import { Auth } from 'aws-amplify';

function actionSuccess(data) {
  // Successful password set, should return a cognito user.
  // TODO: Remove this log
  return data;
}

function actionFailure(err) {
  // Errors here are thrown by cognito.
  // TODO: Handle well known cases to notify user
  return err;
}

/**
 * @typedef {import('vuex').Commit} Commit
 */

/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {string} params -
 */
export default async function CompletePasswordChallenge({ commit }, params) {
  // Check for required params
  if (!params || !params.newPassword) {
    return Promise.reject(
      new Error('ERROR::COMPLETE_PASSWORD_CHALLENGE: No parameters provided.')
    );
  }
  if (!params.user) {
    // Try to set user from session
    try {
      params.user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      // Return a rejected promise if fails.
      return Promise.reject(
        new Error(
          'ERROR::COMPLETE_PASSWORD_CHALLENGE: Failed to read user from session, or no user provided.'
        )
      );
    }
  }

  // Check the challenge name is correct
  if (
    params.user.challengeName !== 'NEW_PASSWORD_REQUIRED' ||
    !params.user.challengeParam
  ) {
    // Return a rejected promise if fails.
    return Promise.reject(
      new Error(
        'ERROR::COMPLETE_PASSWORD_CHALLENGE: User has incorrect challenge name or parameters'
      )
    );
  }
  // Check that user has required parameters and that they are set
  if (
    params.user.challengeParam.requiredAttributes.length !== 0 &&
    !params.user.challengeParam.requiredAttributes.every(item => {
      return params.attributes.hasOwnProperty(item);
    })
  ) {
    // Return a rejected promise if fails.
    return Promise.reject(
      new Error(
        'ERROR::COMPLETE_PASSWORD_CHALLENGE: User is missing required attributes'
      )
    );
  }

  // Use user as parameter for challenge answer
  return Auth.completeNewPassword({ ...params })
    .then(actionSuccess)
    .catch(actionFailure);
}
