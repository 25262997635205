<template>
  <div>
    <v-row>
      <v-col :cols="12" :md="wide ? 6 : 12" v-if="!batch">
        <div class="app-tooltip-textfield">
          <label class="form-label">Event Language Selection</label>
          <TMOToolTip
            text="Select 'English and Spanish' if your event collateral needs to be translated into Spanish."
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <v-radio-group
            v-model="eventLanguage"
            :rules="[nonEmptyRule('You must select an event language.')]"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            row
          >
            <v-radio
              v-for="lang in eventLanguageOptions"
              :key="lang.id"
              :value="lang.value"
              hide-details="auto"
              :readonly="isEventArchived"
            >
              <span slot="label" class="black--text">{{ lang.text }}</span>
            </v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <v-col :cols="12" :md="wide ? 6 : 12" v-if="eventLanguage === 'bi'">
        <div class="app-tooltip-textfield">
          <label class="form-label">Default Language Selection</label>
          <TMOToolTip
            text="The language you select will be the event's main language.
          The users will have the ability to select which language they want to see on the engagement form and rules page."
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <v-radio-group
            v-model="defaultLanguage"
            :rules="[nonEmptyRule('You must select a default language.')]"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            row
          >
            <v-radio
              v-for="deflang in EventDefaultLanguages"
              :key="deflang.id"
              :value="deflang.value"
              hide-details="auto"
              :readonly="isEventArchived"
            >
              <span slot="label" class="black--text">{{ deflang.text }}</span>
            </v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <v-col cols="12" :md="wide ? 12 : 12" v-if="!batch">
        <label class="form-label">Select Branding Option</label>
        <v-radio-group
          v-model="brand"
          :rules="[nonEmptyRule('You must select a branding option.')]"
          hide-details="auto"
          append-icon="mdi-alert-circle"
          row
        >
          <v-radio
            v-for="brd in currentBrandingOptions"
            :key="brd.id"
            :value="brd.value"
            hide-details="auto"
            :readonly="isEventArchived"
          >
            <span slot="label" class="black--text">{{ brd.text }}</span>
          </v-radio>
        </v-radio-group>
      </v-col>
      <v-col
        cols="12"
        :md="wide ? 12 : 12"
        class="branding_selection"
        v-if="brand && !batch"
      >
        <img
          v-if="currentBrandingSelection.imgurl"
          :src="logoUrl(currentBrandingSelection.imgurl)"
        />
        <div v-else :class="currentBrandingSelection.class"></div>
      </v-col>
      <v-col cols="12" :md="wide ? 12 : 12">
        <div class="app-tooltip-textfield">
          <TMOToolTip
            text="The Event Name will be in the consumer-facing entry form. Please check all spelling and keep the title brief (Example – Ariana Grande Concert Ticket Giveaway)"
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>

          <v-text-field
            ref="titleEnglish"
            v-model="eventName"
            :rules="[nonEmptyRule('You must supply an event name.')]"
            label="Event Name"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            required
            @blur="$refs.titleEnglish.validate()"
            :readonly="isEventArchived"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" :md="wide ? 6 : 12">
        <div class="app-tooltip-textfield">
          <TMOToolTip
            v-if="!batch"
            :html="true"
            text="
              <ul>
                <li>Select <b>Enter for a Chance to Win</b> if you’re doing a sweepstakes.</li><br>
                <li>Select <b>Instant Win</b> if every consumer is guaranteed a prize.</li><br>
                <li>Select <b>Sign Up/Check In</b> if your event requires consumers to pre-register for the giveaway but are guaranteed a free gift.</li>
              </ul>
            "
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <v-select
            ref="contestType"
            v-model="contestType"
            label="Promotion Type"
            :rules="[
              nonEmptyRule('You must select the promotion type for this event.')
            ]"
            :items="
              batch
                ? [{ value: 'Batch Import Event', text: 'Batch Import Event' }]
                : EventContestTypeOptions
            "
            hide-details="auto"
            append-icon="$Caret"
            :readonly="isEventArchived || batch"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" :md="wide ? 6 : 12">
        <v-select
          v-model="eventType"
          label="Event Segment/Audience"
          :rules="[nonEmptyRule('You must select a promotion type.')]"
          :items="EventTypeOptions"
          hide-details="auto"
          append-icon="$Caret"
          :readonly="isEventArchived"
        ></v-select>
      </v-col>
      <v-col v-if="eventType === 'TFB'" cols="12" :md="wide ? 6 : 12">
        <v-text-field
          :rules="[v => batch || !!v || 'You must enter a business name.']"
          v-model="businessName"
          label="Business Name"
          hide-details="auto"
          append-icon="mdi-alert-circle"
          :readonly="isEventArchived"
          required
        ></v-text-field>
      </v-col>
      <v-col v-if="eventType === 'TFB'" cols="12" :md="wide ? 6 : 12">
        <div class="app-tooltip-textfield">
          <TMOToolTip
            text="Selecting 'Yes' will allow you to collect your attendee's Business Name."
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <label class="form-label">Do you want to include a field to collect 'Business Name' on the
            Attendee Form?</label>

          <v-radio-group
            :rules="[v => v !== null || 'This question is required.']"
            v-model="attendeeFormBusinessName"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            row
          >
            <v-radio :value="true" hide-details="auto">
              <span slot="label" class="black--text">Yes</span>
            </v-radio>
            <v-radio :value="false" hide-details="auto">
              <span slot="label" class="black--text">No</span>
            </v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <v-col
        v-if="eventType === 'HQ Sponsorship'"
        cols="12"
        :md="wide ? 6 : 12"
      >
        <v-select
          v-model="hqSponsorshipSegment"
          label="HQ Sponsorship Segment"
          :rules="[
            v => batch || !!v || 'You must select an HQ sponsorship segment.'
          ]"
          :items="hqSponsorshipSegmentOptions"
          hide-details="auto"
          append-icon="$Caret"
          :readonly="isEventArchived"
        ></v-select>
      </v-col>
      <v-col cols="12" :md="wide ? 6 : 12" v-if="!batch">
        <div class="app-tooltip-textfield">
          <TMOToolTip
            text="Enter 0000 if you did not partner with a retail store for this event."
            style="transform: translateY(-50%)"
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <v-text-field
            v-model="storeNumber"
            label="Store Number"
            :rules="[
              nonEmptyRule('You must provide a store number.'),
              v =>
                v == null ||
                v.length <= 6 ||
                'Store number cannot be longer than 6 characters.'
            ]"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            counter="6"
            :readonly="isEventArchived"
            required
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" :md="wide ? 6 : 12" v-if="!batch">
        <div class="app-tooltip-textfield">
          <TMOToolTip
            text="The cost of your event, Brand Ambassadors, and giveaways compiled into one dollar amount."
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <v-text-field
            v-model="totalMarketingSpend"
            label="Total Marketing Spend"
            :rules="[
              nonEmptyRule('You must provide your total marketing spend.'),
              v =>
                /^[\d\.]+$/.test(v) ||
                'Total marketing spend can only contain numbers and decimals.'
            ]"
            hide-details="auto"
            append-icon="mdi-alert-circle"
            required
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        v-if="!batch"
        cols="12"
        :md="wide ? 12 : 12"
        :class="{ 'description--wide': wide }"
      >
        <v-textarea
          v-model="eventDescription"
          label="Event Description"
          :rules="[
            nonEmptyRule('You must provide an event description.'),
            v =>
              v == null ||
              v.length <= 500 ||
              'Event description cannot be greater than 500 characters.'
          ]"
          rows="1"
          auto-grow
          hide-details="auto"
          append-icon="mdi-alert-circle"
          :readonly="isEventArchived"
          counter="500"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ValidationRules from '../../utility/ValidationRules.js';
import TMOToolTip from '@/components/TMOToolTip.vue';

export default {
  name: 'InfoFields',

  components: {
    TMOToolTip
  },
  props: {
    wide: {
      type: Boolean,
      default: false
    },
    isEventArchived: {
      type: Boolean,
      default: false
    },
    batch: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    nonEmptyRule(error) {
      return ValidationRules.NonEmptyRule(error);
    },
    logoUrl(logo) {
      return require('../../assets/' + logo);
    }
  },

  computed: {
    eventLanguageOptions() {
      return this.$OptionData.EventLanguages();
    },
    EventDefaultLanguages() {
      return this.$OptionData.EventDefaultLanguages();
    },
    EventContestTypeOptions() {
      return this.$OptionData.ContestTypes();
    },
    EventTypeOptions() {
      return this.$OptionData.SegmentAudienceItems();
    },
    hqSponsorshipSegmentOptions() {
      return this.$OptionData.hqSponsorshipSegmentItems();
    },
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),

    eventLanguage: {
      get() {
        return this.CURRENT_EVENT_OBJECT.eventLanguage || 'en';
      },
      set(value) {
        if (value === 'en') {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'defaultLanguage',
            value: 'en'
          });
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eventLanguage',
          value: value
        });
      }
    },
    defaultLanguage: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.defaultLanguage || 'en';
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'defaultLanguage',
          value: value
        });
      }
    },
    eventName: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.name || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'name',
          value: value
        });
      }
    },

    brand: {
      get() {
        return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
          ? this.CURRENT_EVENT_OBJECT.brand
          : 'T-Mobile';
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'brand',
          value: value
        });
      }
    },
    contestType: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.contestType || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'contestType',
          value: value
        });
        if (value != 'Enter for a Chance to Win') {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_WINNERS', {
            key: 'numberOfWinners',
            value: 0
          });
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'legalRequired',
            value: false
          });
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'legal',
            value: null
          });
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'legalPdfUrl',
            value: null
          });
        }
      }
    },
    eventType: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.eventType || null;
      },
      set(value) {
        if (this.eventType != 'TFB') {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'businessName',
            value: null
          });
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'attendeeFormBusinessName',
            value: false
          });
        }
        if (this.eventType != 'HQ Sponsorship') {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'hqSponsorshipSegment',
            value: null
          });
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eventType',
          value: value
        });
      }
    },

    storeNumber: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.storeNumber || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'storeNumber',
          value: value
        });
      }
    },
    totalMarketingSpend: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.totalMarketingSpend || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'totalMarketingSpend',
          value: value
        });
      }
    },
    eventDescription: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.eventDescription || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eventDescription',
          value: value
        });
      }
    },
    businessName: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.businessName || null;
      },
      set(value) {
        if (this.eventType === 'TFB') {
          return this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY',
            {
              key: 'businessName',
              value: value
            }
          );
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'businessName',
          value: null
        });
      }
    },
    askBusinessName: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.askBusinessName || false;
      },
      set(value) {
        if (this.eventType === 'TFB') {
          return this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY',
            {
              key: 'attendeeFormBusinessName',
              value: value
            }
          );
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'attendeeFormBusinessName',
          value: false
        });
      }
    },
    attendeeFormBusinessName: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.attendeeFormBusinessName || false;
      },
      set(value) {
        if (this.eventType === 'TFB') {
          return this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY',
            {
              key: 'attendeeFormBusinessName',
              value: value
            }
          );
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'attendeeFormBusinessName',
          value: false
        });
      }
    },
    hqSponsorshipSegment: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.hqSponsorshipSegment || null;
      },
      set(value) {
        if (this.eventType === 'HQ Sponsorship') {
          return this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY',
            {
              key: 'hqSponsorshipSegment',
              value: value
            }
          );
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'hqSponsorshipSegment',
          value: null
        });
      }
    },

    currentBrandingSelection() {
      const tmoSolid = {
        imgurl: null,
        class: 'branding_solid--tmo'
      };
      const tmoTextureBrand = {
        imgurl: 'tmo_texture.jpg',
        class: 'branding_solid--tmo'
      };
      const metroSolid = {
        imgurl: null,
        class: 'branding_solid--metro'
      };

      const metroTexture = {
        imgurl: 'metro_texture.jpg',
        class: 'branding_solid--metro'
      };
      return !this.brand
        ? tmoSolid
        : this.brand === 'T-Mobile'
        ? tmoSolid
        : this.brand === 'T-Mobile Solid'
        ? tmoSolid
        : this.brand === 'T-Mobile Texture'
        ? tmoTextureBrand
        : this.brand != 'Metro Texture'
        ? metroSolid
        : metroTexture;
    },
    currentBrandingOptions() {
      const tmoBrands = [
        {
          text: 'Solid',
          value: 'T-Mobile'
        },
        {
          text: 'Textured',
          value: 'T-Mobile Texture'
        }
      ];

      const metroBrands = [
        {
          text: 'Solid',
          value: 'Metro'
        },
        {
          text: 'Textured',
          value: 'Metro Texture'
        }
      ];

      return !this.brand
        ? tmoBrands
        : this.brand.includes('T-Mobile')
        ? tmoBrands
        : metroBrands;
    }
  }
};
</script>
<style scoped>
.branding_selection {
  height: 200px;
}

.branding_selection img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.branding_solid--tmo {
  background: #e20074;
  height: 100%;
  width: 100%;
}
.branding_solid--metro {
  background: #46196e;
  height: 100%;
  width: 100%;
}
</style>
