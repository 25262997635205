<template>
  <div id="winners">
    <v-row class="relative">
      <v-col cols="12" :md="wide ? 12 : 12">
        <label class="form-label">Does your event require winner to be in a certain state(s) or
          territories?</label>
        <v-radio-group
          v-model="contestStatesRequired"
          hide-details="auto"
          append-icon="mdi-alert-circle"
          row
          @change="toggleAllStates"
        >
          <v-radio :value="true" hide-details="auto">
            <span slot="label" class="black--text">Yes</span>
          </v-radio>
          <v-radio :value="false" hide-details="auto">
            <span slot="label" class="black--text">No</span>
          </v-radio>
        </v-radio-group>
        <v-select
          multiple
          v-if="contestStatesRequired"
          v-model="eligibleStates"
          label="State(s) or Territories"
          :items="StateOptions"
          hide-details="auto"
          append-icon="$Caret"
          :readonly="isEventArchived"
          :rules="eligibleStatesRules"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleAllStates">
              <v-list-item-action>
                <v-icon
                  :color="
                    eligibleStates.length > 0
                      ? 'v-application primary--text'
                      : ''
                  "
                >
                  {{ currentStateIcon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-col>

      <v-col
        class="relative"
        cols="12"
        :md="12"
        v-if="showMultipleEntryPeriodPicker"
      >
        <label class="form-label">Does your event have multiple entry periods?</label>
        <v-radio-group
          v-model="HAS_MULTIPLE_ENTRY_PERIODS"
          hide-details="auto"
          append-icon="mdi-alert-circle"
          row
        >
          <v-radio :value="true" hide-details="auto">
            <span slot="label" class="black--text">Yes</span>
          </v-radio>
          <v-radio :value="false" hide-details="auto">
            <span slot="label" class="black--text">No</span>
          </v-radio>
        </v-radio-group>
      </v-col>

      <!-- number of winners start -->
      <v-col cols="12" :md="12" ref="winners">
        <v-text-field
          v-model="numberOfWinners"
          :label="numberOfWinnersLabel"
          :rules="numberOfWinnersRules"
          hide-details="auto"
          :readonly="isEventArchived"
          type="number"
          :min="1"
          :max="50"
        ></v-text-field>
      </v-col>
      <v-col cols="6" :md="wide ? 4 : 4" v-if="numberOfWinners > 1">
        <label class="form-label">Rank Winners</label>
        <v-radio-group
          v-model="rankWinners"
          :rules="rankWinnersRules"
          hide-details="auto"
          append-icon="mdi-alert-circle"
          row
        >
          <v-radio
            :value="true"
            hide-details="auto"
            :readonly="isEventArchived"
          >
            <span slot="label" class="black--text">Yes</span>
          </v-radio>
          <v-radio
            :value="false"
            hide-details="auto"
            :readonly="isEventArchived"
          >
            <span slot="label" class="black--text">No</span>
          </v-radio>
        </v-radio-group>
      </v-col>
      <!-- number of winners end-->
      <!-- if single entry period -->
      <template
        v-if="!HAS_MULTIPLE_ENTRY_PERIODS && intervals && intervals.length"
      >
        <v-col cols="12" md="12" class="pb-0">
          <label class="form-label">When should winners be selected?</label>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <div class="date-time-picker-wrap">
            <v-menu
              v-model="winnerSelectionDateMenu[0]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Winner Selection"
                  append-icon="$Calendar"
                  readonly
                  v-bind="attrs"
                  hide-details="auto"
                  v-on="on"
                  :value="intervals[0].winnerSelectionDate"
                ></v-text-field>
              </template>
              <v-date-picker
                :value="intervals[0].winnerSelectionDate"
                no-title
                @input="
                  updateSingleEntryPeriodWinnerSelectionDateTime(
                    $event,
                    'winnerSelectionDate'
                  )
                "
                :min="startDate"
                :max="returnMaxWinnerSelectionDate(startDate)"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <div class="date-time-picker-wrap">
            <v-select
              :value="intervals[0].winnerSelectionTime"
              :rules="winnerSelectionTimeRules"
              append-icon="$Clock"
              :items="
                getAvailWinnerSelectiontimesInInterval(
                  intervals[0].endDate,
                  intervals[0].endTime,
                  intervals[0].winnerSelectionDate,
                  0
                )
              "
              @change="
                updateSingleEntryPeriodWinnerSelectionDateTime(
                  $event,
                  'winnerSelectionTime'
                )
              "
              hide-details="auto"
              label="Selection Time"
            ></v-select>
          </div>
        </v-col>
      </template>
      <!-- at this point all events should have one interval, with the start date and end date being the first. -->
      <!-- begin entry period dates -->
      <template v-if="HAS_MULTIPLE_ENTRY_PERIODS">
        <v-col cols="12" md="12" class="pb-0">
          <h3>Entry Periods</h3>
          <p>
            The start date and time of your first entry period must match your
            event's start date and time. The end date and time of your last
            entry period must match your event's end date and time. The system
            defaults the first entry period to your event dates; to change the
            end date and time of your first entry period, click the '+' button
            to create additional entry periods.
          </p>
        </v-col>
        <template

          v-for="(interval, index) in intervals"
        >
          <v-col class="mt-4 app-entry-date-row pb-0" cols="12" md="12"  :key="interval.id">
            <div class="entry-period__title-row">
              <h4>Entry Period {{ index + 1 }}</h4>
              <v-btn
                class="app-remove-entry-date-button"
                icon
                v-show="intervals.length >= 2"
                color="primary"
                @click="removeInterval(index)"
                :key="'removeButton' + index"
              >
                <v-icon>mdi-minus-thick</v-icon>
              </v-btn>
              <v-btn
                class="app-add-entry-date-button"
                @click="addInterval"
                v-show="intervals.length < 12 && index == intervals.length - 1"
                icon
                color="primary"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="6" :key="'startDate' + index">
            <div class="date-time-picker-wrap">
              <v-menu
                v-model="entryStartDateMenu[index]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="interval.startDate"
                    label="Entry Start Date"
                    append-icon="$Calendar"
                    readonly
                    v-bind="attrs"
                    hide-details="auto"
                    v-on="on"
                    :messages="checkforSaveError(index, 'startDate')"
                    :rules="[
                      v => !!v || 'All Entry periods require a start date.',
                      v =>
                        v >= calculatePredicateStartDate(index) ||
                        `Your entry periods start date must begin after the previous entry period ends on ${calculatePredicateStartDate(
                          index
                        )}.`
                    ]"
                  ></v-text-field>
                </template>

                <v-date-picker
                  :value="interval.startDate"
                  no-title
                  @input="updateInterval($event, index, 'startDate')"
                  @change="entryStartDateMenu[index] = false"
                  :min="
                    index === 0 ? startDate : calculatePredicateStartDate(index)
                  "
                  :max="index === 0 ? startDate : endDate"
                ></v-date-picker>
                <!-- current startdate selected + 45 days if greater than endDate use endDate if less than endDate use the plus 45 days-->
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" md="6" :key="'startTime' + index">
            <div class="date-time-picker-wrap">
              <v-select
                :value="interval.startTime"
                @input="updateInterval($event, index, 'startTime')"
                append-icon="$Clock"
                :items="
                  index === 0
                    ? returnStartTimeFirstIndex(CURRENT_EVENT_OBJECT.startTime)
                    : getAvailableStartTimePredicate(
                        intervals[index - 1].endDate,
                        intervals[index - 1].endTime,
                        interval.startDate,
                        index
                      )
                "
                hide-details="auto"
                :messages="checkforSaveError(index, 'startTime')"
                label="Start Time"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" md="6" :key="'endDate' + index">
            <div class="date-time-picker-wrap">
              <v-menu
                v-model="entryEndDateMenu[index]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="interval.endDate"
                    label="Entry End Date"
                    append-icon="$Calendar"
                    readonly
                    v-bind="attrs"
                    hide-details="auto"
                    :messages="checkforSaveError(index, 'endDate')"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="interval.endDate"
                  no-title
                  @input="updateInterval($event, index, 'endDate')"
                  @change="entryEndDateMenu[index] = false"
                  :min="
                    index === intervals.length - 1
                      ? endDate
                      : interval.startDate
                      ? interval.startDate
                      : calculatePredicateEndDate(index)
                  "
                  :max="
                    index === intervals.length - 1
                      ? endDate
                      : !interval.startDate
                      ? endDate
                      : getEndDateRange(interval.startDate)
                  "
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" md="6" :key="'endTime' + index">
            <div class="date-time-picker-wrap">
              <v-select
                :value="interval.endTime"
                append-icon="$Clock"
                :messages="checkforSaveError(index, 'endTime')"
                @input="updateInterval($event, index, 'endTime')"
                :items="
                  index === intervals.length - 1
                    ? returnEndTimesLastIndex(CURRENT_EVENT_OBJECT.endTime)
                    : getAvailEndtimesInInterval(
                        interval.startDate,
                        interval.endDate,
                        interval.startTime,
                        index
                      )
                "
                hide-details="auto"
                label="End Time"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" md="6" :key="'winnerSelectionDate' + index">
            <div class="date-time-picker-wrap">
              <v-menu
                v-model="winnerSelectionDateMenu[index]"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="interval.winnerSelectionDate"
                    label="Winner Selection Date"
                    :messages="checkforSaveError(index, 'winnerSelectionDate')"
                    append-icon="$Calendar"
                    readonly
                    v-bind="attrs"
                    hide-details="auto"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="interval.winnerSelectionDate"
                  no-title
                  @input="updateInterval($event, index, 'winnerSelectionDate')"
                  @change="winnerSelectionDateMenu[index] = false"
                  :min="index == 0 ? startDate : interval.startDate"
                  :max="returnMaxWinnerSelectionDate(interval.startDate)"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" md="6" :key="'winnerSelectionTime' + index">
            <div class="date-time-picker-wrap">
              <v-select
                :value="interval.winnerSelectionTime"
                :messages="checkforSaveError(index, 'winnerSelectionTime')"
                append-icon="$Clock"
                @input="updateInterval($event, index, 'winnerSelectionTime')"
                :items="
                  getAvailWinnerSelectiontimesInInterval(
                    interval.endDate,
                    interval.endTime,
                    interval.winnerSelectionDate,
                    index
                  )
                "
                hide-details="auto"
                label="Selection Time"
              ></v-select>
            </div>
          </v-col>
        </template>
      </template>
      <!-- v-for interval except for interval one (startt date always = to event start date ) -->
    </v-row>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogText }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn class="px-6 btn__primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ValidationRules from '../../utility/ValidationRules.js';
import OptionData from '../../utility/OptionData.js';
import HelperFunctions from '../../utility/HelperFunctions.js';
import { mapGetters, mapActions, mapState } from 'vuex';
import { DateTime, Interval } from 'luxon';
let anteriorly = (x, y) => x > y;
let anteriorlyAndEqual = (x, y) => x >= y;
export default {
  name: 'WinnerFields',
  props: {
    wide: {
      type: Boolean,
      default: false
    },
    isEventArchived: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.setupContestState();
  },
  data() {
    return {
      ///////////
      entryStartDateMenu: [],
      entryEndDateMenu: [],
      winnerSelectionDateMenu: [],
      entryDateRows: 1,
      dialog: false,
      dialogText: '',
      dialogTitle: '',
      selectedEntryEndDates: [],
      maxWinnerSelectionDate: [],
      isAdmin: false,

      ////rules: {
      winnerSelectionTimeRules: [],
      numberOfWinnersRules: [
        value =>
          !!value || 'You must select how many winners your event will have.',
        value =>
          value <= 50 ||
          'An enter to win event can have no more than fifty winners.',
        value =>
          value > 0 || 'An enter to win event requires at least one winner.'
      ],
      rankWinnersRules: [
        value =>
          typeof value != 'undefined' ||
          'You must select whether or not your event winners will be assigned a ranking.'
      ],
      eligibleStatesRules: [
        value =>
          value.length >= 1 ||
          "You must select eligible states if you select 'Yes' to the question above."
      ]
    };
  },

  methods: {
    getEndDateRange(intervalDate) {
      if (!intervalDate) return this.endDate;
      let dt = new Date(intervalDate);
      let endDt = new Date(this.endDate);
      dt.setDate(dt.getDate() + 45);
      if (dt > endDt) {
        return this.endDate;
      }
      return dt.toISOString().substr(0, 10);
    },
    setupContestState() {
      if (
        this.eligibleStates &&
        Array.isArray(this.eligibleStates) &&
        this.eligibleStates.length >= 52
      ) {
        this.contestStatesRequired = false;
      } else {
        this.contestStatesRequired = true;
      }
    },

    resetContestStateRequired(value) {
      let baseContestArr = this.StateOptions.map(state => state.value);
      if (!value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eligibleStates',
          value: baseContestArr
        });
      }

      this.contestStatesRequired = value;
    },
    deselectAllStates() {
      if (this.contestStatesRequired) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eligibleStates',
          value: '[]'
        });
      } else {
        this.resetContestStateRequired();
      }
    },
    updateSingleEntryPeriodWinnerSelectionDateTime(value, key) {
      //rule to make sure winnerSelectionendDate/time is good to go;
      if (!key || !value) return;
      let payload = {
        index: 0,
        key: key,
        value: value
      };
      this.$store.commit('EVENT_MANAGER_STORE/UPDATE_INTERVAL_OBJ', payload);
    },
    removeInterval(targetIndex) {
      //TODO show error if trying to remove the first and only interval;
      let payload = {
        index: targetIndex
      };
      this.$store.commit('EVENT_MANAGER_STORE/REMOVE_EVENT_INTERVAL', payload);
    },
    addInterval() {
      //TODO show error if trying to add more than allowed number of intervals
      this.$store.commit('EVENT_MANAGER_STORE/ADD_EVENT_INTERVAL');
    },
    updateInterval(value, index, key) {
      if (!key || !value) return;
      let payload = {
        index: index,
        key: key,
        value: value
      };
      this.$store.commit('EVENT_MANAGER_STORE/UPDATE_INTERVAL_OBJ', payload);
    },
    toggleAllStates() {
      let baseContestArr = this.StateOptions.map(state => state.value);

      if (
        this.eligibleStates &&
        this.eligibleStates.length >= 52 &&
        this.contestStatesRequired
      ) {
        this.$store.commit('EVENT_MANAGER_STORE/TOGGLE_REMOVE_ALL_STATES');
      } else {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eligibleStates',
          value: baseContestArr
        });
      }
    },
    returnStartTimeFirstIndex(time) {
      let baseArr = this.timePickerItems || [];
      let evtStartTime = this.CURRENT_EVENT_OBJECT.startTime || null;
      if (!time) return [];
      return baseArr.filter(time => time.value == evtStartTime);
    },
    returnEndTimesLastIndex(time) {
      let baseArr = this.timePickerEndItems || [];
      let evtEndTime = this.CURRENT_EVENT_OBJECT.endTime || null;
      if (!time) return [];
      return baseArr.filter(time => time.value == evtEndTime);
    },
    returnMaxWinnerSelectionDate(startingTimePoint = null) {
      let stDate = startingTimePoint || this.startDate;
      if (!stDate) return null;

      var dt = new Date(stDate);
      dt.setDate(dt.getDate() + 45);
      return dt.toISOString().substr(0, 10);
    },
    calculatePredicateStartDate(indx = null) {
      let intvlArr = this.intervals || [];
      let isBetween = indx < intvlArr.length && indx !== 0;
      let predStartDate = this.startDate || null;
      let convDate = this.startDate;
      if (isBetween) {
        predStartDate = intvlArr[indx - 1].endDate || null;
      }
      if (predStartDate) {
        convDate = new Date(predStartDate).toISOString().substr(0, 10);
      }

      return convDate;
    },
    calculatePredicateEndDate(indx = null) {
      let intvlArr = this.intervals || [];
      let isBetween = indx < intvlArr.length && indx !== 0;
      let predStartDate = this.startDate || null;
      let convDate = this.startDate;
      if (isBetween) {
        predStartDate = intvlArr[indx - 1].endDate || null;
      }
      if (predStartDate) {
        convDate = new Date(predStartDate).toISOString().substr(0, 10);
      }

      return convDate;
    },
    getAvailEndtimesInInterval(
      intervalStartDate,
      intervalEndate,
      intervalStartTime,
      index
    ) {
      let stDt = intervalStartDate || null;
      let endDt = intervalEndate || null;
      let stTm = intervalStartTime || '00:00:00';
      let endTimeOpts = this.timePickerEndItems || [];
      let currentEndTimeChoosen = this.intervals[index]?.endTime || null;
      if (!stDt || stDt !== endDt) return this.timePickerEndItems;
      endTimeOpts = this.timePickerEndItems.filter(x =>
        anteriorly(x.value, stTm)
      );

      if (currentEndTimeChoosen) {
        let checkIfExists = endTimeOpts.filter(
          x => x.value === currentEndTimeChoosen
        ).length;

        //If invalid reset startTime to null;
        if (!checkIfExists) {
          let payload = {
            index: index,
            key: 'endTime',
            value: null
          };
          this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_INTERVAL_OBJ',
            payload
          );
        }
      }

      return endTimeOpts;
    },
    getAvailWinnerSelectiontimesInInterval(
      intervalEndate,
      intervalEndTime,
      intervalSelectionDate,
      index
    ) {
      // check if EP end date = EP Winner selection date
      let endDt = intervalEndate || null;
      let selDT = intervalSelectionDate || null;
      let endTm = intervalEndTime || '00:14:59';
      let selTimeOpts = this.timePickerEndItems || [];
      let currentSelTimeChoosen =
        this.intervals[index]?.winnerSelectionTime || null;

      if (selDT == null || selDT != endDt) return selTimeOpts;

      selTimeOpts = this.timePickerEndItems.filter(x =>
        anteriorlyAndEqual(x.value, endTm)
      );

      if (currentSelTimeChoosen) {
        let checkIfExists = selTimeOpts.filter(
          x => x.value === currentSelTimeChoosen
        ).length;

        //If invalid reset startTime to null;
        if (!checkIfExists) {
          let payload = {
            index: index,
            key: 'winnerSelectionTime',
            value: null
          };
          this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_INTERVAL_OBJ',
            payload
          );
        }
      }

      return selTimeOpts;
    },
    getAvailableStartTimePredicate(
      previousEndDate,
      PreviousEndTime,
      currentStartDate,
      index
    ) {
      let stDt = previousEndDate || null;
      let crntDt = currentStartDate || null;
      let endTm = PreviousEndTime || '00:00:00';
      let stTimeOpts = this.timePickerItems || [];
      let currentStartTimeChoosen = this.intervals[index]?.startTime || null;
      if (!stDt || !crntDt || stDt !== crntDt) return stTimeOpts;
      stTimeOpts = this.timePickerItems.filter(x => anteriorly(x.value, endTm));

      if (currentStartTimeChoosen) {
        let checkIfExists = stTimeOpts.filter(
          x => x.value === currentStartTimeChoosen
        ).length;

        //If invalid reset startTime to null;
        if (!checkIfExists) {
          let payload = {
            index: index,
            key: 'startTime',
            value: null
          };
          this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_INTERVAL_OBJ',
            payload
          );
        }
      }

      return stTimeOpts;
    },
    checkforSaveError(inx, key) {
      let errMessages = [];
      if (!this.CURRENT_INTERVAL_ERROR_LIST.length) return errMessages;

      this.CURRENT_INTERVAL_ERROR_LIST.filter(err => {
        let isanError = err.index === inx && err.key === key;
        if (!isanError) return;
        errMessages.push(err.message);
        return err.message;
      });

      if (errMessages) {
        return errMessages;
      }
      return errMessages;
    }
  },

  computed: {
    timePickerItems() {
      return this.$OptionData.TimepickerItems();
    },

    firstStartTimeOptions() {
      if (this.CURRENT_EVENT_OBJECT.startTime) {
        return this.$OptionData.TimepickerItems(this.startTime);
      }
      return this.timePickerItems.filter(time => time.value == this.startTime);
    },
    selectedAllStates() {
      return this.eligibleStates.length === this.StateOptions.length;
    },
    selectedSomeStates() {
      return this.eligibleStates.length > 0 && !this.selectedAllStates;
    },
    currentStateIcon() {
      if (this.selectedAllStates) return 'mdi-close-box';
      if (this.selectedSomeStates) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    timePickerEndItems() {
      let endTimeOptions = this.$OptionData.TimepickerEndItems();
      let currentEndTime = this.endTime;
      let checkTimeOccurance = endTimeOptions.filter(
        item => item.value === currentEndTime
      ).length;
      if (!checkTimeOccurance && currentEndTime) {
        let twelveHourTime = this.$options.filters
          .UTCtoAMPM(currentEndTime)
          .toLowerCase();
        endTimeOptions.push({ text: twelveHourTime, value: currentEndTime });
      }

      return endTimeOptions;
    },
    StateOptions() {
      return this.$OptionData.States();
    },
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'CURRENT_INTERVAL_ERROR_LIST'
    ]),
    CURRENT_INTERVAL_ERROR_LIST() {
      return (
        this.$store.state.EVENT_MANAGER_STORE?.CURRENT_INTERVAL_ERROR_LIST || []
      );
    },
    contestStatesRequired: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.contestStatesRequired || false;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'contestStatesRequired',
          value: value
        });
      }
    },
    eligibleStates: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.eligibleStates || [];
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eligibleStates',
          value: value
        });
      }
    },
    intervals: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.intervals || [];
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'intervals',
          value: value
        });
      }
    },
    numberOfWinners: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.numberOfWinners || null;
      },
      set(value) {
        if (!value || value == 1) {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'rankWinners',
            value: false
          });
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_WINNERS', {
          key: 'numberOfWinners',
          value: Number(value)
        });
      }
    },
    rankWinners: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.rankWinners || false;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'rankWinners',
          value: value
        });
      }
    },
    HAS_MULTIPLE_ENTRY_PERIODS: {
      get() {
        return (
          this.$store.state.EVENT_MANAGER_STORE.HAS_MULTIPLE_ENTRY_PERIODS ||
          false
        );
      },
      set(value) {
        this.$store.commit(
          'EVENT_MANAGER_STORE/SET_MULTIPLE_ENTRY_PERIODS',
          value
        );
      }
    },

    showMultipleEntryPeriodPicker() {
      return !this.CURRENT_EVENT_OBJECT.contestType
        ? false
        : this.CURRENT_EVENT_OBJECT.contestType != 'Enter for a Chance to Win'
        ? false
        : this.CURRENT_EVENT_OBJECT.startDate &&
          this.CURRENT_EVENT_OBJECT.endDate &&
          this.CURRENT_EVENT_OBJECT.startTime &&
          this.CURRENT_EVENT_OBJECT.endTime
        ? true
        : false;
    },

    startDate() {
      return this.CURRENT_EVENT_OBJECT?.startDate || null;
    },
    startTime() {
      return this.CURRENT_EVENT_OBJECT?.startTime || null;
    },
    endDate() {
      return this.CURRENT_EVENT_OBJECT?.endDate || null;
    },
    endTime() {
      return this.CURRENT_EVENT_OBJECT?.endTime || null;
    },
    optionData: function() {
      return OptionData;
    },
    numberOfWinnersLabel() {
      return !this.HAS_MULTIPLE_ENTRY_PERIODS
        ? 'Number of Winners'
        : 'Number of Winners Per Entry Period';
    },

    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`
    }),
    helperFunctions: function() {
      return HelperFunctions;
    }
  }
};
</script>
<style scoped>
.relative,
.app-entry-date-row {
  position: relative;
}
.app-entry-date-row {
  padding: 13.5px 0;
}
.entry-period__title-row {
  display: flex;
  align-items: center;
}
.app-entry-date-row:last-of-type .app-remove-entry-date-button {
  display: none;
}
</style>
