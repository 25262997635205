<template>
  <v-container class="py-0 view-winners">
    <h2 class="pb-0">Winners</h2>
    <p>
      Winners may take up to 15 minutes to be generated after your indicated
      winner selection time.
    </p>
    <v-skeleton-loader
      class="mx-auto"
      v-show="isLoading"
      max-width="100%"
      type="list-item"
    ></v-skeleton-loader>

    <v-expansion-panels v-show="!isLoading">
      <v-expansion-panel
        v-for="(entry_period, name, index) in intervalResponseObject"
        :key="index"
        class="black action-bar"
        :active-class="'active'"
      >
        <v-expansion-panel-header style="background:black;color:white;">
          <span>Entry Period #{{
              Object.keys(intervalResponseObject).length - index
            }}
            : {{ formattedStartEndTime(name) }}
          </span>
          <template v-slot:actions>
            <v-icon x-large color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :disable-pagination="true"
            :headers="headers"
            :items="entry_period"
            class="winners-events-table "
            :calculate-widths="true"
            :hide-default-footer="true"
            header-props.sort-icon="mdi-chevron"
          >
            <template v-slot:item.created="{ item }">
              <span>{{
                item.created | humanDateTimeCombo(CURRENT_EVENT_OBJECT.timezone)
              }}</span>
            </template>
            <template v-slot:item.winner_rank="{ item }">
              {{ item.alternate ? 'ALT ' : '' }}
              {{ CURRENT_EVENT_OBJECT.rankWinners ? item.winner_rank : '' }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="flex-wrap justify-center d-flex flex-lg-nowrap">
                <v-btn
                  class="btn__primary"
                  @click="getAltWinner(item)"
                  target="_blank"
                >
                  <v-skeleton-loader
                    class="mx-auto"
                    v-show="isLoading"
                    max-width="100%"
                    type="list-item"
                  ></v-skeleton-loader>
                  <span v-show="!isLoading">Alternate Winner</span>
                </v-btn>
              </div>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="RESET_FILTERS">
                No winners found.
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    {{ errorMessage }}
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import HelperFunctions from '@/utility/HelperFunctions';
export default {
  name: 'ViewWinnersAction',
  data() {
    return {
      headers: [
        {
          value: 'winner_firstName',
          text: 'First Name',

          sortable: false,
          align: 'center',

          class: 'small-table-text'
        },
        {
          value: 'winner_lastName',
          text: 'Last Name',

          sortable: false,
          align: 'center',

          class: 'small-table-text'
        },
        {
          value: 'winner_email',
          text: 'Email',

          sortable: false,
          align: 'center',

          class: 'small-table-text'
        },
        {
          value: 'winnerinfo_phone',
          text: 'Phone',

          sortable: false,
          align: 'center',

          class: 'small-table-text'
        },
        {
          value: 'created',
          text: 'Time Picked',

          sortable: false,
          align: 'center',

          class: 'small-table-text'
        },
        {
          value: 'winner_rank',
          text: 'Winner Rank',

          sortable: false,
          align: 'center',

          class: 'small-table-text'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'small-table-text'
        }
      ],
      winners: null,
      errorMessage: null,
      dialog: false,
      dialogTitle: null,
      dialogMessage: null,
      dialogActionText: null,
      dialogAction: null,
      intervalRanges: [],
      intervalResponseObject: {},
      isLoading: true
    };
  },
  async created() {
    this.isLoading = true;
    await this.getWinners();
    // check if event is longer than 45 days
    if (this.intervalRanges.length > 0) {
      let start = this.intervalRanges[0].start;
      let end = this.intervalRanges[this.intervalRanges.length - 1].end;
      start = new Date(start);
      end = new Date(end);
      // get event length in days
      // end - start returns difference in milliseconds
      let eventLength = Math.ceil((end - start) / (1000 * 3600 * 24));
      if (eventLength > 45) {
        this.isLoading = false;
        this.displayDialog(
          'Confirm Your Winners',
          `Your event is longer than 45 days and we cannot verify if someone
          has been selected as a winner in multiple entry periods.
          Please check to see if any winners listed have previously been awarded
          a prize for this event. If so, click the 'Alternate Winner' button to generate a new winner.`,
          'Close',
          () => (this.dialog = false)
        );
      }
    }
  },
  methods: {
    async getWinners() {
      let winnersResponse, filtered_out_replaced_winners;
      try {
        winnersResponse = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/FETCH_WINNERS',
          this.$route.params.id
        );
        if (!winnersResponse.data || !winnersResponse.data.length) {
          throw Error('This event does not have any winners yet.');
        }
        this.winners = winnersResponse.data.slice().reverse();

        //Remove all objects from the response where replaced = true; Than group by agregate key
        filtered_out_replaced_winners = this.winners
          .filter(winner => !winner.replaced)
          .reduce((acc, obj) => {
            let agregate_key = `${obj['interval_id']}_${obj['interval_start_date']}_${obj['interval_end_date']}`;
            if (!acc[agregate_key]) {
              acc[agregate_key] = [];
            }
            acc[agregate_key].push(obj);
            return acc;
          }, {});

        //assign the filtered and grouped object containing all arrays to the responseObject
        this.intervalResponseObject = Object.assign(
          {},
          this.intervalResponseObject,
          filtered_out_replaced_winners
        );
      } catch (e) {
        this.errorMessage = e;
        this.winners = [];
      } finally {
        this.isLoading = false;
      }
    },
    formattedStartEndTime(interval_agregate) {
      if (!interval_agregate) return null;
      let formattedTimezone = this.helperFunctions.FormatTimezone(
        this.CURRENT_EVENT_OBJECT.timezone
      );
      let splitAggregate = interval_agregate.split('_');
      return `${this.$options.filters.humanDateTimeCombo(
        splitAggregate[1],
        this.CURRENT_EVENT_OBJECT.timezone
      )} ${formattedTimezone} -  ${this.$options.filters.humanDateTimeCombo(
        splitAggregate[2],
        this.CURRENT_EVENT_OBJECT.timezone
      )} ${formattedTimezone}`;
    },
    changeAction(actionName) {
      this.$emit('changeAction', actionName);
    },
    async getAltWinner(winner) {
      var response = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/GET_ALTERNATE_WINNER',
        {
          replacedWinnerId: winner.id,
          event_id: winner.event_id,
          interval_id: winner.interval_id,
          replaceWinnerRank: winner.winner_rank
        }
      );
      response = JSON.parse(response);
      if (response.errorMessage) {
        this.dialog = true;
        this.dialogTitle = 'Error Generating Alternate Winner';
        this.dialogMessage = response.errorMessage;
        this.dialogActionText = 'OK';
        var self = this;
        this.dialogAction = function() {
          self.dialog = false;
        };
      } else {
        this.getWinners();
      }
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    }
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    helperFunctions() {
      return HelperFunctions;
    }
  }
};
</script>
<style lang="scss" scoped>
h2 {
  color: var(--v-primary-base);
  font-size: 40px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.view-winners {
  margin-bottom: 50px;
}

.button-text {
  font-weight: bold;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

::v-deep .v-expansion-panel-header {
  flex-direction: row-reverse;
  span {
    margin-left: 30px;
  }
}
::v-deep .v-data-table__wrapper {
  max-height: 50vh !important;
  overflow: scroll !important;
}
</style>
