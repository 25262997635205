<template>
  <div>
    <v-row>
      <v-col cols="10" :md="wide ? 10 : 10">
        <div class="app-tooltip-textfield">
          <TMOToolTip
            text="The abbreviated prize description will be used in consumer-facing marketing materials. Please check all spelling, avoid using other company/brand names, do not use punctuation and keep the description brief. Example: “$100 GIFT CARD”."
          >
            <div class="d-flex">
              <v-icon medium color="secondary">
                mdi-information
              </v-icon>
            </div>
          </TMOToolTip>
          <v-text-field
            ref="abbreviatedPrize"
            :rules="[
              v =>
                contestType !== 'Enter for a Chance to Win' ||
                !!v ||
                'Abbreviated prize description is required.',
              v =>
                v == null ||
                contestType !== 'Enter for a Chance to Win' ||
                v.length <= 100 ||
                'Abbreviated prize description cannot be greater than 100 characters.'
            ]"
            v-model="abbreviatedPrizeDescription"
            label="Abbreviated Prize Description"
            hide-details="auto"
            counter="100"
            append-icon="mdi-alert-circle"
            validate-on-blur
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="2" class=" justify-center" :md="wide ? 2 : 2">
        <v-btn
          class=" btn__primary v-btn v-btn--text theme--light v-size--default"
          @click="TOGGLE_AVE_FLYOUT(true)"
        >
          Preview
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        :lg="wide ? 12 : 12"
        v-if="contestType === 'Enter for a Chance to Win'"
      >
        <label class="form-label">What are your Sweepstakes Rules Requirements?</label>
        <v-radio-group
          v-model="legalRequired"
          hide-details="auto"
          append-icon="mdi-alert-circle"
          row
          @change="
            if (!legalRequired) {
              resetLegalandLegalPdfURL();
            }
          "
        >
          <v-radio
            :value="false"
            hide-details="auto"
            :readonly="isEventArchived"
          >
            <span slot="label" class="black--text">Generate legal rules based on my event details.</span>
          </v-radio>
          <v-radio
            :value="true"
            hide-details="auto"
            :readonly="isEventArchived"
          >
            <span slot="label" class="black--text">I have received custom rules from the legal team and will upload
              them</span>
          </v-radio>
        </v-radio-group>
      </v-col>
      <template v-if="legalRequired === false">
        <v-col
          cols="12"
          :md="wide ? 12 : 12"
          v-if="contestType === 'Enter for a Chance to Win'"
        >
          <div class="app-tooltip-textfield">
            <TMOToolTip :text="tTipText" :html="true">
              <div class="d-flex">
                <v-icon medium color="secondary">
                  mdi-information
                </v-icon>
              </div>
            </TMOToolTip>

            <v-text-field
              :rules="[v => !!v || 'Sweepstakes title is required.']"
              v-model="sweepstakesTitle"
              label="Title of Sweepstakes"
              hide-details="auto"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          cols="12"
          :md="wide ? 12 : 12"
          v-if="contestType === 'Enter for a Chance to Win'"
        >
          <div class="app-tooltip-textfield">
            <TMOToolTip
              text='This is the formal prize description that will be included in the sweeps rules. Please describe each prize, its individual value, and how many of that prize will be provided. If more than one type of prize will be awarded, please describe how the awarding of the different prizes will correlate to the ranking of the winners. Ex.  "Concert tickets" would be insufficient, but "2 general admission tickets to the London Philharmonic&#39;s New Year&#39;s Eve performance at the Rock and Roll Hall of Fame on 12/31/21.'
            >
              <div class="d-flex">
                <v-icon medium color="secondary">
                  mdi-information
                </v-icon>
              </div>
            </TMOToolTip>
            <v-textarea
              ref="fullPrizeDescription"
              v-model="fullPrizeDescription"
              label="Full Prize Description"
              :rules="[
                v =>
                  !v
                    ? numberOfWinners === 0 ||
                      'You must provide a prize description.'
                    : (fullPrizeDescription &&
                        fullPrizeDescription.length <= 500) ||
                      'Prize description cannot be greater than 500 characters.'
              ]"
              hide-details="auto"
              append-icon="mdi-alert-circle"
              auto-grow
              required
              :readonly="isEventArchived"
            ></v-textarea>
          </div>
        </v-col>
        <v-col
          cols="12"
          :md="wide ? 12 : 12"
          v-if="contestType === 'Enter for a Chance to Win'"
        >
          <div class="app-tooltip-textfield">
            <TMOToolTip
              text="Note: If this total value is equal to or greater than $5,000, you may need to work with Legal to register this Sweepstakes with one or more states and to obtain a Bond to secure the sweeps."
            >
              <div class="d-flex">
                <v-icon medium color="secondary">
                  mdi-information
                </v-icon>
              </div>
            </TMOToolTip>
            <v-text-field
              :rules="[
                nonEmptyRule('ARV is required.'),
                v =>
                  /^[\d\.]+$/.test(v) ||
                  'ARV can only contain numbers and decimals.'
              ]"
              v-model="arv"
              label="Approximate Retail Value (ARV) of ALL Prize(s)"
              hide-details="auto"
              append-icon="mdi-alert-circle"
            ></v-text-field>
          </div>
        </v-col>
      </template>
      <template v-if="legalRequired === true">
        <v-col
          cols="12"
          :md="wide ? 12 : 12"
          :class="{ 'description--wide': wide }"
        >
          <v-textarea
            v-model="abbreviatedLegal"
            label="Paste abbreviated rules provided by legal"
            :rules="[
              v =>
                !!v ||
                'You must provide legal language if you have indicated your event needs it.',
              v =>
                (v && v.length <= 500) ||
                'Legal cannot be greater than 500 characters.'
            ]"
            rows="1"
            auto-grow
            hide-details="auto"
            append-icon="mdi-alert-circle"
            counter="500"
            :readonly="isEventArchived"
          ></v-textarea>
          <div class="app-tooltip-textfield">
            <TMOToolTip text="File must be PDF">
              <div class="d-flex">
                <v-icon medium color="secondary">
                  mdi-information
                </v-icon>
              </div>
            </TMOToolTip>
            <label class="form-label">Upload your Sweepstakes Rules</label>
            <template>
              <v-file-input
                accept=".pdf"
                label="Upload Rules"
                @change="handleRulesUpload"
              ></v-file-input>
            </template>
          </div>
          <label class="form-label" v-if="legalPdfUrl">Your previously uploaded sweepstakes rules are available
            <a :href="legalPdfUrl" target="_blank">here</a>. If you wish to
            replace them, use the field above.</label>
        </v-col>
      </template>
      <template>
        <v-col
          cols="12"
          :md="wide ? 12 : 12"
          :class="{ 'description--wide': wide }"
        >
          <div class="app-tooltip-textfield">
            <TMOToolTip :text="legalDetailsToolTip">
              <div class="d-flex">
                <v-icon medium color="secondary">
                  mdi-information
                </v-icon>
              </div>
            </TMOToolTip>
            <label class="form-label">Please list your Legal point of contact in support of this event,
              along with any pertinent event details, and who the vendor partner
              is (if any):
            </label>

            <v-textarea
              :rules="[v => !!v || 'Legal details are required.']"
              v-model="legalDetails"
              label="Legal Details"
              rows="1"
              auto-grow
              hide-details="auto"
              append-icon="mdi-alert-circle"
              :readonly="isEventArchived"
            ></v-textarea>
          </div>
        </v-col>
      </template>
      <template>
        <v-col
          cols="12"
          :md="wide ? 12 : 12"
          :class="{ 'description--wide': wide }"
        >
          <div class="app-tooltip-textfield">
            <TMOToolTip text="File must be PDF">
              <div class="d-flex">
                <v-icon medium color="secondary">
                  mdi-information
                </v-icon>
              </div>
            </TMOToolTip>

            <label class="form-label">If available, upload a copy of the contract here:</label>
            <template>
              <v-file-input
                accept=".pdf"
                label="Upload Contract"
                @change="handleContractUpload"
              ></v-file-input>
            </template>
          </div>
          <label class="form-label" v-if="legalContract">Your previously uploaded contract document is available
            <a :href="legalContract" target="_blank">here</a>. If you wish to
            replace it, use the field above.</label>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import ValidationRules from '../../utility/ValidationRules.js';
import TMOToolTip from '@/components/TMOToolTip.vue';
import { mapActions } from 'vuex';
export default {
  name: 'PromotionalLegalFields',
  props: {
    wide: {
      type: Boolean,
      default: false
    },
    isEventArchived: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TMOToolTip
  },
  data() {
    return {
      meetsContestCriteria: false,
      legalDetailsToolTip: `This field is required. If you have not been working with anyone on the Legal team for this event, enter "N/A" and someone will be assigned to review your event.`
    };
  },

  methods: {
    ...mapActions('EVENT_MANAGER_STORE', [
      'PUT_RULES_PDF_IN_STORAGE',
      'PUT_CONTRACT_PDF_IN_STORAGE'
    ]),
    ...mapMutations('AVE_CANVAS_STORE', ['TOGGLE_AVE_FLYOUT']),
    nonEmptyRule(error) {
      //return true;
      return ValidationRules.NonEmptyRule(error);
    },
    resetLegalandLegalPdfURL() {
      this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
        key: 'legal',
        value: null
      });
      this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
        key: 'legalPdfUrl',
        value: null
      });
    },
    async handleRulesUpload(rulesFile) {
      if (rulesFile == null) {
        this.legalPdfUrl = null;
        return;
      }
      this.legalPdfUrl = await this.PUT_RULES_PDF_IN_STORAGE({
        rulesPdf: rulesFile
      });
    },
    async handleContractUpload(contractFile) {
      if (contractFile == null) {
        this.legalContract = null;
        return;
      }
      this.legalContract = await this.PUT_CONTRACT_PDF_IN_STORAGE({
        contractPdf: contractFile
      });
    }
  },

  computed: {
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    optionData() {
      return this.$OptionData;
    },
    brand() {
      return this.CURRENT_EVENT_OBJECT?.brand || 'T-Mobile';
    },
    tTipText() {
      let brand = this.brand;
      let toolTipText =
        'Please write this in the form of<br>“T-Mobile&#39;s _____ Sweepstakes.';

      switch (brand) {
        case 'T-Mobile':
        case 'T-Mobile Solid':
        case 'T-Mobile Texture':
          toolTipText =
            'Please write this in the form of<br>“T-Mobile&#39;s _____ Sweepstakes.';
          break;
        default:
          toolTipText =
            'Please write this in the form of<br>“Metro&#39;s _____ Sweepstakes.';
      }
      return toolTipText;
    },

    numberOfWinners() {
      return this.CURRENT_EVENT_OBJECT.numberOfWinners || null;
    },
    contestType() {
      return this.CURRENT_EVENT_OBJECT.contestType || null;
    },
    legalPdfUrl: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.legalPdfUrl || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'legalPdfUrl',
          value: value
        });
      }
    },
    abbreviatedLegal: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.abbreviatedLegal || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'abbreviatedLegal',
          value: value
        });
      }
    },
    abbreviatedPrizeDescription: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.abbreviatedPrizeDescription || null;
      },
      set(value) {
        if (
          !this.CURRENT_EVENT_OBJECT?.event_status ||
          this.CURRENT_EVENT_OBJECT?.event_status === 'Draft'
        ) {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
            key: 'abbreviatedPrizeDescription',
            value: value
          });
        }
      }
    },
    sweepstakesTitle: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.sweepstakesTitle || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'sweepstakesTitle',
          value: value
        });
      }
    },
    fullPrizeDescription: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.fullPrizeDescription || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'fullPrizeDescription',
          value: value
        });
      }
    },
    legalDetails: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.legalDetails || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'legalDetails',
          value: value
        });
      }
    },
    arv: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.arv || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'arv',
          value: value
        });
      }
    },
    legalContract: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.legalContract || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'legalContract',
          value: value
        });
      }
    },
    legalRequired: {
      get() {
        return this.CURRENT_EVENT_OBJECT.legalRequired || false;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'legalRequired',
          value: value
        });
      }
    }
  }
};
</script>
