import { Auth } from 'aws-amplify';

/**
 * @typedef {import('vuex').Commit} Commit
 */

/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {object} response - Response returned from forgot password function.
 */
function forgotPasswordSuccess(commit, response) {
  return response;
}

/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {object} err - Error returned from sign in request
 */
function forgotPasswordFail(commit, err) {
  // TODO: Handle account forgot password failures
  if (err.code === '"UserNotFoundException"') {
    switch (err.message) {
      case 'Username/client id combination not found.':
        commit('SET_AUTH_ERROR_MESSAGE', err.message);
        break;
    }
  } else {
    commit('SET_AUTH_ERROR_MESSAGE', err);
  }
  commit('SET_AUTH_ERROR_MESSAGE', err);
  return err;
}

/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {string} email - Account email
 */
export default async function ForgotPassword({ commit }, email) {
  if (!email) {
    return Promise.reject(
      new Error('ERROR::FORGOT_PASSWORD: No parameters provided.')
    );
  }
  commit('SET_FORGOT_PASSWORD_ERROR', null);
  return (
    Auth.forgotPassword(email)
      // Returns a message with information about password recovery
      // TODO: Display this message somewhere
      .then(data => {
        if (data.CodeDeliveryDetails) {
          commit('SET_CodeDeliveryDetails', data.CodeDeliveryDetails);
        } else {
          commit('SET_FORGOT_PASSWORD_ERROR', {
            message: 'Response did not contain code delivery information.',
            response: data
          });
        }
        return data;
      })
      // TODO: Will return errors like 'user doesn't exist'
      // TODO: Display or store
      .catch(err => {
        commit('SET_FORGOT_PASSWORD_ERROR', err);
        return err;
      })
  );
}
