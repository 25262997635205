<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="40px" height="40px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"
>
<g>
	<rect class="st0" width="40" height="40"/>
	<g id="Dev-Pack">
		<g id="Group" transform="translate(-570.000000, -577.000000)">
			<g id="Shape" transform="translate(566.000000, 573.000000)">
				<path class="st1" d="M24,36c6.6,0,12-5.4,12-12s-5.4-12-12-12s-12,5.4-12,12S17.4,36,24,36 M24,9c8.3,0,15,6.8,15,15
					s-6.8,15-15,15S9,32.3,9,24S15.8,9,24,9 M31.5,26.8l-1.1,2l-7.9-4.3v-8h2.3v6.6L31.5,26.8z"
/>
			</g>
		</g>
	</g>
</g>
</svg>
</template>

<script>
export default {
  name: 'Clock'
};
</script>
<style lang="scss" scoped>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: currentColor;
}
</style>
