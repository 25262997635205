import { Auth } from 'aws-amplify';
import { Validator } from 'jsonschema';
import AccountSignInSchema from '../schema/ACCOUNT_SIGN_IN.schema.json';

/**
 * @typedef {import('vuex').Commit} Commit
 */

// Handle successful sign in
/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {string} accountData - Account Data returned from sign in function.
 */
function signInSuccess(commit, accountData) {
  // TODO: Handle account data
  commit('SET_ACCOUNT_DATA', accountData);
  // User groups set by admins
  commit(
    'SET_USER_GROUPS',
    accountData.signInUserSession.idToken.payload['cognito:groups']
  );
  commit('SET_USER_NAME', accountData.username);
  commit('SET_USER', accountData);
  // Maybe make a set account_data method to cover
  // breaking up this big object into useful bits
  // we don't need to store keys or anything because they
  // are available in the Amplify Auth library after sign in.
  return accountData;
}

/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {object} err - Error returned from sign in request
 */
function signInFail(commit, err) {
  // TODO: Handle account sign in failures
  let exceptions = [
    'UserNotFoundException',
    'NotAuthorizedException',
    'UserNotConfirmedException'
  ];

  if (exceptions.indexOf(err.code) !== -1) {
    switch (err.message) {
      case 'Incorrect username or password.':
        // ! TODO
        commit('SET_AUTH_ERROR_MESSAGE', err.message);
        break;
      case 'Password attempts exceeded':
        // ! TODO
        commit('SET_AUTH_ERROR_MESSAGE', err.message);
        break;
      case 'User is not confirmed.':
        // ! TODO
        commit('SET_AUTH_ERROR_MESSAGE', err.message);
        break;
      default:
        commit('SET_AUTH_ERROR_MESSAGE', err.message);
        break;
    }
    return err;
  } else {
    commit('SET_AUTH_ERROR_MESSAGE', err.message);
    return err;
  }
}
/**
 * @param  {Commit} commit - VUEX Commit - pass this in to issue mutations
 * @param  {object} signInParams - SIGN_IN Params - see example schema
 */
export default async function ACCOUNT_SIGN_IN({ commit }, signInParams) {
  if (!signInParams) {
    return Promise.reject(
      new Error('ERROR::ACCOUNT_SIGN_IN: No parameters provided.')
    );
  }
  const paramValidator = new Validator();
  // Validate incoming request against json schema
  if (paramValidator.validate(signInParams, AccountSignInSchema).valid) {
    // Return value after sign in promise is resolved
    return await Auth.signIn({ ...signInParams })
      .then(accountData => signInSuccess(commit, accountData))
      .catch(err => signInFail(commit, err));
  } else {
    return Promise.reject(
      new Error(
        'ERROR::ACCOUNT_SIGN_IN: Sign in parameters did not pass validation.'
      )
    );
  }
}
