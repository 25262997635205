<template>
  <div v-if="!LOADING">
    <v-row>
      <v-col cols="12" md="6" :lg="wide ? 3 : 3">
        <div class="date-time-picker-wrap">
          <v-menu
            v-model="showPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="startDateField"
                v-model="startDate"
                label="Event Start Date"
                append-icon="$Calendar"
                readonly
                v-bind="attrs"
                hide-details="auto"
                v-on="on"
                :rules="[v => !!v || 'Event start date is required.']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-if="batch"
              v-model="startDate"
              no-title
              :readonly="isEventArchived"
              @input="showPicker = false"
            ></v-date-picker>
            <v-date-picker
              v-else
              v-model="startDate"
              no-title
              :readonly="isEventArchived"
              @input="showPicker = false"
              :allowed-dates="disablePastDates"
              :min="minStartDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" md="6" :lg="wide ? 3 : 3">
        <div class="date-time-picker-wrap">
          <v-select
            v-model="startTime"
            append-icon="$Clock"
            :items="startTimeRuleConditional"
            hide-details="auto"
            label="Start Time"
            :readonly="isEventArchived"
            :rules="[v => !!v || 'Event start time is required.']"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" md="6" :lg="wide ? 3 : 3">
        <div class="date-time-picker-wrap">
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="endDateField"
                v-model="endDate"
                label="Event End Date"
                append-icon="$Calendar"
                readonly
                v-bind="attrs"
                hide-details="auto"
                :rules="[
                  v => !!v || 'Event end date is required.',
                  v => checkIfEndDateIsValid(v)
                ]"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              @input="endDateMenu = false"
              :min="minEndDateStart"
              :readonly="isEventArchived"
            ></v-date-picker>

            <!--
              UNCOMMENT TO RE-ADD THE MAX DATE TO CAL
              <v-date-picker
              v-model="endDate"
              no-title
              @input="endDateMenu = false"
              :min="minEndDateStart"
              :max="maxEndDate"
              :readonly="isEventArchived"
            ></v-date-picker> -->
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" md="6" :lg="wide ? 3 : 3">
        <div class="date-time-picker-wrap">
          <v-select
            ref="endTimeField"
            v-model="endTime"
            append-icon="$Clock"
            :items="endTimeRuleConditional"
            hide-details="auto"
            label="End Time"
            :readonly="isEventArchived"
            :rules="[v => !!v || 'Event end time is required.']"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" md="4" :lg="wide ? 3 : 3">
        <v-select
          v-model="timezone"
          label="Time Zone"
          :items="TimeZoneOptions"
          :readonly="isEventArchived"
          hide-details="auto"
          append-icon="$Caret"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" :lg="wide ? 3 : 6">
        <v-select
          :rules="[v => !!v || 'Event location is required']"
          required
          v-model="eventLocation"
          :items="eventLocationOptions"
          hide-details="auto"
          label="Event Location"
          append-icon="$Caret"
          @change="locationChange"
        ></v-select>
      </v-col>
      <v-col
        v-if="!batch && showAddressOptions"
        cols="12"
        md="4"
        :lg="wide ? 3 : 4"
      >
        <v-text-field
          :rules="[v => !!v || 'Address required.']"
          v-model="physicalEventAddress"
          label="Event Address"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
        :lg="wide ? 3 : 4"
        v-if="
          !batch &&
            (eventLocation == 'Physical/In Person Event' ||
              eventLocation == 'Combination Online/In Person')
        "
      >
        <v-text-field
          :rules="[v => !!v || 'City required.']"
          v-model="physicalEventCity"
          label="Event City"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
        :lg="wide ? 3 : 4"
        v-if="
          !batch &&
            (eventLocation == 'Physical/In Person Event' ||
              eventLocation == 'Combination Online/In Person')
        "
      >
        <v-text-field
          :rules="[
            nonEmptyRule('Zip required.'),
            v =>
              v == null ||
              v.length <= 9 ||
              'Zip code cannot be longer than 9 characters.',
            v =>
              /^[\d\-]+$/.test(v) ||
              'Zip code can only be composed of numbers, hyphens, and parentheses'
          ]"
          v-model="physicalEventZip"
          label="Event Zip"
          hide-details="auto"
          counter="9"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
        :lg="wide ? 3 : 12"
        v-show="eventLocation === 'Online/Virtual Event'"
      >
        <v-select
          ref="regionSelect"
          v-model="region"
          label="Region"
          :rules="[v => onlineEventRule(v, 'You must select a region.')]"
          :items="regionItems"
          hide-details="auto"
          append-icon="$Caret"
          :readonly="isEventArchived"
          @change="regionChange"
          validate-on-blur
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" :lg="wide ? 3 : 12" v-show="region != 'National'">
        <v-autocomplete
          ref="stateSelect"
          v-model="state"
          label="State"
          :rules="[
            v =>
              batch || region === 'National'
                ? true
                : !!v || 'You must select a state.'
          ]"
          :items="StatesOptions"
          hide-details="auto"
          append-icon="$Caret"
          :readonly="isEventArchived"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        :lg="wide ? 4 : 12"
        v-show="
          region !== 'National' && eventLocation === 'Online/Virtual Event'
        "
      >
        <v-autocomplete
          ref="dmaSelect"
          v-model="dma"
          :rules="[v => onlineEventRule(v, 'You must select a DMA.')]"
          :items="DmaOptions"
          label="DMA"
          append-icon="$Caret"
          :readonly="isEventArchived"
          validate-on-blur
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ValidationRules from '../../utility/ValidationRules.js';
import { mapGetters, mapState } from 'vuex';
let anteriorly = (x, y) => x > y;
const checkDateDifferece = (
  dateOne = null,
  dateTwo = null,
  maxDateDifference = 45
) => {
  let passCheck = false;
  if (dateOne && dateTwo) {
    const dateOneObj = new Date(dateOne);
    const dateTwoObj = new Date(dateTwo);
    const totalTimeDifference = dateTwoObj.getTime() - dateOneObj.getTime();
    const totalDayDifference = totalTimeDifference / (1000 * 3600 * 24);

    passCheck = totalDayDifference <= maxDateDifference;
  }
  return passCheck;
};
export default {
  name: 'DateLocationFields',
  props: {
    wide: {
      type: Boolean,
      default: false
    },
    isEventArchived: {
      type: Boolean,
      default: false
    },
    batch: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.isAdmin = this.USER_GROUPS.indexOf('Admin') !== -1;
  },
  data() {
    return {
      showPicker: false,
      startTimeMenu: null,
      tempStartDate: null,
      tempStartTime: null,
      tempEndTime: null,
      endDateMenu: null,
      endTimeMenu: null,
      showEventAddress: false,
      isAdmin: false,
      startDateRules: [
        //  v => this.showPicker || !!v || 'You must select a start date.'
      ],
      entryPeriodCountLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      numberRule: v => {
        if (isNaN(v))
          return 'Number of entry periods can only contain whole numbers.';
        if (v <= 0)
          return 'You must have atleast one entry period for enter to win promotions.';
        if (v >= 13)
          return 'Each event can have a maximun of 12 entry periods.';
        return true;
      }
    };
  },
  watch: {
    showPicker: function(val) {
      this.$refs.startDateField.validate();
    },
    endDateMenu: function(val) {
      this.$refs.endDateField.validate();
    },
    'CURRENT_EVENT_OBJECT.contestType': function(val) {
      this.$refs.endDateField.validate();
      this.$refs.endTimeField.validate();
    }
  },

  methods: {
    checkIfEndDateIsValid(endDate) {
      if (!endDate) return 'Event end date is required.';
      if (this.CURRENT_EVENT_OBJECT.contestType != 'Enter for a Chance to Win')
        return true;
      if (
        this.NUMBER_OF_ENTRY_PERIODS <= 1 &&
        !checkDateDifferece(endDate, this.startDate)
      ) {
        return 'Enter for a chance to win events with only one entry period can only be a maximum of 45 days long.';
      }

      return true;
    },
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    disableEndDatePasts(val) {
      return val >= this.NEW_EVENT_START_DATE;
    },
    nonEmptyRule(error) {
      // if (error.indexOf('state') !== -1 || error.indexOf('DMA') !== -1) {
      //   console.error(error);
      //   if (this.CURRENT_EVENT_OBJECT.region === 'National') {
      //     return true;
      //   } else {
      //     return v => !!v || error;
      //   }
      // } else {
      //   return ValidationRules.NonEmptyRule(error);
      // }
      return ValidationRules.NonEmptyRule(error);
    },
    regionChange(a) {
      if (a === 'National') {
        this.state = '-';
        this.dma = '-';
        this.msa = '-';
        this.marketType = '-';
      } else {
        this.state = this.state == '-' ? undefined : this.state;
        this.dma = this.dma === '-' ? undefined : this.dma;
        this.msa = this.msa === '-' ? undefined : this.msa;
        this.marketType = this.marketType === '-' ? undefined : this.marketType;
      }
      this.$refs.stateSelect.resetValidation();
      this.$refs.dmaSelect.resetValidation();
    },
    locationChange(value) {
      if (value === 'Online/Virtual Event') {
        this.physicalEventAddress = null;
        this.physicalEventCity = null;
        this.physicalEventZip = null;
      } else {
        this.region = undefined;
        this.state = undefined;
        this.dma = undefined;
        this.msa = undefined;
        this.marketType = undefined;
      }
      this.$refs.stateSelect.resetValidation();
      this.$refs.regionSelect.resetValidation();
      this.$refs.dmaSelect.resetValidation();
    },
    onlineEventRule(value, errorMessage) {
      if (
        this.batch ||
        this.region == 'National' ||
        this.eventLocation !== 'Online/Virtual Event'
      ) {
        return true;
      }
      if (!value) {
        return errorMessage;
      }
      return true;
    }
  },
  computed: {
    ...mapGetters({
      USER_GROUPS: `account/USER_GROUPS`
    }),
    startTimeRuleConditional() {
      if (this.isAdmin || !this.startDate) {
        return this.timePickerItems;
      }
      let datediff = (date0, date1) => {
        // get difference in hours
        let milliseconds = date1 - date0;
        let hours = milliseconds / 1000 / 3600;
        return hours >= 48;
      };
      let now = new Date();
      return this.timePickerItems.filter(item => {
        return datediff(now, new Date(this.startDate + ' ' + item.value));
      });
    },
    endTimeRuleConditional() {
      let stDt = this.startDate || null;
      let endDt = this.endDate || null;
      let stTm = this.startTime || '00:00:00';
      let endTimeOpts = this.timePickerEndItems || [];
      if (!stDt || stDt !== endDt) return this.timePickerEndItems;
      // console.log('now running', this.timePickerEndItems);
      endTimeOpts = this.timePickerEndItems.filter(x =>
        anteriorly(x.value, stTm)
      );

      return endTimeOpts;
    },
    minStartDate() {
      var dt = new Date();
      if (!this.isAdmin) {
        dt.setDate(dt.getDate() + 2);
      }

      return dt.toISOString().substr(0, 10);
    },
    minEndDateStart() {
      if (!this.startDate || this.startDate == null) {
        return this.minStartDate;
      }

      var dt = new Date(this.startDate);
      dt.setDate(dt.getDate());

      return dt.toISOString().substr(0, 10);
    },
    maxEndDate() {
      if (this.startDate == null) {
        return null;
      }
      if (
        (this.startDate && this.contestType == 'Instant Win') ||
        this.contestType == null
      ) {
        return null;
      }
      var dt = new Date(this.startDate);
      //UNCOMMENT TO AUTO ADJUST MAX END DATE BASED ON NUMBER OF ENTRY PERIODS
      // if (
      //   this.NUMBER_OF_ENTRY_PERIODS == null ||
      //   this.NUMBER_OF_ENTRY_PERIODS >= 2
      // ) {
      //   let numberOfDayDifferenceAllowed = this.NUMBER_OF_ENTRY_PERIODS * 45;

      //   dt.setDate(dt.getDate() + numberOfDayDifferenceAllowed);
      //   return dt.toISOString().substr(0, 10);
      // }

      dt.setDate(dt.getDate() + 45);
      return dt.toISOString().substr(0, 10);
    },
    StatesOptions() {
      return this.$OptionData.States();
    },
    TimeZoneOptions() {
      return this.$OptionData.TimeZones();
    },
    DmaOptions() {
      return this.$OptionData.DMAs();
    },
    MsaOptions() {
      return this.$OptionData.MSAs();
    },
    timePickerItems() {
      return this.$OptionData.TimepickerItems();
    },
    regionItems() {
      return this.$OptionData.Regions();
    },
    eventLocationOptions() {
      return this.$OptionData.eventLocationOptions();
    },
    marketTypeOptions() {
      return this.$OptionData.marketTypeItems();
    },
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT', 'LOADING']),

    timePickerEndItems() {
      let endTimeOptions = this.$OptionData.TimepickerEndItems();
      // if event ends on same day as start, make sure end time is
      // after event start time
      if (this.startDate && this.startTime && this.endDate) {
        if (this.startDate === this.endDate) {
          endTimeOptions = endTimeOptions.filter(item => {
            return (
              new Date(this.endDate + ' ' + item.value) >=
              new Date(this.startDate + ' ' + this.startTime)
            );
          });
        }
      }
      let currentEndTime = this.endTime;
      let checkTimeOccurance = endTimeOptions.filter(
        item => item.value === currentEndTime
      ).length;
      if (!checkTimeOccurance && currentEndTime) {
        let twelveHourTime = this.$options.filters
          .UTCtoAMPM(currentEndTime)
          .toLowerCase();
        endTimeOptions.push({ text: twelveHourTime, value: currentEndTime });
      }

      return endTimeOptions;
    },

    startDate: {
      get() {
        return this.CURRENT_EVENT_OBJECT.startDate || null;
      },
      set(value) {
        //Reset endTime;
        if (
          (value && this.endDate && value > this.endDate) ||
          (value === this.endDate &&
            this.startTime &&
            this.endTime &&
            this.startTime > this.endTime)
        ) {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_END_DATE_TIME', {
            key: 'endDate',
            value: null
          });
        }
        // reset startTime
        if (!this.isAdmin && value != null && this.startTime != null) {
          // get difference in hours
          let date0 = new Date();
          let date1 = new Date(value + ' ' + this.startTime);
          let milliseconds = date1 - date0;
          let hours = milliseconds / 1000 / 3600;
          if (hours < 48) {
            this.startTime = null;
          }
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_START_DATE_TIME', {
          key: 'startDate',
          value: value
        });
      }
    },
    startTime: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.startTime || null;
      },
      set(value) {
        //Reset endTime;
        if (this.startDate && this.endDate && this.startDate === this.endDate) {
          this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_END_DATE_TIME', {
            key: 'endTime',
            value: null
          });
        }
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_START_DATE_TIME', {
          key: 'startTime',
          value: value
        });
      }
    },
    endDate: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.endDate || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_END_DATE_TIME', {
          key: 'endDate',
          value: value
        });
      }
    },
    endTime: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.endTime || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_END_DATE_TIME', {
          key: 'endTime',
          value: value
        });
      }
    },
    timezone: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.timezone || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'timezone',
          value: value
        });
      }
    },
    eventLocation: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.eventLocation || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'eventLocation',
          value: value
        });
      }
    },
    state: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.state || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'state',
          value: value
        });
      }
    },
    region: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.region || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'region',
          value: value
        });
      }
    },
    dma: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.dma || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'dma',
          value: value
        });
      }
    },
    msa: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.msa || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'msa',
          value: value
        });
      }
    },
    physicalEventCity: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.physicalEventCity || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'physicalEventCity',
          value: value
        });
      }
    },
    physicalEventZip: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.physicalEventZip || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'physicalEventZip',
          value: value
        });
      }
    },
    physicalEventAddress: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.physicalEventAddress || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'physicalEventAddress',
          value: value
        });
      }
    },
    marketType: {
      get() {
        return this.CURRENT_EVENT_OBJECT?.marketType || null;
      },
      set(value) {
        this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'marketType',
          value: value
        });
      }
    },
    NUMBER_OF_ENTRY_PERIODS() {
      return (
        Number(this.$store.state.EVENT_MANAGER_STORE.HOW_MANY_ENTRY_PERIODS) ||
        1
      );
    },
    contestType() {
      return this.CURRENT_EVENT_OBJECT.contestType || null;
    },
    showAddressOptions() {
      let showLocation = true;
      if (
        !this.eventLocation ||
        this.eventLocation === 'Online/Virtual Event'
      ) {
        showLocation = false;
      }
      return showLocation;
    }
  }
};
</script>
