<template>
  <div>
    <v-form
      @submit.prevent="forgotPassword"
      v-model="valid"
      ref="form"
      lazy-validation
      class="app-forgot-password pa-6"
    >
      <v-text-field
        :rules="emailRules"
        v-model="email"
        label="Email"
        required
        class="mt-8"
      ></v-text-field>
      <div class="app-forgot-password__submit mt-8">
        <v-row align="center" class="flex-column">
          <v-col cols="5">
            <v-btn
              type="submit"
              class="btn__primary"
              block
              x-large
              :disabled="!valid"
              @click="validate"
            >
              Request Email
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <div class="app-forgot-password-form__support mt-8 text-center">
      <v-btn text plain href="mailto:smrastrategyandcommunications@t-mobile.com">
        Support
        <v-icon medium color="#E44475">
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <v-snackbar
      top
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" width="500" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="grey lighten-2 justify-center">
          Enter Verification Code
        </v-card-title>

        <v-card-text class="text-center mt-2">
          Please check your email for a verification code and enter it below:
          <ResetPasswordForm :set-valid="setValid" ref="resetPasswordForm" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="btn__primary px-6"
            text
            :disabled="!valid"
            @click="resetPasswordWithCode"
          >
            RESET PASSWORD
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ResetPasswordForm from '@/components/Account/ResetPasswordForm.vue';

export default {
  name: 'ForgotPasswordForm',
  computed: {
    ...mapGetters({
      FORGOT_PASSWORD_ERROR: `account/FORGOT_PASSWORD_ERROR`,
      CodeDeliveryDetails: `account/CodeDeliveryDetails`
    }),
    code: function() {
      return this.$route.query.code || null;
    },
    username: function() {
      return this.$route.query.username || null;
    }
  },
  created() {
    if (this.code || this.username) {
      this.dialog = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    setValid(valid) {
      this.valid = valid;
    },
    resetPasswordWithCode() {
      this.$refs.resetPasswordForm.resetPasswordWithCode();
    },
    ...mapActions({
      FORGOT_PASSWORD: 'account/FORGOT_PASSWORD'
    }),
    forgotPassword() {
      this.FORGOT_PASSWORD(this.email)
        .then(response => this.processForgotPasswordResponse(response))
        .catch();
    },
    processForgotPasswordResponse(response) {
      // on success, a CodeDeliveryDetails { AttributeName: "email", DeliveryMedium: Destination } object is returned
      if (response.CodeDeliveryDetails !== undefined) {
        this.dialog = true;
      } else {
        this.toggleSnackbar(response.message, 'error');
      }
    },
    toggleSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type == 'error' ? '#f03710' : '#0da149';
      this.snackbar = true;
    }
  },
  components: {
    ResetPasswordForm
  },
  data: () => ({
    dialog: false,
    snackbar: false,
    snackbarText: '',
    snackbarColor: '',
    snackbarTimeout: 3000,
    valid: true,
    email: '',
    emailRules: [
      v =>
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || 'Not a valid email'
    ]
  })
};
</script>

<style scoped>
.app-forgot-password {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-forgot-password__submit .v-btn {
  font-weight: 700;
}
::v-deep .v-snack__content {
  font-size: 1rem;
}
::v-deep .app-forgot-password-form__support .v-btn__content {
  opacity: 1 !important;
}
</style>
