<template>
  <div class="app-event-creation">
    <EventActionBar
      :cur-action="curAction"
      @changeAction="curAction = $event"
      :loaded="!LOADING"
    />
    <EventActionOverlay
      :cur-action="curAction"
      @changeAction="curAction = $event"
      @scrollToWinners="scrollToWinners"
    />
    <AlertBar></AlertBar>
    <BannerTitle :title="eventName" :loading="LOADING" />
    <div class="grey" v-if="!LOADING">
      <div class="wide-container">
        <EventFormSection :card="false" wide>
          <div slot="section-content">
            <v-container>
              <EventFormSection :card="false" wide compact>
                <div slot="section-content">
                  <v-row>
                    <v-col cols="12" md="6">
                      <label class="event-link">Event ID: </label>
                      {{ CURRENT_EVENT_OBJECT.id }}
                    </v-col>
                    <v-col cols="12" md="6">
                      <label class="event-link">Event Status: </label>
                      {{ eventStatusTitle }}
                    </v-col>
                  </v-row>
                  <v-row v-if="!isBatchEvent">
                    <v-col cols="12" md="6">
                      <label class="event-link">Public Event URL: </label>
                      <v-skeleton-loader
                        v-if="!BITLY_LINK"
                        class="bitly-skeleton"
                        max-width="300"
                        type="text"
                      ></v-skeleton-loader>
                      <a :href="BITLY_LINK" target="_blank">{{ BITLY_LINK }}</a>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      v-if="
                        CURRENT_EVENT_OBJECT.contestType ===
                          'Enter for a Chance to Win'
                      "
                    >
                      <label class="event-link">
                        Public Rules URL:
                      </label>
                      <router-link
                        :to="'/event/rules/' + $route.params.id"
                        target="_blank"
                      >
                        {{
                          urlRoot + '/#/event/rules/' + CURRENT_EVENT_OBJECT.id
                        }}
                      </router-link>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      v-if="CURRENT_EVENT_OBJECT.status === 'Denied'"
                    >
                      <label class="event-link">Denied Reason: </label>
                      {{
                        CURRENT_LEGAL_OBJECT
                          ? CURRENT_LEGAL_OBJECT.legal_message
                          : ''
                      }}
                    </v-col>
                    <v-col v-show="event_code" cols="12">
                      <label class="event-link">Access Code:</label>
                      <p>
                        {{ event_code }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </EventFormSection>
            </v-container>
          </div>
        </EventFormSection>
      </div>

      <v-row justify="center" class="my-0">
        <v-col cols="9">
          <v-expansion-panels>
            <v-expansion-panel :active-class="'active'">
              <v-expansion-panel-header style="background:black;color:white;">
                <span>Event History </span>
                <template v-slot:actions>
                  <v-icon x-large color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <PastelTable
                  class="my-4"
                  :rows="HISTORY_TABLE_DATA"
                  :columns="[
                    {
                      source: 'type',
                      label: 'Transaction Type'
                    },
                    {
                      source: 'action',
                      label: 'Transaction Action'
                    },
                    {
                      source: 'timestamp',
                      label: 'Timestamp'
                    },
                    {
                      source: 'description',
                      label: 'Description'
                    },
                    {
                      source: 'user',
                      label: 'User'
                    }
                  ]"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
    <!-- {{ CURRENT_EVENT_OBJECT }} -->
    <template v-if="!LOADING">
      <EventEditorForm :is-mobile="isMobile" :is-edit-form="true" ref="form">
        <template v-slot:screenbuttons>
          <div class="form-buttons" v-if="!hideAllFormButtons">
            <v-row justify="center">
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  color="primary"
                  outlined
                  class="exit-button"
                  @click="exitPage"
                >
                  <span class="black--text button-text">
                    Exit
                  </span>
                </v-btn>
              </v-col>
            </v-row>

            <v-row
              justify="center"
              v-if="
                CURRENT_EVENT_OBJECT.status === 'Draft' &&
                  !publishedAndStarted &&
                  showUpdateButton &&
                  !CURRENT_EVENT_OBJECT.archived
              "
            >
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  outlined
                  block
                  x-large
                  color="primary"
                  :loading="API_CALL_IN_PROGRESS"
                  :disabled="!UNTRACKED_CHANGES_TO_EVENT"
                  @click="updateDraftEvent"
                >
                  <span class="black--text button-text">
                    Update Draft
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              v-if="
                CURRENT_EVENT_OBJECT.status === 'Legal Pending' &&
                  showUpdateButton &&
                  !CURRENT_EVENT_OBJECT.archived
              "
            >
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  color="primary"
                  outlined
                  :loading="API_CALL_IN_PROGRESS"
                  @click="pauseLegalAction"
                >
                  <span class="black--text button-text">
                    Pause Legal Review
                  </span>
                </v-btn>
              </v-col>
            </v-row>

            <v-row
              justify="center"
              v-if="
                !LOADING &&
                  CURRENT_EVENT_OBJECT.status === 'Translation Pending' &&
                  showUpdateButton &&
                  !CURRENT_EVENT_OBJECT.archived
              "
            >
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  outlined
                  color="primary"
                  :loading="API_CALL_IN_PROGRESS"
                  @click="pauseTranslationAction"
                >
                  <span class="black--text button-text">
                    Pause Translation
                  </span>
                </v-btn>
              </v-col>
            </v-row>

            <v-row
              justify="center"
              v-show="
                (!publishedAndStarted &&
                  (CURRENT_EVENT_OBJECT.status === 'Draft' ||
                    CURRENT_EVENT_OBJECT.status == 'Denied')) ||
                  (CURRENT_EVENT_OBJECT.status === 'Published' &&
                    showUpdateButton &&
                    !CURRENT_EVENT_OBJECT.archived)
              "
            >
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  :loading="API_CALL_IN_PROGRESS"
                  :color="
                    CURRENT_EVENT_OBJECT.published ? 'primary' : 'secondary'
                  "
                  :disabled="
                    validateSubmitToLegal.length > 0 ||
                      (CURRENT_EVENT_OBJECT.status === 'Published' &&
                        !UNTRACKED_CHANGES_TO_EVENT)
                  "
                  @click="submitToLegalReview"
                >
                  <span class="white--text button-text">
                    Submit to Legal
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="isBatchEvent">
              <v-col cols="10" md="6">
                <v-btn
                  elevation="2"
                  tile
                  block
                  x-large
                  :loading="API_CALL_IN_PROGRESS"
                  :color="
                    CURRENT_EVENT_OBJECT.published ? 'primary' : 'secondary'
                  "
                  :disabled="
                    validateSubmitBatchEvent.length > 0 ||
                      !UNTRACKED_CHANGES_TO_EVENT
                  "
                  @click="submitBatchEvent"
                >
                  <span class="white--text button-text">
                    Submit
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </EventEditorForm>
    </template>
    <template v-else>
      <div
        style="height:100%;width:100%;display:flex;justify-content:center;"
        class="text-center"
      >
        <v-progress-circular indeterminate color="red"></v-progress-circular>
      </div>
    </template>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
          <ul style="list-style: none; padding-left: 0;">
            <li v-for="error in validateDraftEvtObj" :key="error.id">
              {{ error }}
            </li>
            <li v-for="error in currentFormErrors" :key="error.id">
              {{ error }}
            </li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="invalidEntryPeriodDialogue"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Invalid Fields
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          <ul style="list-style: none; padding-left: 0;">
            <li v-for="error in invalidIntervalArr" :key="error.id">
              {{
                `Please Fix entry period ${error.index + 1} :  ${error.message}`
              }}
            </li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="invalidEntryPeriodDialogue = false"
            text
            class="px-6 btn__primary"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ successDialogTitle || 'Event Updated' }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ successDialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="returnToDash" text class="px-6 btn__secondary">
            Dashboard
          </v-btn>
          <v-btn @click="resetSuccessDialog" text class="px-6 btn__primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorDialog"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Error
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          There was an error fetching that event. Please try again later.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="returnToDash" text class="px-6 btn__primary">
            Dashboard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="untrackedChangesDialog"
      width="600"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Unsaved Changes
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          You have unsaved changes to your event. Would you like to save your
          changes before leaving?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            @click="continueWithoutSavingEvent"
            text
            class="px-6 btn__secondary"
          >
            Exit without saving
          </v-btn>
          <v-btn @click="continueEditingEvent" text class="px-6 btn__primary">
            Keep editing
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AlertBar from '@/components/AlertBar.vue';
import BannerTitle from '@/components/BannerTitle.vue';
// import CreateEventForm from '@/components/EventForm/CreateEventForm.vue';
import EventEditorForm from '@/components/Forms/EventEditorForm.vue';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import EventActionBar from '@/components/EventForm/EventActionBar.vue';
import EventActionOverlay from '@/components/EventForm/EventActionOverlay.vue';
import EventFormSection from '@/components/EventForm/EventFormSection';
import { TrackUnsavedChangesDialogControl } from '@/mixins/TrackUnsavedChangesDialogControl';
import {
  SubmitToLegalEventSchema,
  DraftEventSchema,
  EnterToWinEventSchema,
  BatchImportEventSchema
} from '@/utility/ValidationSchemas';
import { DateTime } from 'luxon';
import validateIntervals from '@/utility/ValidateEventIntervals';
import PastelTable from '@/components/PastelTable.vue';

const validate = (object, schema) =>
  Object.keys(schema)
    .filter(key => !schema[key](object[key]))
    .map(key => new Error(`${key} is invalid.`));

export default {
  name: 'EventEdit',
  mixins: [TrackUnsavedChangesDialogControl],
  components: {
    AlertBar,
    BannerTitle,
    EventActionBar,
    EventEditorForm,
    EventActionOverlay,
    EventFormSection,
    PastelTable
  },
  async mounted() {
    try {
      this.isAdmin = this.USER_GROUPS.indexOf('Admin') !== -1;
      this.$store.commit('EVENT_MANAGER_STORE/RESET_ALL');
      await this.fetchEventData();
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true });

      if (this.isBatchEvent && !this.isAdmin) {
        // only admins can edit batch import events
        this.$router.push('/dashboard');
      }
      this.onResize();

      //Setup the temp values for translations;
      this.tempEventName = this.CURRENT_EVENT_OBJECT.name;
      this.tempPrizeDescription = this.CURRENT_EVENT_OBJECT.fullPrizeDescription;
      this.tempAbbreviatedPrizeDescription = this.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription;
      this.tempSweepstakesTitle = this.CURRENT_EVENT_OBJECT.sweepstakesTitle;
      this.tempAbbreviatedLegal = this.CURRENT_EVENT_OBJECT.abbreviatedLegal;

      let historyPayload = {
        eventId: this.$route.params.id,
        timezone: this.CURRENT_EVENT_OBJECT.timezone || 'America/New_York'
      };
      await this.GET_ALL_HISTORY(historyPayload);
    } catch (e) {
      console.log(e);
      //Display Error dialog
      this.errorDialog = true;
    }
  },
  data() {
    return {
      isMobile: false,
      loaded: false,
      curAction: null,
      temporaryEventObject: {},
      publishedAndStarted: false,
      showUpdateButton: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      successDialog: false,
      successDialogTitle: null,
      successDialogMessage: null,
      showSuccessDialog: false,
      //Used for translations;
      tempEventName: null,
      tempPrizeDescription: null,
      tempAbbreviatedPrizeDescription: null,
      tempSweepstakesTitle: null,
      tempAbbreviatedLegal: null,
      //End used for translations;
      errorDialog: false,
      invalidIntervalArr: [],
      currentFormErrors: [],
      invalidEntryPeriodDialogue: false,
      isAdmin: false,
      untrackedChangesDialog: false,
      targetToRoute: false
    };
  },

  watch: {
    $route() {
      this.fetchEventData();
    },
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    },
    CURRENT_EVENT_OBJECT: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleScreenSetup(newVal);
        }
      }
    }
  },
  methods: {
    async fetchEventData() {
      try {
        let evtId = this.$route.params.id;
        let evtGet = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/LOAD_EVENT',
          evtId
        );

        if (this.CURRENT_EVENT_OBJECT.status === 'Published') {
          this.temporaryEventObject = this.CURRENT_EVENT_OBJECT;
        }

        await this.$store.dispatch(
          'EVENT_MANAGER_STORE/FETCH_BITLY_LINK',
          evtId
        );
        await this.$store.dispatch('AVE_CANVAS_STORE/GET_AVAILABLE_IMAGES');
        await this.$store.dispatch('LEGAL_STORE/FETCH_LEGAL', evtId);
        await this.GET_PROXIES(this.$route.params.id);
      } catch (error) {
        console.log(error);
        //Display Error dialog
        this.errorDialog = true;
      }
    },
    ...mapMutations({
      UPDATE_TRANSLATION_PROPERTY: 'UPDATE_TRANSLATION_PROPERTY'
    }),
    ...mapActions({
      sendEmail: 'admin/sendEmail',
      approveTranslations: 'approveTranslations',
      fetchTranslations: 'fetchTranslations'
    }),
    ...mapActions('PROXY_STORE', ['GET_PROXIES']),
    ...mapActions('EVENT_HISTORY_STORE', ['GET_ALL_HISTORY']),
    returnToDash() {
      this.$store.commit('EVENT_MANAGER_STORE/RESET_ALL');
      this.$router.push({ path: '/dashboard' });
    },
    exitPage() {
      this.$store.commit('EVENT_MANAGER_STORE/RESET_ALL');
      this.$router.push({ path: '/dashboard' });
    },
    resetSuccessDialog() {
      this.successDialog = false;
      this.successDialogMessage = null;
      this.successDialogTitle = null;
    },
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    },
    eventEndsBeforeItStarts() {
      // make sure that event end time >= start time
      let startDateTime = new Date(
        this.CURRENT_EVENT_OBJECT.startDate +
          ' ' +
          this.CURRENT_EVENT_OBJECT.startTime
      );
      let endDateTime = new Date(
        this.CURRENT_EVENT_OBJECT.endDate +
          ' ' +
          this.CURRENT_EVENT_OBJECT.endTime
      );
      return endDateTime <= startDateTime;
    },
    eventStartsWithin48Hours() {
      let now = new Date();
      let startDate = new Date(
        this.CURRENT_EVENT_OBJECT.startDate +
          ' ' +
          this.CURRENT_EVENT_OBJECT.startTime
      );
      let milliseconds = startDate - now;
      let hours = milliseconds / 1000 / 3600;
      return startDate <= now || (!this.isAdmin && hours < 48);
    },
    validateEventDates() {
      // make sure that event end time >= start time
      if (this.eventEndsBeforeItStarts()) {
        this.currentFormErrors.push(
          'Event end time cannot be before event start time.'
        );
      }
      // make sure event start is not within 48 hours
      if (this.eventStartsWithin48Hours()) {
        this.currentFormErrors.push('Event start date cannot be within 7 days');
      }
      // reset interval errors array
      this.invalidIntervalArr = [];
      this.$store.commit('EVENT_MANAGER_STORE/SET_CURRENT_INTERVAL_ERROR_LIST');
      // validate interval dates
      if (this.CURRENT_EVENT_OBJECT.contestType !== 'Instant Win') {
        let areIntervalsValid = validateIntervals(
          this.CURRENT_EVENT_OBJECT.startDate,
          this.CURRENT_EVENT_OBJECT.startTime,
          this.CURRENT_EVENT_OBJECT.endDate,
          this.CURRENT_EVENT_OBJECT.endTime,
          this.CURRENT_EVENT_OBJECT.intervals
        );
        // run date checks on all of the dates if multiple intervals;
        if (areIntervalsValid.length) {
          this.invalidIntervalArr = areIntervalsValid;
          this.$store.commit(
            'EVENT_MANAGER_STORE/SET_CURRENT_INTERVAL_ERROR_LIST',
            areIntervalsValid
          );
        }
      }
      // check if eventType = 'HQ Sponsorship' and if it does, require a segment selection;
      if (
        this.CURRENT_EVENT_OBJECT.eventType === 'HQ Sponsorship' &&
        !this.CURRENT_EVENT_OBJECT.hqSponsorshipSegment
      ) {
        this.currentFormErrors.push(
          'You must select a value for HQ Sponsorship Segment.'
        );
      }
    },
    validateLegalAndLocation() {
      // validate abbreviated legal
      if (
        this.CURRENT_EVENT_OBJECT.legalRequired &&
        !this.CURRENT_EVENT_OBJECT.abbreviatedLegal
      ) {
        this.currentFormErrors.push(
          'You must provide legal language if you have indicated your event needs it.'
        );
      }
      if (
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win' &&
        !this.CURRENT_EVENT_OBJECT.legalRequired
      ) {
        if (!this.CURRENT_EVENT_OBJECT.sweepstakesTitle)
          this.currentFormErrors.push('Sweepstakes title is required.');
        if (!this.CURRENT_EVENT_OBJECT.fullPrizeDescription)
          this.currentFormErrors.push('Full prize description is required.');
        if (!this.CURRENT_EVENT_OBJECT.arv)
          this.currentFormErrors.push('ARV is required.');
      }
      // validate event location
      if (
        this.CURRENT_EVENT_OBJECT.region !== 'National' &&
        !this.CURRENT_EVENT_OBJECT.state
      ) {
        this.currentFormErrors.push('State is required.');
      }
      if (this.CURRENT_EVENT_OBJECT.eventLocation === 'Online/Virtual Event') {
        if (!this.CURRENT_EVENT_OBJECT.region) {
          this.currentFormErrors.push('Region is required.');
        }
        if (this.CURRENT_EVENT_OBJECT.region !== 'National') {
          if (!this.CURRENT_EVENT_OBJECT.dma)
            this.currentFormErrors.push('DMA is required.');
        }
      } else {
        if (!this.CURRENT_EVENT_OBJECT.physicalEventAddress)
          this.currentFormErrors.push('Event Address is required.');
        if (!this.CURRENT_EVENT_OBJECT.physicalEventCity)
          this.currentFormErrors.push('Event City is required.');
        if (!this.CURRENT_EVENT_OBJECT.physicalEventZip)
          this.currentFormErrors.push('Event Zip is required.');
      }
    },
    handleScreenSetup(eventObject) {
      this.publishedAndStarted = false;
      //Handle all of the setup steps;
      let todaysDate = new Date().valueOf(),
        formattedStartDate = null;
      //Handle published and started;
      let {
        published = false,
        eventStartDate = null,
        archived = false
      } = eventObject;
      if (eventStartDate) {
        formattedStartDate = DateTime.fromISO(eventStartDate);
      }
      //Check if the event is published and running;
      if (published && formattedStartDate) {
        let isEventStarted = formattedStartDate < todaysDate;
        let isEventWithin48Hours =
          Math.abs((formattedStartDate - todaysDate) / 1000 / 3600) < 48;
        if (!this.isBatchEvent) {
          if (isEventStarted || (!this.isAdmin && isEventWithin48Hours)) {
            this.publishedAndStarted = true;
            this.displayDialog(
              'Event cannot be updated',
              'Published events are locked to updates 7 days before their start date.',
              'Close',
              () => (this.dialog = false)
            );
          }
        }
      }
      //Check if we should be showing the Update button;
      if (this.isAdmin && formattedStartDate && !archived) {
        this.showUpdateButton = todaysDate < formattedStartDate;
      } else if (!archived && formattedStartDate) {
        let startDateMinusTwo = formattedStartDate.minus({ days: 2 });
        this.showUpdateButton = todaysDate < startDateMinusTwo.valueOf();
      }
      if (this.isBatchEvent) {
        this.showUpdateButton = false;
      }
      if (this.CURRENT_EVENT_OBJECT.status === 'Draft' && !archived) {
        this.showUpdateButton = true;
      }
    },
    async updateDraftEvent() {
      try {
        this.currentFormErrors = [];
        if (this.validateDraftEvtObj.length > 0) {
          this.currentFormErrors.push(
            'One or more of your fields are invalid. Please fix these fields before proceeding.'
          );
        }
        this.validateEventDates();
        if (this.currentFormErrors.length > 0) {
          this.displayDialog('Invalid Fields', '', 'Close', () => {
            this.dialog = false;
          });
          return;
        }
        if (this.invalidIntervalArr.length > 0) {
          this.invalidEntryPeriodDialogue = true;
          return;
        }
        if (this.CURRENT_EVENT_OBJECT.isTranslated) {
          this.updateForTranslation();
        }

        //Update the event Status;
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Draft'
        });

        //Send draft email or show dialog
        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/PATCH_EVENT',
          this.$route.params.id
        );
        let { id = null } = newEventId;

        //RELOAD the event
        if (id) {
          await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT', id);
          this.newEventId = id;
          this.successDialogTitle = 'Draft Updated';
          this.successDialogMessage =
            'Draft Updated. Would you like to continue editing or return to the main dashboard?';
          this.successDialog = true;
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    async pauseTranslationAction() {
      let oldStatus = this.CURRENT_EVENT_OBJECT.status;
      try {
        this.currentFormErrors = [];
        if (this.validateDraftEvtObj.length > 0) {
          this.currentFormErrors.push(
            'One or more of your fields are invalid. Please fix these fields before proceeding.'
          );
        }
        this.validateEventDates();
        if (this.currentFormErrors.length > 0) {
          this.displayDialog('Invalid Fields', '', 'Close', () => {
            this.dialog = false;
          });
          return;
        }
        if (this.invalidIntervalArr.length > 0) {
          this.invalidEntryPeriodDialogue = true;
          return;
        }
        //Update the event Status;
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Draft'
        });

        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/PATCH_EVENT',
          this.$route.params.id
        );
        let { id = null } = newEventId;
        if (id) {
          this.newEventId = id;
          //Send email;
          let eventName = this.CURRENT_EVENT_OBJECT.name;

          this.sendEmail(
            this.pauseTranslationEmail(
              process.env.VUE_APP_TRANSLATION_TO_EMAIL,
              eventName
            )
          );
          this.sendEmail(
            this.userEventPauseEmail(
              this.CURRENT_EVENT_OBJECT.createdByEmail,
              eventName
            )
          );

          //RELOAD the event
          await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT', id);
          this.successDialogTitle = 'Translation Paused';
          this.successDialogMessage =
            'The translation process is paused. Would you like to continue editing or return to the main dashboard?';
          this.successDialog = true;
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        // revert status
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: oldStatus
        });
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }

      // send translation pause email
    },
    async updateForTranslation() {
      try {
        let evtId = this.$route.params.id;
        if (
          this.tempEventName != this.CURRENT_EVENT_OBJECT.name ||
          this.tempAbbreviatedPrizeDescription !=
            this.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription ||
          this.CURRENT_EVENT_OBJECT.abbreviatedLegal !=
            this.tempAbbreviatedLegal ||
          this.CURRENT_EVENT_OBJECT.fullPrizeDescription !=
            this.tempPrizeDescription ||
          this.CURRENT_EVENT_OBJECT.sweepstakesTitle !=
            this.tempSweepstakesTitle
        ) {
          await this.$store.commit(
            'EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY',
            {
              key: 'isTranslated',
              value: 0
            }
          );
          await this.fetchTranslations(evtId);
          if (this.tempEventName != this.CURRENT_EVENT_OBJECT.name) {
            await this.$store.commit('UPDATE_TRANSLATION_PROPERTY', {
              key: 'name',
              value: null
            });
          }
          if (
            this.tempAbbreviatedLegal !=
            this.CURRENT_EVENT_OBJECT.abbreviatedLegal
          ) {
            await this.$store.commit('UPDATE_TRANSLATION_PROPERTY', {
              key: 'abbreviatedLegal',
              value: null
            });
          }

          if (
            this.tempAbbreviatedPrizeDescription !=
            this.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription
          ) {
            await this.$store.commit('UPDATE_TRANSLATION_PROPERTY', {
              key: 'abbreviatedPrizeDescription',
              value: null
            });
          }
          if (
            this.tempPrizeDescription !=
            this.CURRENT_EVENT_OBJECT.fullPrizeDescription
          ) {
            await this.$store.commit('UPDATE_TRANSLATION_PROPERTY', {
              key: 'fullPrizeDescription',
              value: null
            });
          }
        }
        await this.approveTranslations(evtId);
        //Update the event Status;
      } catch (error) {
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    async pauseLegalAction() {
      let oldStatus = this.CURRENT_EVENT_OBJECT.status;
      try {
        this.currentFormErrors = [];
        if (this.validateDraftEvtObj.length > 0) {
          this.currentFormErrors.push(
            'One or more of your fields are invalid. Please fix these fields before proceeding.'
          );
        }
        this.validateEventDates();
        if (this.currentFormErrors.length > 0) {
          this.displayDialog('Invalid Fields', '', 'Close', () => {
            this.dialog = false;
          });
          return;
        }
        if (this.invalidIntervalArr.length > 0) {
          this.invalidEntryPeriodDialogue = true;
          return;
        }
        //Update the event Status;
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Draft'
        });

        //Send draft email or show dialog
        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/PATCH_EVENT',
          this.$route.params.id
        );
        let { id = null } = newEventId;

        //RELOAD the event
        if (id) {
          await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT', id);
          this.newEventId = id;
          //Send the email to legal;
          let eventName = this.CURRENT_EVENT_OBJECT.name;
          this.sendEmail(
            this.pauseLegalReviewEmail(
              process.env.VUE_APP_LEGAL_TO_EMAIL,
              eventName
            )
          );
          this.sendEmail(
            this.userEventPauseEmail(
              this.CURRENT_EVENT_OBJECT.createdByEmail,
              eventName
            )
          );

          this.successDialogTitle = 'Legal Review Paused';
          this.successDialogMessage =
            'The legal review process is paused. Would you like to continue editing or return to the main dashboard?';
          this.successDialog = true;
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        // revert status
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: oldStatus
        });
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    async submitToLegalReview() {
      let oldStatus = this.CURRENT_EVENT_OBJECT.status;
      try {
        this.currentFormErrors = [];
        // highlight errors
        this.$refs.form.validate();
        if (this.validateSubmitToLegal.length > 0) {
          this.currentFormErrors.push(
            'One or more of your fields are invalid. Please fix these fields before proceeding.'
          );
        }
        this.validateEventDates();
        this.validateLegalAndLocation();
        if (this.currentFormErrors.length > 0) {
          this.displayDialog('Invalid Fields', '', 'Close', () => {
            this.dialog = false;
          });
          return;
        }
        if (this.invalidIntervalArr.length > 0) {
          this.invalidEntryPeriodDialogue = true;
          return;
        }
        if (this.CURRENT_EVENT_OBJECT.isTranslated) {
          this.updateForTranslation();
        }

        //Update the event Status;
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Legal Pending'
        });

        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/PATCH_EVENT',
          this.$route.params.id
        );
        let { id = null } = newEventId;
        if (id) {
          this.newEventId = id;
          //Send Email;
          let eventName = this.CURRENT_EVENT_OBJECT.name;
          this.sendEmail(
            this.legalReviewEmail(process.env.VUE_APP_LEGAL_TO_EMAIL, eventName)
          );
          this.sendEmail(
            this.userEventSubmittedLegalEmail(
              this.CURRENT_EVENT_OBJECT.createdByEmail,
              eventName
            )
          );

          //RELOAD the event
          await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT', id);
          this.successDialogTitle = 'Legal Review';
          this.successDialogMessage =
            'Your Event has been submitted to legal for review. Would you like to continue editing or return to the main dashboard?';
          this.successDialog = true;
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        // revert status
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: oldStatus
        });
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    async submitBatchEvent() {
      try {
        if (this.validateSubmitBatchEvent.length > 0) {
          this.dialogTitle = 'Invalid Fields';
          this.dialogMessage =
            'One or more of your fields are invalid. Please fix these fields before proceeding.';
          this.dialogActionText = 'Close';
          this.dialogAction = () => {
            this.dialog = false;
          };
          return (this.dialog = true);
        }

        // make sure that event end time >= start time
        if (this.eventEndsBeforeItStarts()) {
          this.dialogTitle = 'Invalid Fields';
          this.dialogMessage =
            'Event end time cannot be before event start time.';
          this.dialogActionText = 'Close';
          this.dialogAction = () => {
            this.dialog = false;
          };
          return (this.dialog = true);
        }

        //Update the event Status;
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'status',
          value: 'Published'
        });
        await this.$store.commit('EVENT_MANAGER_STORE/UPDATE_EVENT_PROPERTY', {
          key: 'published',
          value: '1'
        });

        let newEventId = await this.$store.dispatch(
          'EVENT_MANAGER_STORE/PATCH_EVENT',
          this.$route.params.id
        );
        let { id = null } = newEventId;

        //RELOAD the event
        if (id) {
          await this.$store.dispatch('EVENT_MANAGER_STORE/LOAD_EVENT', id);
          this.newEventId = id;
          this.successDialogTitle = 'Success';
          this.successDialogMessage =
            'Your Event has been published. Would you like to continue editing or return to the main dashboard?';
          this.successDialog = true;
        } else {
          let error = newEventId.errorMessage || newEventId.message;
          throw error;
        }
      } catch (error) {
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    scrollToWinners() {
      this.$refs.winnerFields.scrollToWinners();
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    },
    //Emails Start
    legalReviewEmail(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Event Review',
        greeting_text: `Hi, Legal Team,`,
        'body-1_text':
          'An event is ready to be reviewed for legal approval. Click the button below to review the event details.',
        'cta-1_href':
          'https://www.t-mobileengagementhub.com/#/legal/' +
          this.$route.params.id,
        'cta-1_text': 'Review Event',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text:
          'Legal Review Request for Event ID: ' + this.$route.params.id,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Legal Review <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    userEventSubmittedLegalEmail(toAddress, eventName) {
      let eventId = this.$route.params.id;
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Legal Review Request',
        greeting_text: `Greetings,`,
        'body-1_text': 'Your event has been sent to be reviewed by legal.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text: 'Legal Review Request Receipt for Event ID: ' + eventId,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source:
          'Legal Review Submission <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    pauseLegalReviewEmail(toAddress, eventName) {
      let eventId = this.$route.params.id;
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'Legal Review Paused',
        greeting_text: `Hi, Legal Team,`,
        'body-1_text':
          'An event that had previously been submitted for review is currently being edited. You will not be able to complete legal review on the event listed below until it is re-submitted for approval.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event ID: ' +
          eventId +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text: 'Review Paused for Event ID: ' + eventId,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Legal Review <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    userEventPauseEmail(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Pause',
        greeting_text: `Greetings,`,
        'body-1_text': 'Your event submission has been paused.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text: 'Pause Alert for Event ID: ' + this.$route.params.id,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Pause Alert <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    pauseTranslationEmail(toAddress, eventName) {
      let eventId = this.$route.params.id;
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'Translation Paused',
        greeting_text: `Hi, Translation Team,`,
        'body-1_text':
          'An event that had previously been submitted for translation is currently being edited. You will not be able to complete translations on the event listed below until it is re-submitted for approval.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event ID: ' +
          eventId +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text: 'Translation Paused for Event ID: ' + eventId,
        'text-part_text': ''
      };
      let emailTemplate = {
        Source: 'Translation <donotreply@t-mobileengagementhub.com>',
        Template: 'no-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    }

    //Emails End
  },
  computed: {
    ...mapState('EVENT_MANAGER_STORE', [
      'CURRENT_EVENT_OBJECT',
      'LOADING',
      'BITLY_LINK',
      'API_CALL_IN_PROGRESS'
    ]),
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`,
      translations: `translations`
    }),
    ...mapState('PROXY_STORE', ['TABLE_DATA']),
    ...mapState('LEGAL_STORE', ['CURRENT_LEGAL_OBJECT']),
    ...mapState('EVENT_HISTORY_STORE', ['HISTORY_TABLE_DATA']),
    hideAllFormButtons() {
      //
      if (!this.userAttributes['email']) return true;
      if (
        this.CURRENT_EVENT_OBJECT['createdByEmail'] &&
        this.CURRENT_EVENT_OBJECT['createdByEmail'] ===
          this.userAttributes['email']
      )
        return false;
      if (this.isAdmin) {
        return false;
      }

      return !this.TABLE_DATA.filter(
        proxy =>
          proxy.users_email === this.userAttributes['email'].toLowerCase()
      ).length;
    },
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    },
    eventName() {
      return this.LOADING
        ? null
        : !this.CURRENT_EVENT_OBJECT.name
        ? null
        : this.CURRENT_EVENT_OBJECT.name;
    },
    eventStatusTitle() {
      return !this.CURRENT_EVENT_OBJECT.status
        ? 'Loading'
        : this.CURRENT_EVENT_OBJECT.archived
        ? 'Archived'
        : this.CURRENT_EVENT_OBJECT.status;
    },
    urlRoot() {
      return window.location.origin;
    },
    event_code() {
      return this.CURRENT_EVENT_OBJECT.event_code || null;
    },
    validateDraftEvtObj() {
      let errorArr = [];
      const errors = validate(this.CURRENT_EVENT_OBJECT, DraftEventSchema);

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },
    validateSubmitToLegal() {
      let errorArr = [];
      let enterToWin =
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win';
      const errors = validate(
        this.CURRENT_EVENT_OBJECT,
        enterToWin ? EnterToWinEventSchema : SubmitToLegalEventSchema
      );

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },
    validateSubmitToTranslations() {
      let errorArr = [];
      let enterToWin =
        this.CURRENT_EVENT_OBJECT.contestType === 'Enter for a Chance to Win';
      const errors = validate(
        this.CURRENT_EVENT_OBJECT,
        enterToWin ? EnterToWinEventSchema : SubmitToTranslationSchema
      );

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },
    validateSubmitBatchEvent() {
      let errorArr = [];
      const errors = validate(
        this.CURRENT_EVENT_OBJECT,
        BatchImportEventSchema
      );

      if (errors.length > 0) {
        for (const { message } of errors) {
          errorArr.push(message);
        }
      } else {
        errorArr = [];
      }
      return errorArr;
    },
    isBatchEvent() {
      return this.LOADING
        ? false
        : !this.CURRENT_EVENT_OBJECT.contestType
        ? false
        : this.CURRENT_EVENT_OBJECT.contestType === 'Batch Import Event';
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.UNTRACKED_CHANGES_TO_EVENT) {
      this.untrackedChangesDialog = true;
      this.targetToRoute = to.fullPath || to.path;
    } else {
      next();
    }
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', this.onResize, { passive: true });
    this.$store.commit('UI_STORE/TOGGLE_EXPANSION_PANEL', 'hide');
  }
};
</script>

<style scoped>
.loading-row {
  padding: 32px;
  text-align: center;
}
</style>
<style lang="scss" scoped>
::v-deep .event-brand .v-input--selection-controls__input {
  display: none;
}

::v-deep .v-input--checkbox {
  margin-top: 0;
}

.date-time-picker-wrap .v-text-field {
  margin-top: 0px;
}

::v-deep .v-input--radio-group {
  margin-top: 0;
  padding-top: 0;
}

.fixed-buttons {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #9b9b9b;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 1;
}

.exit-button {
  background-color: white;
}

.button-text {
  font-weight: bold;
}

::v-deep .v-btn--outlined {
  border-width: 2px !important;
}

.wide-container {
  // max-width: 1442px;
  margin: auto;

  .row {
    @media (min-width: 1400px) {
      max-width: 1706px;
      margin: auto;
    }

    @media (max-width: 767px) {
      max-width: 100vw;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media (max-width: 1470px) {
  // .wide-container {
  //   // width: 90%;
  // }
}

.event-link {
  font-weight: bold;
}

.bitly-skeleton {
  display: inline-block;
  width: 100px;
  margin-top: 5px;
}

.form-buttons {
  margin-top: 50px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0;
  }
}
::v-deep .form-section .gutter-padding {
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
}
</style>
