export default class OptionData {
  static Languages() {
    return [
      { value: 'en', text: 'English' },
      { value: 'bi', text: 'English and Spanish' }
    ];
  }

  static EventLanguages() {
    return [
      { value: 'en', text: 'English' },
      { value: 'bi', text: 'English and Spanish' }
    ];
  }

  static EventDefaultLanguages() {
    return [
      { value: 'en', text: 'English' },
      { value: 'es', text: 'Spanish' }
    ];
  }

  static States() {
    return [
      { value: 'AL', text: 'Alabama' },
      { value: 'AK', text: 'Alaska' },
      { value: 'AZ', text: 'Arizona' },
      { value: 'AR', text: 'Arkansas' },
      { value: 'CA', text: 'California' },
      { value: 'CO', text: 'Colorado' },
      { value: 'CT', text: 'Connecticut' },
      { value: 'DE', text: 'Delaware' },
      { value: 'DC', text: 'District Of Columbia' },
      { value: 'FL', text: 'Florida' },
      { value: 'GA', text: 'Georgia' },
      { value: 'HI', text: 'Hawaii' },
      { value: 'ID', text: 'Idaho' },
      { value: 'IL', text: 'Illinois' },
      { value: 'IN', text: 'Indiana' },
      { value: 'IA', text: 'Iowa' },
      { value: 'KS', text: 'Kansas' },
      { value: 'KY', text: 'Kentucky' },
      { value: 'LA', text: 'Louisiana' },
      { value: 'ME', text: 'Maine' },
      { value: 'MD', text: 'Maryland' },
      { value: 'MA', text: 'Massachusetts' },
      { value: 'MI', text: 'Michigan' },
      { value: 'MN', text: 'Minnesota' },
      { value: 'MS', text: 'Mississippi' },
      { value: 'MO', text: 'Missouri' },
      { value: 'MT', text: 'Montana' },
      { value: 'NE', text: 'Nebraska' },
      { value: 'NV', text: 'Nevada' },
      { value: 'NH', text: 'New Hampshire' },
      { value: 'NJ', text: 'New Jersey' },
      { value: 'NM', text: 'New Mexico' },
      { value: 'NY', text: 'New York' },
      { value: 'NC', text: 'North Carolina' },
      { value: 'ND', text: 'North Dakota' },
      { value: 'OH', text: 'Ohio' },
      { value: 'OK', text: 'Oklahoma' },
      { value: 'OR', text: 'Oregon' },
      { value: 'PA', text: 'Pennsylvania' },
      { value: 'PR', text: 'Puerto Rico' },
      { value: 'RI', text: 'Rhode Island' },
      { value: 'SC', text: 'South Carolina' },
      { value: 'SD', text: 'South Dakota' },
      { value: 'TN', text: 'Tennessee' },
      { value: 'TX', text: 'Texas' },
      { value: 'UT', text: 'Utah' },
      { value: 'VT', text: 'Vermont' },
      { value: 'VA', text: 'Virginia' },
      { value: 'WA', text: 'Washington' },
      { value: 'WV', text: 'West Virginia' },
      { value: 'WI', text: 'Wisconsin' },
      { value: 'WY', text: 'Wyoming' }
    ];
  }

  static DMAs() {
    return [
      { text: 'ALBUQUERQUE-SANTA FE', value: 'ALBUQUERQUE-SANTA FE' },
      { text: 'ATLANTA', value: 'ATLANTA' },
      { text: 'AUSTIN', value: 'AUSTIN' },
      { text: 'BAKERSFIELD', value: 'BAKERSFIELD' },
      { text: 'BALTIMORE', value: 'BALTIMORE' },
      { text: 'BEAUMONT-PORT ARTHUR', value: 'BEAUMONT-PORT ARTHUR' },
      { text: 'BEND OR', value: 'BEND OR' },
      { text: 'BIRMINGHAM (ANN AND TUSC)', value: 'BIRMINGHAM (ANN AND TUSC)' },
      { text: 'BOISE', value: 'BOISE' },
      { text: 'BOSTON (MANCHESTER)', value: 'BOSTON (MANCHESTER)' },
      { text: 'BUFFALO', value: 'BUFFALO' },
      { text: 'CHARLOTTE', value: 'CHARLOTTE' },
      { text: 'CHICAGO', value: 'CHICAGO' },
      { text: 'CLEVELAND-AKRON (CANTON)', value: 'CLEVELAND-AKRON (CANTON)' },
      { text: 'COLUMBIA SC', value: 'COLUMBIA SC' },
      { text: 'COLUMBUS OH', value: 'COLUMBUS OH' },
      { text: 'DALLAS-FT. WORTH', value: 'DALLAS-FT. WORTH' },
      { text: 'DAYTON', value: 'DAYTON' },
      { text: 'DENVER', value: 'DENVER' },
      { text: 'DETROIT', value: 'DETROIT' },
      { text: 'FRESNO-VISALIA', value: 'FRESNO-VISALIA' },
      {
        text: 'GREENVLL-SPART-ASHEVLL-AND',
        value: 'GREENVLL-SPART-ASHEVLL-AND'
      },
      {
        text: 'HARRISBURG-LNCSTR-LEB-YORK',
        value: 'HARRISBURG-LNCSTR-LEB-YORK'
      },
      { text: 'HOUSTON', value: 'HOUSTON' },
      { text: 'INDIANAPOLIS', value: 'INDIANAPOLIS' },
      { text: 'JACKSONVILLE', value: 'JACKSONVILLE' },
      { text: 'KANSAS CITY', value: 'KANSAS CITY' },
      { text: 'LOS ANGELES', value: 'LOS ANGELES' },
      { text: 'LOUISVILLE', value: 'LOUISVILLE' },
      { text: 'LUBBOCK', value: 'LUBBOCK' },
      { text: 'MEMPHIS', value: 'MEMPHIS' },
      { text: 'MIAMI-FT. LAUDERDALE', value: 'MIAMI-FT. LAUDERDALE' },
      { text: 'MINNEAPOLIS-ST. PAUL', value: 'MINNEAPOLIS-ST. PAUL' },
      { text: 'NASHVILLE', value: 'NASHVILLE' },
      { text: 'NEW ORLEANS', value: 'NEW ORLEANS' },
      { text: 'NEW YORK', value: 'NEW YORK' },
      {
        text: 'NORFOLK-PORTSMTH-NEWPT NWS',
        value: 'NORFOLK-PORTSMTH-NEWPT NWS'
      },
      { text: 'OKLAHOMA CITY', value: 'OKLAHOMA CITY' },
      {
        text: 'ORLANDO-DAYTONA BCH-MELBRN',
        value: 'ORLANDO-DAYTONA BCH-MELBRN'
      },
      { text: 'PHILADELPHIA', value: 'PHILADELPHIA' },
      { text: 'PHOENIX (PRESCOTT)', value: 'PHOENIX (PRESCOTT)' },
      { text: 'PITTSBURGH', value: 'PITTSBURGH' },
      { text: 'PORTLAND OR', value: 'PORTLAND OR' },
      {
        text: 'RALEIGH-DURHAM (FAYETVLLE)',
        value: 'RALEIGH-DURHAM (FAYETVLLE)'
      },
      { text: 'ROCHESTER NY', value: 'ROCHESTER NY' },
      { text: 'SACRAMNTO-STKTON-MODESTO', value: 'SACRAMNTO-STKTON-MODESTO' },
      { text: 'SALT LAKE CITY', value: 'SALT LAKE CITY' },
      { text: 'SAN ANTONIO', value: 'SAN ANTONIO' },
      {
        text: 'SAN FRANCISCO-OAK-SAN JOSE',
        value: 'SAN FRANCISCO-OAK-SAN JOSE'
      },
      { text: 'SAVANNAH', value: 'SAVANNAH' },
      { text: 'SEATTLE-TACOMA', value: 'SEATTLE-TACOMA' },
      { text: 'SPOKANE', value: 'SPOKANE' },
      { text: 'ST. LOUIS', value: 'ST. LOUIS' },
      { text: 'TAMPA-ST. PETE (SARASOTA)', value: 'TAMPA-ST. PETE (SARASOTA)' },
      { text: 'TULSA', value: 'TULSA' },
      { text: 'WASHINGTON DC (HAGRSTWN)', value: 'WASHINGTON DC (HAGRSTWN)' },
      { text: 'WILKES BARRE-SCRANTON', value: 'WILKES BARRE-SCRANTON' },
      { text: 'ABILENE-SWEETWATER', value: 'ABILENE-SWEETWATER' },
      { text: 'ALBANY GA', value: 'ALBANY GA' },
      { text: 'ALBANY-SCHENECTADY-TROY', value: 'ALBANY-SCHENECTADY-TROY' },
      { text: 'ALEXANDRIA LA', value: 'ALEXANDRIA LA' },
      { text: 'ALPENA', value: 'ALPENA' },
      { text: 'AMARILLO', value: 'AMARILLO' },
      { text: 'ANCHORAGE', value: 'ANCHORAGE' },
      { text: 'AUGUSTA', value: 'AUGUSTA' },
      { text: 'BANGOR', value: 'BANGOR' },
      { text: 'BATON ROUGE', value: 'BATON ROUGE' },
      { text: 'BILLINGS', value: 'BILLINGS' },
      { text: 'BILOXI-GULFPORT', value: 'BILOXI-GULFPORT' },
      { text: 'BINGHAMTON', value: 'BINGHAMTON' },
      {
        text: 'BLUEFIELD-BECKLEY-OAK HILL',
        value: 'BLUEFIELD-BECKLEY-OAK HILL'
      },
      { text: 'BOWLING GREEN', value: 'BOWLING GREEN' },
      { text: 'BURLINGTON-PLATTSBURGH', value: 'BURLINGTON-PLATTSBURGH' },
      { text: 'BUTTE-BOZEMAN', value: 'BUTTE-BOZEMAN' },
      { text: 'CASPER-RIVERTON', value: 'CASPER-RIVERTON' },
      {
        text: 'CEDAR RAPIDS-WTRLO-IWC&DUB',
        value: 'CEDAR RAPIDS-WTRLO-IWC&DUB'
      },
      {
        text: 'CHAMPAIGN&SPRNGFLD-DECATUR',
        value: 'CHAMPAIGN&SPRNGFLD-DECATUR'
      },
      { text: 'CHARLESTON SC', value: 'CHARLESTON SC' },
      { text: 'CHARLESTON-HUNTINGTON', value: 'CHARLESTON-HUNTINGTON' },
      { text: 'CHARLOTTESVILLE', value: 'CHARLOTTESVILLE' },
      { text: 'CHATTANOOGA', value: 'CHATTANOOGA' },
      { text: 'CHEYENNE-SCOTTSBLUF', value: 'CHEYENNE-SCOTTSBLUF' },
      { text: 'CHICO-REDDING', value: 'CHICO-REDDING' },
      { text: 'CINCINNATI', value: 'CINCINNATI' },
      { text: 'CLARKSBURG-WESTON', value: 'CLARKSBURG-WESTON' },
      { text: 'COLORADO SPRINGS-PUEBLO', value: 'COLORADO SPRINGS-PUEBLO' },
      { text: 'COLUMBIA-JEFFERSON CITY', value: 'COLUMBIA-JEFFERSON CITY' },
      { text: 'COLUMBUS GA (OPELIKA AL)', value: 'COLUMBUS GA (OPELIKA AL)' },
      {
        text: 'COLUMBUS-TUPELO-WEST POINT',
        value: 'COLUMBUS-TUPELO-WEST POINT'
      },
      { text: 'CORPUS CHRISTI', value: 'CORPUS CHRISTI' },
      { text: 'DAVENPORT-R.ISLAND-MOLINE', value: 'DAVENPORT-R.ISLAND-MOLINE' },
      { text: 'DES MOINES-AMES', value: 'DES MOINES-AMES' },
      { text: 'DOTHAN', value: 'DOTHAN' },
      { text: 'DULUTH-SUPERIOR', value: 'DULUTH-SUPERIOR' },
      { text: 'EL PASO (LAS CRUCES)', value: 'EL PASO (LAS CRUCES)' },
      { text: 'ELMIRA (CORNING)', value: 'ELMIRA (CORNING)' },
      { text: 'ERIE', value: 'ERIE' },
      { text: 'EUGENE', value: 'EUGENE' },
      { text: 'EUREKA', value: 'EUREKA' },
      { text: 'EVANSVILLE', value: 'EVANSVILLE' },
      { text: 'FAIRBANKS', value: 'FAIRBANKS' },
      { text: 'FARGO-VALLEY CITY', value: 'FARGO-VALLEY CITY' },
      { text: 'FLINT-SAGINAW-BAY CITY', value: 'FLINT-SAGINAW-BAY CITY' },
      { text: 'FT. MYERS-NAPLES', value: 'FT. MYERS-NAPLES' },
      {
        text: 'FT. SMITH-FAY-SPRNGDL-RGRS',
        value: 'FT. SMITH-FAY-SPRNGDL-RGRS'
      },
      { text: 'FT. WAYNE', value: 'FT. WAYNE' },
      { text: 'GAINESVILLE', value: 'GAINESVILLE' },
      { text: 'GLENDIVE', value: 'GLENDIVE' },
      { text: 'GRAND JUNCTION-MONTROSE', value: 'GRAND JUNCTION-MONTROSE' },
      {
        text: 'GRAND RAPIDS-KALMZOO-B.CRK',
        value: 'GRAND RAPIDS-KALMZOO-B.CRK'
      },
      { text: 'GREAT FALLS', value: 'GREAT FALLS' },
      { text: 'GREEN BAY-APPLETON', value: 'GREEN BAY-APPLETON' },
      {
        text: 'GREENSBORO-H.POINT-W.SALEM',
        value: 'GREENSBORO-H.POINT-W.SALEM'
      },
      {
        text: 'GREENVILLE-N.BERN-WASHNGTN',
        value: 'GREENVILLE-N.BERN-WASHNGTN'
      },
      { text: 'GREENWOOD-GREENVILLE', value: 'GREENWOOD-GREENVILLE' },
      {
        text: 'HARLINGEN-WSLCO-BRNSVL-MCA',
        value: 'HARLINGEN-WSLCO-BRNSVL-MCA'
      },
      { text: 'HARRISONBURG', value: 'HARRISONBURG' },
      { text: 'HARTFORD & NEW HAVEN', value: 'HARTFORD & NEW HAVEN' },
      { text: 'HATTIESBURG-LAUREL', value: 'HATTIESBURG-LAUREL' },
      { text: 'HELENA', value: 'HELENA' },
      { text: 'HONOLULU', value: 'HONOLULU' },
      { text: 'HUNTSVILLE-DECATUR (FLOR)', value: 'HUNTSVILLE-DECATUR (FLOR)' },
      { text: 'IDAHO FALLS-POCATELLO', value: 'IDAHO FALLS-POCATELLO' },
      { text: 'JACKSON MS', value: 'JACKSON MS' },
      { text: 'JACKSON TN', value: 'JACKSON TN' },
      {
        text: 'JOHNSTOWN-ALTOONA-ST COLGE',
        value: 'JOHNSTOWN-ALTOONA-ST COLGE'
      },
      { text: 'JONESBORO', value: 'JONESBORO' },
      { text: 'JOPLIN-PITTSBURG', value: 'JOPLIN-PITTSBURG' },
      { text: 'JUNEAU', value: 'JUNEAU' },
      { text: 'KNOXVILLE', value: 'KNOXVILLE' },
      { text: 'LA CROSSE-EAU CLAIRE', value: 'LA CROSSE-EAU CLAIRE' },
      { text: 'LAFAYETTE IN', value: 'LAFAYETTE IN' },
      { text: 'LAFAYETTE LA', value: 'LAFAYETTE LA' },
      { text: 'LAKE CHARLES', value: 'LAKE CHARLES' },
      { text: 'LANSING', value: 'LANSING' },
      { text: 'LAREDO', value: 'LAREDO' },
      { text: 'LAS VEGAS', value: 'LAS VEGAS' },
      { text: 'LEXINGTON', value: 'LEXINGTON' },
      { text: 'LIMA', value: 'LIMA' },
      { text: 'LINCOLN & HASTINGS-KRNY', value: 'LINCOLN & HASTINGS-KRNY' },
      { text: 'LITTLE ROCK-PINE BLUFF', value: 'LITTLE ROCK-PINE BLUFF' },
      { text: 'MACON', value: 'MACON' },
      { text: 'MADISON', value: 'MADISON' },
      { text: 'MANKATO', value: 'MANKATO' },
      { text: 'MARQUETTE', value: 'MARQUETTE' },
      { text: 'MEDFORD-KLAMATH FALLS', value: 'MEDFORD-KLAMATH FALLS' },
      { text: 'MERIDIAN', value: 'MERIDIAN' },
      { text: 'MILWAUKEE', value: 'MILWAUKEE' },
      { text: 'MINOT-BISMARCK-DICKINSON', value: 'MINOT-BISMARCK-DICKINSON' },
      { text: 'MISSOULA', value: 'MISSOULA' },
      {
        text: 'MOBILE-PENSACOLA (FT WALT)',
        value: 'MOBILE-PENSACOLA (FT WALT)'
      },
      { text: 'MONROE-EL DORADO', value: 'MONROE-EL DORADO' },
      { text: 'MONTEREY-SALINAS', value: 'MONTEREY-SALINAS' },
      { text: 'MONTGOMERY-SELMA', value: 'MONTGOMERY-SELMA' },
      { text: 'MYRTLE BEACH-FLORENCE', value: 'MYRTLE BEACH-FLORENCE' },
      { text: 'NORTH PLATTE', value: 'NORTH PLATTE' },
      { text: 'ODESSA-MIDLAND', value: 'ODESSA-MIDLAND' },
      { text: 'OMAHA', value: 'OMAHA' },
      { text: 'OTTUMWA-KIRKSVILLE', value: 'OTTUMWA-KIRKSVILLE' },
      {
        text: 'PADUCAH-CAPE GIRARD-HARSBG',
        value: 'PADUCAH-CAPE GIRARD-HARSBG'
      },
      { text: 'PALM SPRINGS', value: 'PALM SPRINGS' },
      { text: 'PANAMA CITY', value: 'PANAMA CITY' },
      { text: 'PARKERSBURG', value: 'PARKERSBURG' },
      { text: 'PEORIA-BLOOMINGTON', value: 'PEORIA-BLOOMINGTON' },
      { text: 'PORTLAND-AUBURN', value: 'PORTLAND-AUBURN' },
      { text: 'PRESQUE ISLE', value: 'PRESQUE ISLE' },
      { text: 'PROVIDENCE-NEW BEDFORD', value: 'PROVIDENCE-NEW BEDFORD' },
      { text: 'Puerto Rico', value: 'Puerto Rico' },
      { text: 'QUINCY-HANNIBAL-KEOKUK', value: 'QUINCY-HANNIBAL-KEOKUK' },
      { text: 'RAPID CITY', value: 'RAPID CITY' },
      { text: 'RENO', value: 'RENO' },
      { text: 'RICHMOND-PETERSBURG', value: 'RICHMOND-PETERSBURG' },
      { text: 'ROANOKE-LYNCHBURG', value: 'ROANOKE-LYNCHBURG' },
      {
        text: 'ROCHESTR-MASON CITY-AUSTIN',
        value: 'ROCHESTR-MASON CITY-AUSTIN'
      },
      { text: 'ROCKFORD', value: 'ROCKFORD' },
      { text: 'SALISBURY', value: 'SALISBURY' },
      { text: 'SAN ANGELO', value: 'SAN ANGELO' },
      { text: 'SAN DIEGO', value: 'SAN DIEGO' },
      {
        text: 'SANTABARBRA-SANMAR-SANLUOB',
        value: 'SANTABARBRA-SANMAR-SANLUOB'
      },
      { text: 'SHERMAN-ADA', value: 'SHERMAN-ADA' },
      { text: 'SHREVEPORT', value: 'SHREVEPORT' },
      { text: 'SIOUX CITY', value: 'SIOUX CITY' },
      { text: 'SIOUX FALLS(MITCHELL)', value: 'SIOUX FALLS(MITCHELL)' },
      { text: 'SOUTH BEND-ELKHART', value: 'SOUTH BEND-ELKHART' },
      { text: 'SPRINGFIELD-HOLYOKE', value: 'SPRINGFIELD-HOLYOKE' },
      { text: 'SPRINGFIELD MO', value: 'SPRINGFIELD MO' },
      { text: 'ST. JOSEPH', value: 'ST. JOSEPH' },
      { text: 'SYRACUSE', value: 'SYRACUSE' },
      { text: 'TALLAHASSEE-THOMASVILLE', value: 'TALLAHASSEE-THOMASVILLE' },
      { text: 'TERRE HAUTE', value: 'TERRE HAUTE' },
      { text: 'TOLEDO', value: 'TOLEDO' },
      { text: 'TOPEKA', value: 'TOPEKA' },
      { text: 'TRAVERSE CITY-CADILLAC', value: 'TRAVERSE CITY-CADILLAC' },
      { text: 'TRI-CITIES TN-VA', value: 'TRI-CITIES TN-VA' },
      { text: 'TUCSON (SIERRA VISTA)', value: 'TUCSON (SIERRA VISTA)' },
      { text: 'TWIN FALLS', value: 'TWIN FALLS' },
      { text: 'TYLER-LONGVIEW(LFKN&NCGD)', value: 'TYLER-LONGVIEW(LFKN&NCGD)' },
      { text: 'UTICA', value: 'UTICA' },
      { text: 'VICTORIA', value: 'VICTORIA' },
      { text: 'WACO-TEMPLE-BRYAN', value: 'WACO-TEMPLE-BRYAN' },
      { text: 'WATERTOWN', value: 'WATERTOWN' },
      { text: 'WAUSAU-RHINELANDER', value: 'WAUSAU-RHINELANDER' },
      {
        text: 'WEST PALM BEACH-FT. PIERCE',
        value: 'WEST PALM BEACH-FT. PIERCE'
      },
      { text: 'WHEELING-STEUBENVILLE', value: 'WHEELING-STEUBENVILLE' },
      { text: 'WICHITA-HUTCHINSON PLUS', value: 'WICHITA-HUTCHINSON PLUS' },
      { text: 'WICHITA FALLS & LAWTON', value: 'WICHITA FALLS & LAWTON' },
      { text: 'WILMINGTON', value: 'WILMINGTON' },
      {
        text: 'YAKIMA-PASCO-RCHLND-KNNWCK',
        value: 'YAKIMA-PASCO-RCHLND-KNNWCK'
      },
      { text: 'YOUNGSTOWN', value: 'YOUNGSTOWN' },
      { text: 'YUMA-EL CENTRO', value: 'YUMA-EL CENTRO' },
      { text: 'ZANESVILLE', value: 'ZANESVILLE' }
    ];
  }

  static TimeZones() {
    return [
      { value: 'US/Hawaii', text: 'Hawaii' },
      { value: 'US/Alaska', text: 'Alaska' },
      { value: 'America/Los_Angeles', text: 'Pacific' },
      { value: 'America/Denver', text: 'Mountain' },
      { value: 'America/Chicago', text: 'Central' },
      { value: 'America/New_York', text: 'Eastern' }
    ];
  }

  static MSAs() {
    return [
      { text: 'Aberdeen, SD', value: 'Aberdeen, SD' },
      { text: 'Aberdeen, WA', value: 'Aberdeen, WA' },
      { text: 'Abilene, TX', value: 'Abilene, TX' },
      { text: 'Ada, OK', value: 'Ada, OK' },
      { text: 'Adjuntas, PR', value: 'Adjuntas, PR' },
      { text: 'Adrian, MI', value: 'Adrian, MI' },
      { text: 'Aguadilla-Isabela, PR', value: 'Aguadilla-Isabela, PR' },
      { text: 'Akron, OH', value: 'Akron, OH' },
      { text: 'Alamogordo, NM', value: 'Alamogordo, NM' },
      { text: 'Albany, GA', value: 'Albany, GA' },
      { text: 'Albany, NY', value: 'Albany, NY' },
      { text: 'Albany, OR', value: 'Albany, OR' },
      { text: 'Albemarle, NC', value: 'Albemarle, NC' },
      { text: 'Albert Lea, MN', value: 'Albert Lea, MN' },
      { text: 'Albertville, AL', value: 'Albertville, AL' },
      { text: 'Albuquerque, NM', value: 'Albuquerque, NM' },
      { text: 'Alexandria, LA', value: 'Alexandria, LA' },
      { text: 'Alexandria, MN', value: 'Alexandria, MN' },
      { text: 'Alice, TX', value: 'Alice, TX' },
      { text: 'Allentown, PA', value: 'Allentown, PA' },
      { text: 'Alma, MI', value: 'Alma, MI' },
      { text: 'Alpena, MI', value: 'Alpena, MI' },
      { text: 'Altoona, PA', value: 'Altoona, PA' },
      { text: 'Altus, OK', value: 'Altus, OK' },
      { text: 'Amarillo, TX', value: 'Amarillo, TX' },
      { text: 'Americus, GA', value: 'Americus, GA' },
      { text: 'Ames, IA', value: 'Ames, IA' },
      { text: 'Amsterdam, NY', value: 'Amsterdam, NY' },
      { text: 'Anaheim, CA', value: 'Anaheim, CA' },
      { text: 'Anchorage, AK', value: 'Anchorage, AK' },
      { text: 'Andrews, TX', value: 'Andrews, TX' },
      { text: 'Angola, IN', value: 'Angola, IN' },
      { text: 'Ann Arbor, MI', value: 'Ann Arbor, MI' },
      { text: 'Anniston, AL', value: 'Anniston, AL' },
      { text: 'Appleton, WI', value: 'Appleton, WI' },
      { text: 'Arcadia, FL', value: 'Arcadia, FL' },
      { text: 'Ardmore, OK', value: 'Ardmore, OK' },
      { text: 'Arecibo, PR', value: 'Arecibo, PR' },
      { text: 'Arkadelphia, AR', value: 'Arkadelphia, AR' },
      { text: 'Arkansas City, KS', value: 'Arkansas City, KS' },
      { text: 'Asheville, NC', value: 'Asheville, NC' },
      { text: 'Ashland, OH', value: 'Ashland, OH' },
      { text: 'Ashtabula, OH', value: 'Ashtabula, OH' },
      { text: 'Astoria, OR', value: 'Astoria, OR' },
      { text: 'Atchison, KS', value: 'Atchison, KS' },
      { text: 'Athens, GA', value: 'Athens, GA' },
      { text: 'Athens, OH', value: 'Athens, OH' },
      { text: 'Athens, TN', value: 'Athens, TN' },
      { text: 'Athens, TX', value: 'Athens, TX' },
      { text: 'Atlanta, GA', value: 'Atlanta, GA' },
      { text: 'Atlantic City, NJ', value: 'Atlantic City, NJ' },
      { text: 'Auburn, AL', value: 'Auburn, AL' },
      { text: 'Auburn, IN', value: 'Auburn, IN' },
      { text: 'Auburn, NY', value: 'Auburn, NY' },
      { text: 'Augusta, GA', value: 'Augusta, GA' },
      { text: 'Augusta, ME', value: 'Augusta, ME' },
      { text: 'Austin, MN', value: 'Austin, MN' },
      { text: 'Austin, TX', value: 'Austin, TX' },
      { text: 'Bainbridge, GA', value: 'Bainbridge, GA' },
      { text: 'Bakersfield, CA', value: 'Bakersfield, CA' },
      { text: 'Baltimore, MD', value: 'Baltimore, MD' },
      { text: 'Bangor, ME', value: 'Bangor, ME' },
      { text: 'Baraboo, WI', value: 'Baraboo, WI' },
      { text: 'Bardstown, KY', value: 'Bardstown, KY' },
      { text: 'Barnstable Town, MA', value: 'Barnstable Town, MA' },
      { text: 'Barre, VT', value: 'Barre, VT' },
      { text: 'Bartlesville, OK', value: 'Bartlesville, OK' },
      { text: 'Bastrop, LA', value: 'Bastrop, LA' },
      { text: 'Batavia, NY', value: 'Batavia, NY' },
      { text: 'Batesville, AR', value: 'Batesville, AR' },
      { text: 'Baton Rouge, LA', value: 'Baton Rouge, LA' },
      { text: 'Battle Creek, MI', value: 'Battle Creek, MI' },
      { text: 'Bay City, MI', value: 'Bay City, MI' },
      { text: 'Bay City, TX', value: 'Bay City, TX' },
      { text: 'Beatrice, NE', value: 'Beatrice, NE' },
      { text: 'Beaumont, TX', value: 'Beaumont, TX' },
      { text: 'Beaver Dam, WI', value: 'Beaver Dam, WI' },
      { text: 'Beckley, WV', value: 'Beckley, WV' },
      { text: 'Bedford, IN', value: 'Bedford, IN' },
      { text: 'Beeville, TX', value: 'Beeville, TX' },
      { text: 'Bellefontaine, OH', value: 'Bellefontaine, OH' },
      { text: 'Bellingham, WA', value: 'Bellingham, WA' },
      { text: 'Bemidji, MN', value: 'Bemidji, MN' },
      { text: 'Bend, OR', value: 'Bend, OR' },
      { text: 'Bennettsville, SC', value: 'Bennettsville, SC' },
      { text: 'Bennington, VT', value: 'Bennington, VT' },
      { text: 'Berlin, NH', value: 'Berlin, NH' },
      { text: 'Big Rapids, MI', value: 'Big Rapids, MI' },
      { text: 'Big Spring, TX', value: 'Big Spring, TX' },
      { text: 'Big Stone Gap, VA', value: 'Big Stone Gap, VA' },
      { text: 'Billings, MT', value: 'Billings, MT' },
      { text: 'Binghamton, NY', value: 'Binghamton, NY' },
      { text: 'Birmingham, AL', value: 'Birmingham, AL' },
      { text: 'Bismarck, ND', value: 'Bismarck, ND' },
      { text: 'Blackfoot, ID', value: 'Blackfoot, ID' },
      { text: 'Blacksburg, VA', value: 'Blacksburg, VA' },
      { text: 'Bloomington, IL', value: 'Bloomington, IL' },
      { text: 'Bloomington, IN', value: 'Bloomington, IN' },
      { text: 'Bloomsburg, PA', value: 'Bloomsburg, PA' },
      { text: 'Bluefield, WV', value: 'Bluefield, WV' },
      { text: 'Blytheville, AR', value: 'Blytheville, AR' },
      { text: 'Bogalusa, LA', value: 'Bogalusa, LA' },
      { text: 'Boise City, ID', value: 'Boise City, ID' },
      { text: 'Boone, IA', value: 'Boone, IA' },
      { text: 'Boone, NC', value: 'Boone, NC' },
      { text: 'Borger, TX', value: 'Borger, TX' },
      { text: 'Boston, MA', value: 'Boston, MA' },
      { text: 'Boulder, CO', value: 'Boulder, CO' },
      { text: 'Bowling Green, KY', value: 'Bowling Green, KY' },
      { text: 'Bozeman, MT', value: 'Bozeman, MT' },
      { text: 'Bradford, PA', value: 'Bradford, PA' },
      { text: 'Brainerd, MN', value: 'Brainerd, MN' },
      { text: 'Branson, MO', value: 'Branson, MO' },
      { text: 'Breckenridge, CO', value: 'Breckenridge, CO' },
      { text: 'Bremerton, WA', value: 'Bremerton, WA' },
      { text: 'Brenham, TX', value: 'Brenham, TX' },
      { text: 'Brevard, NC', value: 'Brevard, NC' },
      { text: 'Bridgeport, CT', value: 'Bridgeport, CT' },
      { text: 'Brookhaven, MS', value: 'Brookhaven, MS' },
      { text: 'Brookings, OR', value: 'Brookings, OR' },
      { text: 'Brookings, SD', value: 'Brookings, SD' },
      { text: 'Brownsville, TX', value: 'Brownsville, TX' },
      { text: 'Brownwood, TX', value: 'Brownwood, TX' },
      { text: 'Brunswick, GA', value: 'Brunswick, GA' },
      { text: 'Bucyrus, OH', value: 'Bucyrus, OH' },
      { text: 'Buffalo, NY', value: 'Buffalo, NY' },
      { text: 'Burley, ID', value: 'Burley, ID' },
      { text: 'Burlington, IA', value: 'Burlington, IA' },
      { text: 'Burlington, NC', value: 'Burlington, NC' },
      { text: 'Burlington, VT', value: 'Burlington, VT' },
      { text: 'Butte, MT', value: 'Butte, MT' },
      { text: 'Cadillac, MI', value: 'Cadillac, MI' },
      { text: 'Calhoun, GA', value: 'Calhoun, GA' },
      { text: 'California, MD', value: 'California, MD' },
      { text: 'Cambridge, MA', value: 'Cambridge, MA' },
      { text: 'Cambridge, MD', value: 'Cambridge, MD' },
      { text: 'Cambridge, OH', value: 'Cambridge, OH' },
      { text: 'Camden, AR', value: 'Camden, AR' },
      { text: 'Camden, NJ', value: 'Camden, NJ' },
      { text: 'Campbellsville, KY', value: 'Campbellsville, KY' },
      { text: 'Canon City, CO', value: 'Canon City, CO' },
      { text: 'Canton, IL', value: 'Canton, IL' },
      { text: 'Canton, OH', value: 'Canton, OH' },
      { text: 'Cape Coral, FL', value: 'Cape Coral, FL' },
      { text: 'Cape Girardeau, MO', value: 'Cape Girardeau, MO' },
      { text: 'Carbondale, IL', value: 'Carbondale, IL' },
      { text: 'Carlsbad, NM', value: 'Carlsbad, NM' },
      { text: 'Carson City, NV', value: 'Carson City, NV' },
      { text: 'Casper, WY', value: 'Casper, WY' },
      { text: 'Cedar City, UT', value: 'Cedar City, UT' },
      { text: 'Cedar Rapids, IA', value: 'Cedar Rapids, IA' },
      { text: 'Cedartown, GA', value: 'Cedartown, GA' },
      { text: 'Celina, OH', value: 'Celina, OH' },
      { text: 'Centralia, IL', value: 'Centralia, IL' },
      { text: 'Centralia, WA', value: 'Centralia, WA' },
      { text: 'Chambersburg, PA', value: 'Chambersburg, PA' },
      { text: 'Champaign, IL', value: 'Champaign, IL' },
      { text: 'Charleston, IL', value: 'Charleston, IL' },
      { text: 'Charleston, SC', value: 'Charleston, SC' },
      { text: 'Charleston, WV', value: 'Charleston, WV' },
      { text: 'Charlotte, NC', value: 'Charlotte, NC' },
      { text: 'Charlottesville, VA', value: 'Charlottesville, VA' },
      { text: 'Chattanooga, TN', value: 'Chattanooga, TN' },
      { text: 'Cheyenne, WY', value: 'Cheyenne, WY' },
      { text: 'Chicago, IL', value: 'Chicago, IL' },
      { text: 'Chico, CA', value: 'Chico, CA' },
      { text: 'Chillicothe, OH', value: 'Chillicothe, OH' },
      { text: 'Cincinnati, OH', value: 'Cincinnati, OH' },
      { text: 'Claremont, NH', value: 'Claremont, NH' },
      { text: 'Clarksburg, WV', value: 'Clarksburg, WV' },
      { text: 'Clarksdale, MS', value: 'Clarksdale, MS' },
      { text: 'Clarksville, TN', value: 'Clarksville, TN' },
      { text: 'Clearlake, CA', value: 'Clearlake, CA' },
      { text: 'Cleveland, MS', value: 'Cleveland, MS' },
      { text: 'Cleveland, OH', value: 'Cleveland, OH' },
      { text: 'Cleveland, TN', value: 'Cleveland, TN' },
      { text: 'Clewiston, FL', value: 'Clewiston, FL' },
      { text: 'Clinton, IA', value: 'Clinton, IA' },
      { text: 'Clovis, NM', value: 'Clovis, NM' },
      { text: 'Coamo, PR', value: 'Coamo, PR' },
      { text: 'Coco, PR', value: 'Coco, PR' },
      { text: "Coeur d'Alene, ID", value: "Coeur d'Alene, ID" },
      { text: 'Coffeyville, KS', value: 'Coffeyville, KS' },
      { text: 'Coldwater, MI', value: 'Coldwater, MI' },
      { text: 'College Station, TX', value: 'College Station, TX' },
      { text: 'Colorado Springs, CO', value: 'Colorado Springs, CO' },
      { text: 'Columbia, MO', value: 'Columbia, MO' },
      { text: 'Columbia, SC', value: 'Columbia, SC' },
      { text: 'Columbus, GA', value: 'Columbus, GA' },
      { text: 'Columbus, IN', value: 'Columbus, IN' },
      { text: 'Columbus, MS', value: 'Columbus, MS' },
      { text: 'Columbus, NE', value: 'Columbus, NE' },
      { text: 'Columbus, OH', value: 'Columbus, OH' },
      { text: 'Concord, NH', value: 'Concord, NH' },
      { text: 'Connersville, IN', value: 'Connersville, IN' },
      { text: 'Cookeville, TN', value: 'Cookeville, TN' },
      { text: 'Coos Bay, OR', value: 'Coos Bay, OR' },
      { text: 'Cordele, GA', value: 'Cordele, GA' },
      { text: 'Corinth, MS', value: 'Corinth, MS' },
      { text: 'Cornelia, GA', value: 'Cornelia, GA' },
      { text: 'Corning, NY', value: 'Corning, NY' },
      { text: 'Corpus Christi, TX', value: 'Corpus Christi, TX' },
      { text: 'Corsicana, TX', value: 'Corsicana, TX' },
      { text: 'Cortland, NY', value: 'Cortland, NY' },
      { text: 'Corvallis, OR', value: 'Corvallis, OR' },
      { text: 'Coshocton, OH', value: 'Coshocton, OH' },
      { text: 'Craig, CO', value: 'Craig, CO' },
      { text: 'Crawfordsville, IN', value: 'Crawfordsville, IN' },
      { text: 'Crescent City, CA', value: 'Crescent City, CA' },
      { text: 'Crestview, FL', value: 'Crestview, FL' },
      { text: 'Crossville, TN', value: 'Crossville, TN' },
      { text: 'Cullman, AL', value: 'Cullman, AL' },
      { text: 'Cullowhee, NC', value: 'Cullowhee, NC' },
      { text: 'Cumberland, MD', value: 'Cumberland, MD' },
      { text: 'Dallas, TX', value: 'Dallas, TX' },
      { text: 'Dalton, GA', value: 'Dalton, GA' },
      { text: 'Danville, IL', value: 'Danville, IL' },
      { text: 'Danville, KY', value: 'Danville, KY' },
      { text: 'Danville, VA', value: 'Danville, VA' },
      { text: 'Daphne, AL', value: 'Daphne, AL' },
      { text: 'Davenport, IA', value: 'Davenport, IA' },
      { text: 'Dayton, OH', value: 'Dayton, OH' },
      { text: 'Dayton, TN', value: 'Dayton, TN' },
      { text: 'Decatur, AL', value: 'Decatur, AL' },
      { text: 'Decatur, IL', value: 'Decatur, IL' },
      { text: 'Decatur, IN', value: 'Decatur, IN' },
      { text: 'Defiance, OH', value: 'Defiance, OH' },
      { text: 'Del Rio, TX', value: 'Del Rio, TX' },
      { text: 'Deltona, FL', value: 'Deltona, FL' },
      { text: 'Deming, NM', value: 'Deming, NM' },
      { text: 'Denver, CO', value: 'Denver, CO' },
      { text: 'DeRidder, LA', value: 'DeRidder, LA' },
      { text: 'Des Moines, IA', value: 'Des Moines, IA' },
      { text: 'Detroit, MI', value: 'Detroit, MI' },
      { text: 'Dickinson, ND', value: 'Dickinson, ND' },
      { text: 'Dixon, IL', value: 'Dixon, IL' },
      { text: 'Dodge City, KS', value: 'Dodge City, KS' },
      { text: 'Dothan, AL', value: 'Dothan, AL' },
      { text: 'Douglas, GA', value: 'Douglas, GA' },
      { text: 'Dover, DE', value: 'Dover, DE' },
      { text: 'Dublin, GA', value: 'Dublin, GA' },
      { text: 'DuBois, PA', value: 'DuBois, PA' },
      { text: 'Dubuque, IA', value: 'Dubuque, IA' },
      { text: 'Duluth, MN', value: 'Duluth, MN' },
      { text: 'Dumas, TX', value: 'Dumas, TX' },
      { text: 'Duncan, OK', value: 'Duncan, OK' },
      { text: 'Dunn, NC', value: 'Dunn, NC' },
      { text: 'Durango, CO', value: 'Durango, CO' },
      { text: 'Durant, OK', value: 'Durant, OK' },
      { text: 'Durham, NC', value: 'Durham, NC' },
      { text: 'Dutchess County, NY', value: 'Dutchess County, NY' },
      { text: 'Dyersburg, TN', value: 'Dyersburg, TN' },
      { text: 'Eagle Pass, TX', value: 'Eagle Pass, TX' },
      { text: 'East Stroudsburg, PA', value: 'East Stroudsburg, PA' },
      { text: 'Easton, MD', value: 'Easton, MD' },
      { text: 'Eau Claire, WI', value: 'Eau Claire, WI' },
      { text: 'Edwards, CO', value: 'Edwards, CO' },
      { text: 'Effingham, IL', value: 'Effingham, IL' },
      { text: 'El Campo, TX', value: 'El Campo, TX' },
      { text: 'El Centro, CA', value: 'El Centro, CA' },
      { text: 'El Dorado, AR', value: 'El Dorado, AR' },
      { text: 'El Paso, TX', value: 'El Paso, TX' },
      { text: 'Elgin, IL', value: 'Elgin, IL' },
      { text: 'Elizabeth City, NC', value: 'Elizabeth City, NC' },
      { text: 'Elizabethtown, KY', value: 'Elizabethtown, KY' },
      { text: 'Elk City, OK', value: 'Elk City, OK' },
      { text: 'Elkhart, IN', value: 'Elkhart, IN' },
      { text: 'Elkins, WV', value: 'Elkins, WV' },
      { text: 'Elko, NV', value: 'Elko, NV' },
      { text: 'Ellensburg, WA', value: 'Ellensburg, WA' },
      { text: 'Elmira, NY', value: 'Elmira, NY' },
      { text: 'Emporia, KS', value: 'Emporia, KS' },
      { text: 'Enid, OK', value: 'Enid, OK' },
      { text: 'Enterprise, AL', value: 'Enterprise, AL' },
      { text: 'Erie, PA', value: 'Erie, PA' },
      { text: 'Escanaba, MI', value: 'Escanaba, MI' },
      { text: 'Espanola, NM', value: 'Espanola, NM' },
      { text: 'Eugene, OR', value: 'Eugene, OR' },
      { text: 'Eureka, CA', value: 'Eureka, CA' },
      { text: 'Evanston, WY', value: 'Evanston, WY' },
      { text: 'Evansville, IN', value: 'Evansville, IN' },
      { text: 'Fairbanks, AK', value: 'Fairbanks, AK' },
      { text: 'Fairfield, IA', value: 'Fairfield, IA' },
      { text: 'Fairmont, WV', value: 'Fairmont, WV' },
      { text: 'Fallon, NV', value: 'Fallon, NV' },
      { text: 'Fargo, ND', value: 'Fargo, ND' },
      { text: 'Faribault, MN', value: 'Faribault, MN' },
      { text: 'Farmington, MO', value: 'Farmington, MO' },
      { text: 'Farmington, NM', value: 'Farmington, NM' },
      { text: 'Fayetteville, AR', value: 'Fayetteville, AR' },
      { text: 'Fayetteville, NC', value: 'Fayetteville, NC' },
      { text: 'Fergus Falls, MN', value: 'Fergus Falls, MN' },
      { text: 'Fernley, NV', value: 'Fernley, NV' },
      { text: 'Findlay, OH', value: 'Findlay, OH' },
      { text: 'Fitzgerald, GA', value: 'Fitzgerald, GA' },
      { text: 'Flagstaff, AZ', value: 'Flagstaff, AZ' },
      { text: 'Flint, MI', value: 'Flint, MI' },
      { text: 'Florence, AL', value: 'Florence, AL' },
      { text: 'Florence, SC', value: 'Florence, SC' },
      { text: 'Fond du Lac, WI', value: 'Fond du Lac, WI' },
      { text: 'Forest City, NC', value: 'Forest City, NC' },
      { text: 'Forrest City, AR', value: 'Forrest City, AR' },
      { text: 'Fort Collins, CO', value: 'Fort Collins, CO' },
      { text: 'Fort Dodge, IA', value: 'Fort Dodge, IA' },
      { text: 'Fort Lauderdale, FL', value: 'Fort Lauderdale, FL' },
      { text: 'Fort Leonard Wood, MO', value: 'Fort Leonard Wood, MO' },
      { text: 'Fort Madison, IA', value: 'Fort Madison, IA' },
      { text: 'Fort Morgan, CO', value: 'Fort Morgan, CO' },
      { text: 'Fort Polk South, LA', value: 'Fort Polk South, LA' },
      { text: 'Fort Smith, AR', value: 'Fort Smith, AR' },
      { text: 'Fort Wayne, IN', value: 'Fort Wayne, IN' },
      { text: 'Fort Worth, TX', value: 'Fort Worth, TX' },
      { text: 'Frankfort, IN', value: 'Frankfort, IN' },
      { text: 'Frankfort, KY', value: 'Frankfort, KY' },
      { text: 'Fredericksburg, TX', value: 'Fredericksburg, TX' },
      { text: 'Freeport, IL', value: 'Freeport, IL' },
      { text: 'Fremont, NE', value: 'Fremont, NE' },
      { text: 'Fremont, OH', value: 'Fremont, OH' },
      { text: 'Fresno, CA', value: 'Fresno, CA' },
      { text: 'Gadsden, AL', value: 'Gadsden, AL' },
      { text: 'Gaffney, SC', value: 'Gaffney, SC' },
      { text: 'Gainesville, FL', value: 'Gainesville, FL' },
      { text: 'Gainesville, GA', value: 'Gainesville, GA' },
      { text: 'Gainesville, TX', value: 'Gainesville, TX' },
      { text: 'Galesburg, IL', value: 'Galesburg, IL' },
      { text: 'Gallup, NM', value: 'Gallup, NM' },
      { text: 'Garden City, KS', value: 'Garden City, KS' },
      { text: 'Gardnerville Ranchos, NV', value: 'Gardnerville Ranchos, NV' },
      { text: 'Gary, IN', value: 'Gary, IN' },
      { text: 'Georgetown, SC', value: 'Georgetown, SC' },
      { text: 'Gettysburg, PA', value: 'Gettysburg, PA' },
      { text: 'Gillette, WY', value: 'Gillette, WY' },
      { text: 'Glasgow, KY', value: 'Glasgow, KY' },
      { text: 'Glens Falls, NY', value: 'Glens Falls, NY' },
      { text: 'Glenwood Springs, CO', value: 'Glenwood Springs, CO' },
      { text: 'Gloversville, NY', value: 'Gloversville, NY' },
      { text: 'Goldsboro, NC', value: 'Goldsboro, NC' },
      { text: 'Grand Forks, ND', value: 'Grand Forks, ND' },
      { text: 'Grand Island, NE', value: 'Grand Island, NE' },
      { text: 'Grand Junction, CO', value: 'Grand Junction, CO' },
      { text: 'Grand Rapids, MI', value: 'Grand Rapids, MI' },
      { text: 'Grants Pass, OR', value: 'Grants Pass, OR' },
      { text: 'Grants, NM', value: 'Grants, NM' },
      { text: 'Great Bend, KS', value: 'Great Bend, KS' },
      { text: 'Great Falls, MT', value: 'Great Falls, MT' },
      { text: 'Greeley, CO', value: 'Greeley, CO' },
      { text: 'Green Bay, WI', value: 'Green Bay, WI' },
      { text: 'Greeneville, TN', value: 'Greeneville, TN' },
      { text: 'Greenfield Town, MA', value: 'Greenfield Town, MA' },
      { text: 'Greensboro, NC', value: 'Greensboro, NC' },
      { text: 'Greensburg, IN', value: 'Greensburg, IN' },
      { text: 'Greenville, MS', value: 'Greenville, MS' },
      { text: 'Greenville, NC', value: 'Greenville, NC' },
      { text: 'Greenville, OH', value: 'Greenville, OH' },
      { text: 'Greenville, SC', value: 'Greenville, SC' },
      { text: 'Greenwood, MS', value: 'Greenwood, MS' },
      { text: 'Greenwood, SC', value: 'Greenwood, SC' },
      { text: 'Grenada, MS', value: 'Grenada, MS' },
      { text: 'Guayama, PR', value: 'Guayama, PR' },
      { text: 'Gulfport, MS', value: 'Gulfport, MS' },
      { text: 'Guymon, OK', value: 'Guymon, OK' },
      { text: 'Hagerstown, MD', value: 'Hagerstown, MD' },
      { text: 'Hailey, ID', value: 'Hailey, ID' },
      { text: 'Hammond, LA', value: 'Hammond, LA' },
      { text: 'Hanford, CA', value: 'Hanford, CA' },
      { text: 'Hannibal, MO', value: 'Hannibal, MO' },
      { text: 'Harrisburg, PA', value: 'Harrisburg, PA' },
      { text: 'Harrison, AR', value: 'Harrison, AR' },
      { text: 'Harrisonburg, VA', value: 'Harrisonburg, VA' },
      { text: 'Hartford, CT', value: 'Hartford, CT' },
      { text: 'Hastings, NE', value: 'Hastings, NE' },
      { text: 'Hattiesburg, MS', value: 'Hattiesburg, MS' },
      { text: 'Hays, KS', value: 'Hays, KS' },
      { text: 'Heber, UT', value: 'Heber, UT' },
      { text: 'Helena, AR', value: 'Helena, AR' },
      { text: 'Helena, MT', value: 'Helena, MT' },
      { text: 'Henderson, NC', value: 'Henderson, NC' },
      { text: 'Hereford, TX', value: 'Hereford, TX' },
      { text: 'Hermiston, OR', value: 'Hermiston, OR' },
      { text: 'Hickory, NC', value: 'Hickory, NC' },
      { text: 'Hillsdale, MI', value: 'Hillsdale, MI' },
      { text: 'Hilo, HI', value: 'Hilo, HI' },
      { text: 'Hilton Head Island, SC', value: 'Hilton Head Island, SC' },
      { text: 'Hinesville, GA', value: 'Hinesville, GA' },
      { text: 'Hobbs, NM', value: 'Hobbs, NM' },
      { text: 'Holland, MI', value: 'Holland, MI' },
      { text: 'Homosassa Springs, FL', value: 'Homosassa Springs, FL' },
      { text: 'Hood River, OR', value: 'Hood River, OR' },
      { text: 'Hot Springs, AR', value: 'Hot Springs, AR' },
      { text: 'Houghton, MI', value: 'Houghton, MI' },
      { text: 'Houma, LA', value: 'Houma, LA' },
      { text: 'Houston, TX', value: 'Houston, TX' },
      { text: 'Hudson, NY', value: 'Hudson, NY' },
      { text: 'Huntingdon, PA', value: 'Huntingdon, PA' },
      { text: 'Huntington, IN', value: 'Huntington, IN' },
      { text: 'Huntington, WV', value: 'Huntington, WV' },
      { text: 'Huntsville, AL', value: 'Huntsville, AL' },
      { text: 'Huntsville, TX', value: 'Huntsville, TX' },
      { text: 'Huron, SD', value: 'Huron, SD' },
      { text: 'Hutchinson, KS', value: 'Hutchinson, KS' },
      { text: 'Hutchinson, MN', value: 'Hutchinson, MN' },
      { text: 'Idaho Falls, ID', value: 'Idaho Falls, ID' },
      { text: 'Indiana, PA', value: 'Indiana, PA' },
      { text: 'Indianapolis, IN', value: 'Indianapolis, IN' },
      { text: 'Indianola, MS', value: 'Indianola, MS' },
      { text: 'Ionia, MI', value: 'Ionia, MI' },
      { text: 'Iowa City, IA', value: 'Iowa City, IA' },
      { text: 'Iron Mountain, MI', value: 'Iron Mountain, MI' },
      { text: 'Ithaca, NY', value: 'Ithaca, NY' },
      { text: 'Jackson, MI', value: 'Jackson, MI' },
      { text: 'Jackson, MS', value: 'Jackson, MS' },
      { text: 'Jackson, OH', value: 'Jackson, OH' },
      { text: 'Jackson, TN', value: 'Jackson, TN' },
      { text: 'Jackson, WY', value: 'Jackson, WY' },
      { text: 'Jacksonville, FL', value: 'Jacksonville, FL' },
      { text: 'Jacksonville, IL', value: 'Jacksonville, IL' },
      { text: 'Jacksonville, NC', value: 'Jacksonville, NC' },
      { text: 'Jacksonville, TX', value: 'Jacksonville, TX' },
      { text: 'Jamestown, ND', value: 'Jamestown, ND' },
      { text: 'Jamestown, NY', value: 'Jamestown, NY' },
      { text: 'Janesville, WI', value: 'Janesville, WI' },
      { text: 'Jasper, IN', value: 'Jasper, IN' },
      { text: 'Jayuya, PR', value: 'Jayuya, PR' },
      { text: 'Jefferson City, MO', value: 'Jefferson City, MO' },
      { text: 'Jefferson, GA', value: 'Jefferson, GA' },
      { text: 'Jesup, GA', value: 'Jesup, GA' },
      { text: 'Johnson City, TN', value: 'Johnson City, TN' },
      { text: 'Johnstown, PA', value: 'Johnstown, PA' },
      { text: 'Jonesboro, AR', value: 'Jonesboro, AR' },
      { text: 'Joplin, MO', value: 'Joplin, MO' },
      { text: 'Junction City, KS', value: 'Junction City, KS' },
      { text: 'Juneau, AK', value: 'Juneau, AK' },
      { text: 'Kahului, HI', value: 'Kahului, HI' },
      { text: 'Kalamazoo, MI', value: 'Kalamazoo, MI' },
      { text: 'Kalispell, MT', value: 'Kalispell, MT' },
      { text: 'Kankakee, IL', value: 'Kankakee, IL' },
      { text: 'Kansas City, MO', value: 'Kansas City, MO' },
      { text: 'Kapaa, HI', value: 'Kapaa, HI' },
      { text: 'Kearney, NE', value: 'Kearney, NE' },
      { text: 'Keene, NH', value: 'Keene, NH' },
      { text: 'Kendallville, IN', value: 'Kendallville, IN' },
      { text: 'Kennett, MO', value: 'Kennett, MO' },
      { text: 'Kennewick, WA', value: 'Kennewick, WA' },
      { text: 'Kerrville, TX', value: 'Kerrville, TX' },
      { text: 'Ketchikan, AK', value: 'Ketchikan, AK' },
      { text: 'Key West, FL', value: 'Key West, FL' },
      { text: 'Kill Devil Hills, NC', value: 'Kill Devil Hills, NC' },
      { text: 'Killeen, TX', value: 'Killeen, TX' },
      { text: 'Kingsport, TN', value: 'Kingsport, TN' },
      { text: 'Kingston, NY', value: 'Kingston, NY' },
      { text: 'Kingsville, TX', value: 'Kingsville, TX' },
      { text: 'Kinston, NC', value: 'Kinston, NC' },
      { text: 'Kirksville, MO', value: 'Kirksville, MO' },
      { text: 'Klamath Falls, OR', value: 'Klamath Falls, OR' },
      { text: 'Knoxville, TN', value: 'Knoxville, TN' },
      { text: 'Kokomo, IN', value: 'Kokomo, IN' },
      { text: 'La Crosse, WI', value: 'La Crosse, WI' },
      { text: 'La Grande, OR', value: 'La Grande, OR' },
      { text: 'Laconia, NH', value: 'Laconia, NH' },
      { text: 'Lafayette, IN', value: 'Lafayette, IN' },
      { text: 'Lafayette, LA', value: 'Lafayette, LA' },
      { text: 'LaGrange, GA', value: 'LaGrange, GA' },
      { text: 'Lake Charles, LA', value: 'Lake Charles, LA' },
      { text: 'Lake City, FL', value: 'Lake City, FL' },
      { text: 'Lake County, IL', value: 'Lake County, IL' },
      { text: 'Lake Havasu City, AZ', value: 'Lake Havasu City, AZ' },
      { text: 'Lakeland, FL', value: 'Lakeland, FL' },
      { text: 'Lamesa, TX', value: 'Lamesa, TX' },
      { text: 'Lancaster, PA', value: 'Lancaster, PA' },
      { text: 'Lansing, MI', value: 'Lansing, MI' },
      { text: 'Laramie, WY', value: 'Laramie, WY' },
      { text: 'Laredo, TX', value: 'Laredo, TX' },
      { text: 'Las Cruces, NM', value: 'Las Cruces, NM' },
      { text: 'Las Vegas, NM', value: 'Las Vegas, NM' },
      { text: 'Las Vegas, NV', value: 'Las Vegas, NV' },
      { text: 'Laurel, MS', value: 'Laurel, MS' },
      { text: 'Laurinburg, NC', value: 'Laurinburg, NC' },
      { text: 'Lawrence, KS', value: 'Lawrence, KS' },
      { text: 'Lawrenceburg, TN', value: 'Lawrenceburg, TN' },
      { text: 'Lawton, OK', value: 'Lawton, OK' },
      { text: 'Lebanon, MO', value: 'Lebanon, MO' },
      { text: 'Lebanon, PA', value: 'Lebanon, PA' },
      { text: 'Levelland, TX', value: 'Levelland, TX' },
      { text: 'Lewisburg, PA', value: 'Lewisburg, PA' },
      { text: 'Lewisburg, TN', value: 'Lewisburg, TN' },
      { text: 'Lewiston, ID', value: 'Lewiston, ID' },
      { text: 'Lewiston, ME', value: 'Lewiston, ME' },
      { text: 'Lewistown, PA', value: 'Lewistown, PA' },
      { text: 'Lexington, KY', value: 'Lexington, KY' },
      { text: 'Lexington, NE', value: 'Lexington, NE' },
      { text: 'Liberal, KS', value: 'Liberal, KS' },
      { text: 'Lima, OH', value: 'Lima, OH' },
      { text: 'Lincoln, IL', value: 'Lincoln, IL' },
      { text: 'Lincoln, NE', value: 'Lincoln, NE' },
      { text: 'Little Rock, AR', value: 'Little Rock, AR' },
      { text: 'Lock Haven, PA', value: 'Lock Haven, PA' },
      { text: 'Logan, UT', value: 'Logan, UT' },
      { text: 'Logan, WV', value: 'Logan, WV' },
      { text: 'Logansport, IN', value: 'Logansport, IN' },
      { text: 'London, KY', value: 'London, KY' },
      { text: 'Longview, TX', value: 'Longview, TX' },
      { text: 'Longview, WA', value: 'Longview, WA' },
      { text: 'Los Alamos, NM', value: 'Los Alamos, NM' },
      { text: 'Los Angeles, CA', value: 'Los Angeles, CA' },
      { text: 'Louisville, KY', value: 'Louisville, KY' },
      { text: 'Lubbock, TX', value: 'Lubbock, TX' },
      { text: 'Ludington, MI', value: 'Ludington, MI' },
      { text: 'Lufkin, TX', value: 'Lufkin, TX' },
      { text: 'Lumberton, NC', value: 'Lumberton, NC' },
      { text: 'Lynchburg, VA', value: 'Lynchburg, VA' },
      { text: 'Macomb, IL', value: 'Macomb, IL' },
      { text: 'Macon, GA', value: 'Macon, GA' },
      { text: 'Madera, CA', value: 'Madera, CA' },
      { text: 'Madison, IN', value: 'Madison, IN' },
      { text: 'Madison, WI', value: 'Madison, WI' },
      { text: 'Madisonville, KY', value: 'Madisonville, KY' },
      { text: 'Magnolia, AR', value: 'Magnolia, AR' },
      { text: 'Malone, NY', value: 'Malone, NY' },
      { text: 'Malvern, AR', value: 'Malvern, AR' },
      { text: 'Manchester, NH', value: 'Manchester, NH' },
      { text: 'Manhattan, KS', value: 'Manhattan, KS' },
      { text: 'Manitowoc, WI', value: 'Manitowoc, WI' },
      { text: 'Mankato, MN', value: 'Mankato, MN' },
      { text: 'Mansfield, OH', value: 'Mansfield, OH' },
      { text: 'Marietta, OH', value: 'Marietta, OH' },
      { text: 'Marinette, WI', value: 'Marinette, WI' },
      { text: 'Marion, IN', value: 'Marion, IN' },
      { text: 'Marion, NC', value: 'Marion, NC' },
      { text: 'Marion, OH', value: 'Marion, OH' },
      { text: 'Marquette, MI', value: 'Marquette, MI' },
      { text: 'Marshall, MN', value: 'Marshall, MN' },
      { text: 'Marshall, MO', value: 'Marshall, MO' },
      { text: 'Marshall, TX', value: 'Marshall, TX' },
      { text: 'Marshalltown, IA', value: 'Marshalltown, IA' },
      { text: 'Martin, TN', value: 'Martin, TN' },
      { text: 'Martinsville, VA', value: 'Martinsville, VA' },
      { text: 'Maryville, MO', value: 'Maryville, MO' },
      { text: 'Mason City, IA', value: 'Mason City, IA' },
      { text: 'Mayagüez, PR', value: 'Mayagüez, PR' },
      { text: 'Mayfield, KY', value: 'Mayfield, KY' },
      { text: 'Maysville, KY', value: 'Maysville, KY' },
      { text: 'McAlester, OK', value: 'McAlester, OK' },
      { text: 'McAllen, TX', value: 'McAllen, TX' },
      { text: 'McComb, MS', value: 'McComb, MS' },
      { text: 'McMinnville, TN', value: 'McMinnville, TN' },
      { text: 'McPherson, KS', value: 'McPherson, KS' },
      { text: 'Meadville, PA', value: 'Meadville, PA' },
      { text: 'Medford, OR', value: 'Medford, OR' },
      { text: 'Memphis, TN', value: 'Memphis, TN' },
      { text: 'Menomonie, WI', value: 'Menomonie, WI' },
      { text: 'Merced, CA', value: 'Merced, CA' },
      { text: 'Meridian, MS', value: 'Meridian, MS' },
      { text: 'Merrill, WI', value: 'Merrill, WI' },
      { text: 'Mexico, MO', value: 'Mexico, MO' },
      { text: 'Miami, FL', value: 'Miami, FL' },
      { text: 'Miami, OK', value: 'Miami, OK' },
      { text: 'Michigan City, IN', value: 'Michigan City, IN' },
      { text: 'Middlesborough, KY', value: 'Middlesborough, KY' },
      { text: 'Midland, MI', value: 'Midland, MI' },
      { text: 'Midland, TX', value: 'Midland, TX' },
      { text: 'Milledgeville, GA', value: 'Milledgeville, GA' },
      { text: 'Milwaukee, WI', value: 'Milwaukee, WI' },
      { text: 'Mineral Wells, TX', value: 'Mineral Wells, TX' },
      { text: 'Minneapolis, MN', value: 'Minneapolis, MN' },
      { text: 'Minot, ND', value: 'Minot, ND' },
      { text: 'Missoula, MT', value: 'Missoula, MT' },
      { text: 'Mitchell, SD', value: 'Mitchell, SD' },
      { text: 'Moberly, MO', value: 'Moberly, MO' },
      { text: 'Mobile, AL', value: 'Mobile, AL' },
      { text: 'Modesto, CA', value: 'Modesto, CA' },
      { text: 'Monroe, LA', value: 'Monroe, LA' },
      { text: 'Monroe, MI', value: 'Monroe, MI' },
      { text: 'Montgomery County, PA', value: 'Montgomery County, PA' },
      { text: 'Montgomery, AL', value: 'Montgomery, AL' },
      { text: 'Montrose, CO', value: 'Montrose, CO' },
      { text: 'Morehead City, NC', value: 'Morehead City, NC' },
      { text: 'Morgan City, LA', value: 'Morgan City, LA' },
      { text: 'Morgantown, WV', value: 'Morgantown, WV' },
      { text: 'Morristown, TN', value: 'Morristown, TN' },
      { text: 'Moscow, ID', value: 'Moscow, ID' },
      { text: 'Moses Lake, WA', value: 'Moses Lake, WA' },
      { text: 'Moultrie, GA', value: 'Moultrie, GA' },
      { text: 'Mount Airy, NC', value: 'Mount Airy, NC' },
      { text: 'Mount Pleasant, MI', value: 'Mount Pleasant, MI' },
      { text: 'Mount Pleasant, TX', value: 'Mount Pleasant, TX' },
      { text: 'Mount Sterling, KY', value: 'Mount Sterling, KY' },
      { text: 'Mount Vernon, IL', value: 'Mount Vernon, IL' },
      { text: 'Mount Vernon, OH', value: 'Mount Vernon, OH' },
      { text: 'Mount Vernon, WA', value: 'Mount Vernon, WA' },
      { text: 'Mountain Home, AR', value: 'Mountain Home, AR' },
      { text: 'Mountain Home, ID', value: 'Mountain Home, ID' },
      { text: 'Muncie, IN', value: 'Muncie, IN' },
      { text: 'Murray, KY', value: 'Murray, KY' },
      { text: 'Muscatine, IA', value: 'Muscatine, IA' },
      { text: 'Muskegon, MI', value: 'Muskegon, MI' },
      { text: 'Muskogee, OK', value: 'Muskogee, OK' },
      { text: 'Myrtle Beach, SC', value: 'Myrtle Beach, SC' },
      { text: 'Nacogdoches, TX', value: 'Nacogdoches, TX' },
      { text: 'Napa, CA', value: 'Napa, CA' },
      { text: 'Naples, FL', value: 'Naples, FL' },
      { text: 'Nashville, TN', value: 'Nashville, TN' },
      { text: 'Nassau County, NY', value: 'Nassau County, NY' },
      { text: 'Natchez, MS', value: 'Natchez, MS' },
      { text: 'Natchitoches, LA', value: 'Natchitoches, LA' },
      { text: 'New Bern, NC', value: 'New Bern, NC' },
      { text: 'New Castle, IN', value: 'New Castle, IN' },
      { text: 'New Castle, PA', value: 'New Castle, PA' },
      { text: 'New Haven, CT', value: 'New Haven, CT' },
      { text: 'New Orleans, LA', value: 'New Orleans, LA' },
      { text: 'New Philadelphia, OH', value: 'New Philadelphia, OH' },
      { text: 'New Ulm, MN', value: 'New Ulm, MN' },
      { text: 'New York, NY', value: 'New York, NY' },
      { text: 'Newark, NJ', value: 'Newark, NJ' },
      { text: 'Newberry, SC', value: 'Newberry, SC' },
      { text: 'Newport, OR', value: 'Newport, OR' },
      { text: 'Newport, TN', value: 'Newport, TN' },
      { text: 'Newton, IA', value: 'Newton, IA' },
      { text: 'Niles, MI', value: 'Niles, MI' },
      { text: 'Nogales, AZ', value: 'Nogales, AZ' },
      { text: 'Norfolk, NE', value: 'Norfolk, NE' },
      { text: 'North Platte, NE', value: 'North Platte, NE' },
      { text: 'North Port, FL', value: 'North Port, FL' },
      { text: 'North Vernon, IN', value: 'North Vernon, IN' },
      { text: 'North Wilkesboro, NC', value: 'North Wilkesboro, NC' },
      { text: 'Norwalk, OH', value: 'Norwalk, OH' },
      { text: 'Norwich, CT', value: 'Norwich, CT' },
      { text: 'Oak Harbor, WA', value: 'Oak Harbor, WA' },
      { text: 'Oakland, CA', value: 'Oakland, CA' },
      { text: 'Ocala, FL', value: 'Ocala, FL' },
      { text: 'Ocean City, NJ', value: 'Ocean City, NJ' },
      { text: 'Odessa, TX', value: 'Odessa, TX' },
      { text: 'Ogden, UT', value: 'Ogden, UT' },
      { text: 'Ogdensburg, NY', value: 'Ogdensburg, NY' },
      { text: 'Oil City, PA', value: 'Oil City, PA' },
      { text: 'Okeechobee, FL', value: 'Okeechobee, FL' },
      { text: 'Oklahoma City, OK', value: 'Oklahoma City, OK' },
      { text: 'Olean, NY', value: 'Olean, NY' },
      { text: 'Olympia, WA', value: 'Olympia, WA' },
      { text: 'Omaha, NE', value: 'Omaha, NE' },
      { text: 'Oneonta, NY', value: 'Oneonta, NY' },
      { text: 'Ontario, OR', value: 'Ontario, OR' },
      { text: 'Opelousas, LA', value: 'Opelousas, LA' },
      { text: 'Orangeburg, SC', value: 'Orangeburg, SC' },
      { text: 'Orlando, FL', value: 'Orlando, FL' },
      { text: 'Oshkosh, WI', value: 'Oshkosh, WI' },
      { text: 'Oskaloosa, IA', value: 'Oskaloosa, IA' },
      { text: 'Othello, WA', value: 'Othello, WA' },
      { text: 'Ottawa, IL', value: 'Ottawa, IL' },
      { text: 'Ottawa, KS', value: 'Ottawa, KS' },
      { text: 'Ottumwa, IA', value: 'Ottumwa, IA' },
      { text: 'Owatonna, MN', value: 'Owatonna, MN' },
      { text: 'Owensboro, KY', value: 'Owensboro, KY' },
      { text: 'Owosso, MI', value: 'Owosso, MI' },
      { text: 'Oxford, MS', value: 'Oxford, MS' },
      { text: 'Oxford, NC', value: 'Oxford, NC' },
      { text: 'Oxnard, CA', value: 'Oxnard, CA' },
      { text: 'Ozark, AL', value: 'Ozark, AL' },
      { text: 'Paducah, KY', value: 'Paducah, KY' },
      { text: 'Pahrump, NV', value: 'Pahrump, NV' },
      { text: 'Palatka, FL', value: 'Palatka, FL' },
      { text: 'Palestine, TX', value: 'Palestine, TX' },
      { text: 'Palm Bay, FL', value: 'Palm Bay, FL' },
      { text: 'Pampa, TX', value: 'Pampa, TX' },
      { text: 'Panama City, FL', value: 'Panama City, FL' },
      { text: 'Paragould, AR', value: 'Paragould, AR' },
      { text: 'Paris, TN', value: 'Paris, TN' },
      { text: 'Paris, TX', value: 'Paris, TX' },
      { text: 'Parkersburg, WV', value: 'Parkersburg, WV' },
      { text: 'Parsons, KS', value: 'Parsons, KS' },
      { text: 'Payson, AZ', value: 'Payson, AZ' },
      { text: 'Pecos, TX', value: 'Pecos, TX' },
      { text: 'Pensacola, FL', value: 'Pensacola, FL' },
      { text: 'Peoria, IL', value: 'Peoria, IL' },
      { text: 'Peru, IN', value: 'Peru, IN' },
      { text: 'Philadelphia, PA', value: 'Philadelphia, PA' },
      { text: 'Phoenix, AZ', value: 'Phoenix, AZ' },
      { text: 'Picayune, MS', value: 'Picayune, MS' },
      { text: 'Pierre, SD', value: 'Pierre, SD' },
      { text: 'Pine Bluff, AR', value: 'Pine Bluff, AR' },
      { text: 'Pinehurst, NC', value: 'Pinehurst, NC' },
      { text: 'Pittsburg, KS', value: 'Pittsburg, KS' },
      { text: 'Pittsburgh, PA', value: 'Pittsburgh, PA' },
      { text: 'Pittsfield, MA', value: 'Pittsfield, MA' },
      { text: 'Plainview, TX', value: 'Plainview, TX' },
      { text: 'Platteville, WI', value: 'Platteville, WI' },
      { text: 'Plattsburgh, NY', value: 'Plattsburgh, NY' },
      { text: 'Plymouth, IN', value: 'Plymouth, IN' },
      { text: 'Pocatello, ID', value: 'Pocatello, ID' },
      { text: 'Point Pleasant, WV', value: 'Point Pleasant, WV' },
      { text: 'Ponca City, OK', value: 'Ponca City, OK' },
      { text: 'Ponce, PR', value: 'Ponce, PR' },
      { text: 'Pontiac, IL', value: 'Pontiac, IL' },
      { text: 'Poplar Bluff, MO', value: 'Poplar Bluff, MO' },
      { text: 'Port Angeles, WA', value: 'Port Angeles, WA' },
      { text: 'Port Clinton, OH', value: 'Port Clinton, OH' },
      { text: 'Port Lavaca, TX', value: 'Port Lavaca, TX' },
      { text: 'Port St. Lucie, FL', value: 'Port St. Lucie, FL' },
      { text: 'Portales, NM', value: 'Portales, NM' },
      { text: 'Portland, ME', value: 'Portland, ME' },
      { text: 'Portland, OR', value: 'Portland, OR' },
      { text: 'Portsmouth, OH', value: 'Portsmouth, OH' },
      { text: 'Pottsville, PA', value: 'Pottsville, PA' },
      { text: 'Prescott, AZ', value: 'Prescott, AZ' },
      { text: 'Price, UT', value: 'Price, UT' },
      { text: 'Prineville, OR', value: 'Prineville, OR' },
      { text: 'Providence, RI', value: 'Providence, RI' },
      { text: 'Provo, UT', value: 'Provo, UT' },
      { text: 'Pueblo, CO', value: 'Pueblo, CO' },
      { text: 'Pullman, WA', value: 'Pullman, WA' },
      { text: 'Punta Gorda, FL', value: 'Punta Gorda, FL' },
      { text: 'Quincy, IL', value: 'Quincy, IL' },
      { text: 'Racine, WI', value: 'Racine, WI' },
      { text: 'Raleigh, NC', value: 'Raleigh, NC' },
      { text: 'Rapid City, SD', value: 'Rapid City, SD' },
      { text: 'Raymondville, TX', value: 'Raymondville, TX' },
      { text: 'Reading, PA', value: 'Reading, PA' },
      { text: 'Red Bluff, CA', value: 'Red Bluff, CA' },
      { text: 'Red Wing, MN', value: 'Red Wing, MN' },
      { text: 'Redding, CA', value: 'Redding, CA' },
      { text: 'Reno, NV', value: 'Reno, NV' },
      { text: 'Rexburg, ID', value: 'Rexburg, ID' },
      { text: 'Richmond, IN', value: 'Richmond, IN' },
      { text: 'Richmond, KY', value: 'Richmond, KY' },
      { text: 'Richmond, VA', value: 'Richmond, VA' },
      { text: 'Rio Grande City, TX', value: 'Rio Grande City, TX' },
      { text: 'Riverside, CA', value: 'Riverside, CA' },
      { text: 'Riverton, WY', value: 'Riverton, WY' },
      { text: 'Roanoke Rapids, NC', value: 'Roanoke Rapids, NC' },
      { text: 'Roanoke, VA', value: 'Roanoke, VA' },
      { text: 'Rochelle, IL', value: 'Rochelle, IL' },
      { text: 'Rochester, MN', value: 'Rochester, MN' },
      { text: 'Rochester, NY', value: 'Rochester, NY' },
      { text: 'Rock Springs, WY', value: 'Rock Springs, WY' },
      { text: 'Rockford, IL', value: 'Rockford, IL' },
      { text: 'Rockingham County, NH', value: 'Rockingham County, NH' },
      { text: 'Rockingham, NC', value: 'Rockingham, NC' },
      { text: 'Rocky Mount, NC', value: 'Rocky Mount, NC' },
      { text: 'Rolla, MO', value: 'Rolla, MO' },
      { text: 'Rome, GA', value: 'Rome, GA' },
      { text: 'Roseburg, OR', value: 'Roseburg, OR' },
      { text: 'Roswell, NM', value: 'Roswell, NM' },
      { text: 'Russellville, AR', value: 'Russellville, AR' },
      { text: 'Ruston, LA', value: 'Ruston, LA' },
      { text: 'Rutland, VT', value: 'Rutland, VT' },
      { text: 'Sacramento, CA', value: 'Sacramento, CA' },
      { text: 'Safford, AZ', value: 'Safford, AZ' },
      { text: 'Saginaw, MI', value: 'Saginaw, MI' },
      { text: 'Salem, OH', value: 'Salem, OH' },
      { text: 'Salem, OR', value: 'Salem, OR' },
      { text: 'Salina, KS', value: 'Salina, KS' },
      { text: 'Salinas, CA', value: 'Salinas, CA' },
      { text: 'Salisbury, MD', value: 'Salisbury, MD' },
      { text: 'Salt Lake City, UT', value: 'Salt Lake City, UT' },
      { text: 'San Angelo, TX', value: 'San Angelo, TX' },
      { text: 'San Antonio, TX', value: 'San Antonio, TX' },
      { text: 'San Diego, CA', value: 'San Diego, CA' },
      { text: 'San Francisco, CA', value: 'San Francisco, CA' },
      { text: 'San Germán, PR', value: 'San Germán, PR' },
      { text: 'San Jose, CA', value: 'San Jose, CA' },
      {
        text: 'San Juan-Carolina-Caguas, PR',
        value: 'San Juan-Carolina-Caguas, PR'
      },
      { text: 'San Luis Obispo, CA', value: 'San Luis Obispo, CA' },
      { text: 'San Rafael, CA', value: 'San Rafael, CA' },
      { text: 'Sandpoint, ID', value: 'Sandpoint, ID' },
      { text: 'Sandusky, OH', value: 'Sandusky, OH' },
      { text: 'Sanford, NC', value: 'Sanford, NC' },
      { text: 'Santa Cruz, CA', value: 'Santa Cruz, CA' },
      { text: 'Santa Fe, NM', value: 'Santa Fe, NM' },
      { text: 'Santa Isabel, PR', value: 'Santa Isabel, PR' },
      { text: 'Santa Maria, CA', value: 'Santa Maria, CA' },
      { text: 'Santa Rosa, CA', value: 'Santa Rosa, CA' },
      { text: 'Sault Ste. Marie, MI', value: 'Sault Ste. Marie, MI' },
      { text: 'Savannah, GA', value: 'Savannah, GA' },
      { text: 'Sayre, PA', value: 'Sayre, PA' },
      { text: 'Scottsbluff, NE', value: 'Scottsbluff, NE' },
      { text: 'Scottsboro, AL', value: 'Scottsboro, AL' },
      { text: 'Scranton, PA', value: 'Scranton, PA' },
      { text: 'Searcy, AR', value: 'Searcy, AR' },
      { text: 'Seattle, WA', value: 'Seattle, WA' },
      { text: 'Sebastian, FL', value: 'Sebastian, FL' },
      { text: 'Sebring, FL', value: 'Sebring, FL' },
      { text: 'Sedalia, MO', value: 'Sedalia, MO' },
      { text: 'Selinsgrove, PA', value: 'Selinsgrove, PA' },
      { text: 'Selma, AL', value: 'Selma, AL' },
      { text: 'Seneca Falls, NY', value: 'Seneca Falls, NY' },
      { text: 'Seneca, SC', value: 'Seneca, SC' },
      { text: 'Sevierville, TN', value: 'Sevierville, TN' },
      { text: 'Seymour, IN', value: 'Seymour, IN' },
      { text: 'Shawano, WI', value: 'Shawano, WI' },
      { text: 'Shawnee, OK', value: 'Shawnee, OK' },
      { text: 'Sheboygan, WI', value: 'Sheboygan, WI' },
      { text: 'Shelby, NC', value: 'Shelby, NC' },
      { text: 'Shelbyville, TN', value: 'Shelbyville, TN' },
      { text: 'Shelton, WA', value: 'Shelton, WA' },
      { text: 'Sheridan, WY', value: 'Sheridan, WY' },
      { text: 'Sherman, TX', value: 'Sherman, TX' },
      { text: 'Show Low, AZ', value: 'Show Low, AZ' },
      { text: 'Shreveport, LA', value: 'Shreveport, LA' },
      { text: 'Sidney, OH', value: 'Sidney, OH' },
      { text: 'Sierra Vista, AZ', value: 'Sierra Vista, AZ' },
      { text: 'Sikeston, MO', value: 'Sikeston, MO' },
      { text: 'Silver City, NM', value: 'Silver City, NM' },
      { text: 'Silver Spring, MD', value: 'Silver Spring, MD' },
      { text: 'Sioux City, IA', value: 'Sioux City, IA' },
      { text: 'Sioux Falls, SD', value: 'Sioux Falls, SD' },
      { text: 'Snyder, TX', value: 'Snyder, TX' },
      { text: 'Somerset, KY', value: 'Somerset, KY' },
      { text: 'Somerset, PA', value: 'Somerset, PA' },
      { text: 'Sonora, CA', value: 'Sonora, CA' },
      { text: 'South Bend, IN', value: 'South Bend, IN' },
      { text: 'Spartanburg, SC', value: 'Spartanburg, SC' },
      { text: 'Spearfish, SD', value: 'Spearfish, SD' },
      { text: 'Spencer, IA', value: 'Spencer, IA' },
      { text: 'Spirit Lake, IA', value: 'Spirit Lake, IA' },
      { text: 'Spokane, WA', value: 'Spokane, WA' },
      { text: 'Springfield, IL', value: 'Springfield, IL' },
      { text: 'Springfield, MA', value: 'Springfield, MA' },
      { text: 'Springfield, MO', value: 'Springfield, MO' },
      { text: 'Springfield, OH', value: 'Springfield, OH' },
      { text: 'St. Cloud, MN', value: 'St. Cloud, MN' },
      { text: 'St. George, UT', value: 'St. George, UT' },
      { text: 'St. Joseph, MO', value: 'St. Joseph, MO' },
      { text: 'St. Louis, MO', value: 'St. Louis, MO' },
      { text: 'St. Marys, GA', value: 'St. Marys, GA' },
      { text: 'Starkville, MS', value: 'Starkville, MS' },
      { text: 'State College, PA', value: 'State College, PA' },
      { text: 'Statesboro, GA', value: 'Statesboro, GA' },
      { text: 'Staunton, VA', value: 'Staunton, VA' },
      { text: 'Steamboat Springs, CO', value: 'Steamboat Springs, CO' },
      { text: 'Stephenville, TX', value: 'Stephenville, TX' },
      { text: 'Sterling, CO', value: 'Sterling, CO' },
      { text: 'Sterling, IL', value: 'Sterling, IL' },
      { text: 'Stevens Point, WI', value: 'Stevens Point, WI' },
      { text: 'Stillwater, OK', value: 'Stillwater, OK' },
      { text: 'Stockton, CA', value: 'Stockton, CA' },
      { text: 'Storm Lake, IA', value: 'Storm Lake, IA' },
      { text: 'Sturgis, MI', value: 'Sturgis, MI' },
      { text: 'Sulphur Springs, TX', value: 'Sulphur Springs, TX' },
      { text: 'Summerville, GA', value: 'Summerville, GA' },
      { text: 'Summit Park, UT', value: 'Summit Park, UT' },
      { text: 'Sumter, SC', value: 'Sumter, SC' },
      { text: 'Sunbury, PA', value: 'Sunbury, PA' },
      { text: 'Susanville, CA', value: 'Susanville, CA' },
      { text: 'Sweetwater, TX', value: 'Sweetwater, TX' },
      { text: 'Syracuse, NY', value: 'Syracuse, NY' },
      { text: 'Tacoma, WA', value: 'Tacoma, WA' },
      { text: 'Tahlequah, OK', value: 'Tahlequah, OK' },
      { text: 'Talladega, AL', value: 'Talladega, AL' },
      { text: 'Tallahassee, FL', value: 'Tallahassee, FL' },
      { text: 'Tampa, FL', value: 'Tampa, FL' },
      { text: 'Taos, NM', value: 'Taos, NM' },
      { text: 'Taylorville, IL', value: 'Taylorville, IL' },
      { text: 'Terre Haute, IN', value: 'Terre Haute, IN' },
      { text: 'Texarkana, TX', value: 'Texarkana, TX' },
      { text: 'The Dalles, OR', value: 'The Dalles, OR' },
      { text: 'The Villages, FL', value: 'The Villages, FL' },
      { text: 'Thomaston, GA', value: 'Thomaston, GA' },
      { text: 'Thomasville, GA', value: 'Thomasville, GA' },
      { text: 'Tiffin, OH', value: 'Tiffin, OH' },
      { text: 'Tifton, GA', value: 'Tifton, GA' },
      { text: 'Toccoa, GA', value: 'Toccoa, GA' },
      { text: 'Toledo, OH', value: 'Toledo, OH' },
      { text: 'Topeka, KS', value: 'Topeka, KS' },
      { text: 'Torrington, CT', value: 'Torrington, CT' },
      { text: 'Traverse City, MI', value: 'Traverse City, MI' },
      { text: 'Trenton, NJ', value: 'Trenton, NJ' },
      { text: 'Troy, AL', value: 'Troy, AL' },
      { text: 'Truckee, CA', value: 'Truckee, CA' },
      { text: 'Tucson, AZ', value: 'Tucson, AZ' },
      { text: 'Tullahoma, TN', value: 'Tullahoma, TN' },
      { text: 'Tulsa, OK', value: 'Tulsa, OK' },
      { text: 'Tupelo, MS', value: 'Tupelo, MS' },
      { text: 'Tuscaloosa, AL', value: 'Tuscaloosa, AL' },
      { text: 'Twin Falls, ID', value: 'Twin Falls, ID' },
      { text: 'Tyler, TX', value: 'Tyler, TX' },
      { text: 'Ukiah, CA', value: 'Ukiah, CA' },
      { text: 'Union City, TN', value: 'Union City, TN' },
      { text: 'Urban Honolulu, HI', value: 'Urban Honolulu, HI' },
      { text: 'Urbana, OH', value: 'Urbana, OH' },
      { text: 'Utica, NY', value: 'Utica, NY' },
      { text: 'Uvalde, TX', value: 'Uvalde, TX' },
      { text: 'Valdosta, GA', value: 'Valdosta, GA' },
      { text: 'Vallejo, CA', value: 'Vallejo, CA' },
      { text: 'Valley, AL', value: 'Valley, AL' },
      { text: 'Van Wert, OH', value: 'Van Wert, OH' },
      { text: 'Vermillion, SD', value: 'Vermillion, SD' },
      { text: 'Vernal, UT', value: 'Vernal, UT' },
      { text: 'Vernon, TX', value: 'Vernon, TX' },
      { text: 'Vicksburg, MS', value: 'Vicksburg, MS' },
      { text: 'Victoria, TX', value: 'Victoria, TX' },
      { text: 'Vidalia, GA', value: 'Vidalia, GA' },
      { text: 'Vincennes, IN', value: 'Vincennes, IN' },
      { text: 'Vineland, NJ', value: 'Vineland, NJ' },
      { text: 'Vineyard Haven, MA', value: 'Vineyard Haven, MA' },
      { text: 'Virginia Beach, VA', value: 'Virginia Beach, VA' },
      { text: 'Visalia, CA', value: 'Visalia, CA' },
      { text: 'Wabash, IN', value: 'Wabash, IN' },
      { text: 'Waco, TX', value: 'Waco, TX' },
      { text: 'Wahpeton, ND', value: 'Wahpeton, ND' },
      { text: 'Walla Walla, WA', value: 'Walla Walla, WA' },
      { text: 'Wapakoneta, OH', value: 'Wapakoneta, OH' },
      { text: 'Warner Robins, GA', value: 'Warner Robins, GA' },
      { text: 'Warren, MI', value: 'Warren, MI' },
      { text: 'Warren, PA', value: 'Warren, PA' },
      { text: 'Warrensburg, MO', value: 'Warrensburg, MO' },
      { text: 'Warsaw, IN', value: 'Warsaw, IN' },
      {
        text: 'Washington Court House, OH',
        value: 'Washington Court House, OH'
      },
      { text: 'Washington, DC', value: 'Washington, DC' },
      { text: 'Washington, IN', value: 'Washington, IN' },
      { text: 'Washington, NC', value: 'Washington, NC' },
      { text: 'Waterloo, IA', value: 'Waterloo, IA' },
      { text: 'Watertown, NY', value: 'Watertown, NY' },
      { text: 'Watertown, SD', value: 'Watertown, SD' },
      { text: 'Watertown, WI', value: 'Watertown, WI' },
      { text: 'Wauchula, FL', value: 'Wauchula, FL' },
      { text: 'Wausau, WI', value: 'Wausau, WI' },
      { text: 'Waycross, GA', value: 'Waycross, GA' },
      { text: 'Weatherford, OK', value: 'Weatherford, OK' },
      { text: 'Weirton, WV', value: 'Weirton, WV' },
      { text: 'Wenatchee, WA', value: 'Wenatchee, WA' },
      { text: 'West Palm Beach, FL', value: 'West Palm Beach, FL' },
      { text: 'West Plains, MO', value: 'West Plains, MO' },
      { text: 'Wheeling, WV', value: 'Wheeling, WV' },
      { text: 'Whitewater, WI', value: 'Whitewater, WI' },
      { text: 'Wichita Falls, TX', value: 'Wichita Falls, TX' },
      { text: 'Wichita, KS', value: 'Wichita, KS' },
      { text: 'Williamsport, PA', value: 'Williamsport, PA' },
      { text: 'Williston, ND', value: 'Williston, ND' },
      { text: 'Willmar, MN', value: 'Willmar, MN' },
      { text: 'Wilmington, DE', value: 'Wilmington, DE' },
      { text: 'Wilmington, NC', value: 'Wilmington, NC' },
      { text: 'Wilmington, OH', value: 'Wilmington, OH' },
      { text: 'Wilson, NC', value: 'Wilson, NC' },
      { text: 'Winchester, VA', value: 'Winchester, VA' },
      { text: 'Winnemucca, NV', value: 'Winnemucca, NV' },
      { text: 'Winona, MN', value: 'Winona, MN' },
      { text: 'Winston, NC', value: 'Winston, NC' },
      { text: 'Wisconsin Rapids, WI', value: 'Wisconsin Rapids, WI' },
      { text: 'Woodward, OK', value: 'Woodward, OK' },
      { text: 'Wooster, OH', value: 'Wooster, OH' },
      { text: 'Worcester, MA', value: 'Worcester, MA' },
      { text: 'Worthington, MN', value: 'Worthington, MN' },
      { text: 'Yakima, WA', value: 'Yakima, WA' },
      { text: 'Yankton, SD', value: 'Yankton, SD' },
      { text: 'York, PA', value: 'York, PA' },
      { text: 'Youngstown, OH', value: 'Youngstown, OH' },
      { text: 'Yuba City, CA', value: 'Yuba City, CA' },
      { text: 'Yuma, AZ', value: 'Yuma, AZ' },
      { text: 'Zanesville, OH', value: 'Zanesville, OH' },
      { text: 'Zapata, TX', value: 'Zapata, TX' }
    ];
  }

  static humanReadableTimeZoneFromValue(value) {
    var tzs = OptionData.TimeZones();
    for (var i = 0; i < tzs.length; i++) {
      if (tzs[i].value == value) {
        return tzs[i].text;
      }
    }
  }

  static WirelessProviders() {
    return [
      { value: 'T-Mobile', text: 'T-Mobile' },
      { value: 'Metro by T-Mobile', text: 'Metro by T-Mobile' },
      { value: 'Verizon', text: 'Verizon' },
      { value: 'AT&T', text: 'AT&T' },
      { value: 'Boost', text: 'Boost' },
      { value: 'Cricket', text: 'Cricket' },
      { value: 'Straight Talk', text: 'Straight Talk' },
      { value: 'Total Wireless', text: 'Total Wireless' },
      { value: 'Other', text: 'Other' }
    ];
  }

  static Regions() {
    return [
      { value: 'National', text: 'National' },
      { value: 'Northeast', text: 'Northeast' },
      { value: 'Central', text: 'Central' },
      { value: 'Southeast', text: 'South' },
      { value: 'West', text: 'West' },
      { value: '#N/A', text: 'Not Applicable' }
    ];
  }

  static TimepickerItems() {
    return [
      { value: '00:00:00', text: '12:00 am' },
      { value: '00:30:00', text: '12:30 am' },
      { value: '01:00:00', text: '01:00 am' },
      { value: '01:30:00', text: '01:30 am' },
      { value: '02:00:00', text: '02:00 am' },
      { value: '02:30:00', text: '02:30 am' },
      { value: '03:00:00', text: '03:00 am' },
      { value: '03:30:00', text: '03:30 am' },
      { value: '04:00:00', text: '04:00 am' },
      { value: '04:30:00', text: '04:30 am' },
      { value: '05:00:00', text: '05:00 am' },
      { value: '05:30:00', text: '05:30 am' },
      { value: '06:00:00', text: '06:00 am' },
      { value: '06:30:00', text: '06:30 am' },
      { value: '07:00:00', text: '07:00 am' },
      { value: '07:30:00', text: '07:30 am' },
      { value: '08:00:00', text: '08:00 am' },
      { value: '08:30:00', text: '08:30 am' },
      { value: '09:00:00', text: '09:00 am' },
      { value: '09:30:00', text: '09:30 am' },
      { value: '10:00:00', text: '10:00 am' },
      { value: '10:30:00', text: '10:30 am' },
      { value: '11:00:00', text: '11:00 am' },
      { value: '11:30:00', text: '11:30 am' },
      { value: '12:00:00', text: '12:00 pm' },
      { value: '12:30:00', text: '12:30 pm' },
      { value: '13:00:00', text: '01:00 pm' },
      { value: '13:30:00', text: '01:30 pm' },
      { value: '14:00:00', text: '02:00 pm' },
      { value: '14:30:00', text: '02:30 pm' },
      { value: '15:00:00', text: '03:00 pm' },
      { value: '15:30:00', text: '03:30 pm' },
      { value: '16:00:00', text: '04:00 pm' },
      { value: '16:30:00', text: '04:30 pm' },
      { value: '17:00:00', text: '05:00 pm' },
      { value: '17:30:00', text: '05:30 pm' },
      { value: '18:00:00', text: '06:00 pm' },
      { value: '18:30:00', text: '06:30 pm' },
      { value: '19:00:00', text: '07:00 pm' },
      { value: '19:30:00', text: '07:30 pm' },
      { value: '20:00:00', text: '08:00 pm' },
      { value: '20:30:00', text: '08:30 pm' },
      { value: '21:00:00', text: '09:00 pm' },
      { value: '21:30:00', text: '09:30 pm' },
      { value: '22:00:00', text: '10:00 pm' },
      { value: '22:30:00', text: '10:30 pm' },
      { value: '23:00:00', text: '11:00 pm' },
      { value: '23:30:00', text: '11:30 pm' }
    ];
  }

  static TimepickerEndItems() {
    return [
      { value: '00:14:59', text: '12:14 am' },
      { value: '00:29:59', text: '12:29 am' },
      { value: '00:44:59', text: '12:44 am' },
      { value: '00:59:59', text: '12:59 am' },
      { value: '01:14:59', text: '01:14 am' },
      { value: '01:29:59', text: '01:29 am' },
      { value: '01:44:59', text: '01:44 am' },
      { value: '01:59:59', text: '01:59 am' },
      { value: '02:14:59', text: '02:14 am' },
      { value: '02:29:59', text: '02:29 am' },
      { value: '02:44:59', text: '02:44 am' },
      { value: '02:59:59', text: '02:59 am' },
      { value: '03:14:59', text: '03:14 am' },
      { value: '03:29:59', text: '03:29 am' },
      { value: '03:44:59', text: '03:44 am' },
      { value: '03:59:59', text: '03:59 am' },
      { value: '04:14:59', text: '04:14 am' },
      { value: '04:29:59', text: '04:29 am' },
      { value: '04:44:59', text: '04:44 am' },
      { value: '04:59:59', text: '04:59 am' },
      { value: '05:14:59', text: '05:14 am' },
      { value: '05:29:59', text: '05:29 am' },
      { value: '05:44:59', text: '05:44 am' },
      { value: '05:59:59', text: '05:59 am' },
      { value: '06:14:59', text: '06:14 am' },
      { value: '06:29:59', text: '06:29 am' },
      { value: '06:44:59', text: '06:44 am' },
      { value: '06:59:59', text: '06:59 am' },
      { value: '07:14:59', text: '07:14 am' },
      { value: '07:29:59', text: '07:29 am' },
      { value: '07:44:59', text: '07:44 am' },
      { value: '07:59:59', text: '07:59 am' },
      { value: '08:14:59', text: '08:14 am' },
      { value: '08:29:59', text: '08:29 am' },
      { value: '08:44:59', text: '08:44 am' },
      { value: '08:59:59', text: '08:59 am' },
      { value: '09:14:59', text: '09:14 am' },
      { value: '09:29:59', text: '09:29 am' },
      { value: '09:44:59', text: '09:44 am' },
      { value: '09:59:59', text: '09:59 am' },
      { value: '10:14:59', text: '10:14 am' },
      { value: '10:29:59', text: '10:29 am' },
      { value: '10:44:59', text: '10:44 am' },
      { value: '10:59:59', text: '10:59 am' },
      { value: '11:14:59', text: '11:14 am' },
      { value: '11:29:59', text: '11:29 am' },
      { value: '11:44:59', text: '11:44 am' },
      { value: '11:59:59', text: '11:59 am' },
      { value: '12:14:59', text: '12:14 pm' },
      { value: '12:29:59', text: '12:29 pm' },
      { value: '12:44:59', text: '12:44 pm' },
      { value: '12:59:59', text: '12:59 pm' },
      { value: '13:14:59', text: '01:14 pm' },
      { value: '13:29:59', text: '01:29 pm' },
      { value: '13:44:59', text: '01:44 pm' },
      { value: '13:59:59', text: '01:59 pm' },
      { value: '14:14:59', text: '02:14 pm' },
      { value: '14:29:59', text: '02:29 pm' },
      { value: '14:44:59', text: '02:44 pm' },
      { value: '14:59:59', text: '02:59 pm' },
      { value: '15:14:59', text: '03:14 pm' },
      { value: '15:29:59', text: '03:29 pm' },
      { value: '15:44:59', text: '03:44 pm' },
      { value: '15:59:59', text: '03:59 pm' },
      { value: '16:14:49', text: '04:14 pm' },
      { value: '16:29:59', text: '04:29 pm' },
      { value: '16:44:59', text: '04:44 pm' },
      { value: '16:59:59', text: '04:59 pm' },
      { value: '17:14:59', text: '05:14 pm' },
      { value: '17:29:59', text: '05:29 pm' },
      { value: '17:44:59', text: '05:44 pm' },
      { value: '17:59:59', text: '05:59 pm' },
      { value: '18:14:59', text: '06:14 pm' },
      { value: '18:29:59', text: '06:29 pm' },
      { value: '18:44:59', text: '06:44 pm' },
      { value: '18:59:59', text: '06:59 pm' },
      { value: '19:14:59', text: '07:14 pm' },
      { value: '19:29:59', text: '07:29 pm' },
      { value: '19:44:59', text: '07:44 pm' },
      { value: '19:59:59', text: '07:59 pm' },
      { value: '20:14:59', text: '08:14 pm' },
      { value: '20:29:59', text: '08:29 pm' },
      { value: '20:44:59', text: '08:44 pm' },
      { value: '20:59:59', text: '08:59 pm' },
      { value: '21:14:59', text: '09:14 pm' },
      { value: '21:29:59', text: '09:29 pm' },
      { value: '21:44:59', text: '09:44 pm' },
      { value: '21:59:59', text: '09:59 pm' },
      { value: '22:14:59', text: '10:14 pm' },
      { value: '22:29:59', text: '10:29 pm' },
      { value: '22:44:59', text: '10:44 pm' },
      { value: '22:59:59', text: '10:59 pm' },
      { value: '23:14:59', text: '11:14 pm' },
      { value: '23:29:59', text: '11:29 pm' },
      { value: '23:44:59', text: '11:44 pm' },
      { value: '23:59:59', text: '11:59 pm' }
    ];
  }

  static WinnerSelectionEndTimes() {
    return [
      { value: '00:00:00', text: '12:00 am' },
      { value: '00:15:00', text: '12:15 am' },
      { value: '00:30:00', text: '12:30 am' },
      { value: '00:45:00', text: '12:45 am' },
      { value: '01:00:00', text: '01:00 am' },
      { value: '01:15:00', text: '01:15 am' },
      { value: '01:30:00', text: '01:30 am' },
      { value: '01:45:00', text: '01:45 am' },
      { value: '02:00:00', text: '02:00 am' },
      { value: '02:15:00', text: '02:15 am' },
      { value: '02:30:00', text: '02:30 am' },
      { value: '02:45:00', text: '02:45 am' },
      { value: '03:00:00', text: '03:00 am' },
      { value: '03:15:00', text: '03:15 am' },
      { value: '03:30:00', text: '03:30 am' },
      { value: '03:45:00', text: '03:45 am' },
      { value: '04:00:00', text: '04:00 am' },
      { value: '04:15:00', text: '04:15 am' },
      { value: '04:30:00', text: '04:30 am' },
      { value: '04:45:00', text: '04:45 am' },
      { value: '05:00:00', text: '05:00 am' },
      { value: '05:15:00', text: '05:15 am' },
      { value: '05:30:00', text: '05:30 am' },
      { value: '05:45:00', text: '05:45 am' },
      { value: '06:00:00', text: '06:00 am' },
      { value: '06:15:00', text: '06:15 am' },
      { value: '06:30:00', text: '06:30 am' },
      { value: '06:45:00', text: '06:45 am' },
      { value: '07:00:00', text: '07:00 am' },
      { value: '07:15:00', text: '07:15 am' },
      { value: '07:30:00', text: '07:30 am' },
      { value: '07:45:00', text: '07:45 am' },
      { value: '08:00:00', text: '08:00 am' },
      { value: '08:15:00', text: '08:15 am' },
      { value: '08:30:00', text: '08:30 am' },
      { value: '08:45:00', text: '08:45 am' },
      { value: '09:00:00', text: '09:00 am' },
      { value: '09:15:00', text: '09:15 am' },
      { value: '09:30:00', text: '09:30 am' },
      { value: '09:45:00', text: '09:45 am' },
      { value: '10:00:00', text: '10:00 am' },
      { value: '10:15:00', text: '10:15 am' },
      { value: '10:30:00', text: '10:30 am' },
      { value: '10:45:00', text: '10:45 am' },
      { value: '11:00:00', text: '11:00 am' },
      { value: '11:15:00', text: '11:15 am' },
      { value: '11:30:00', text: '11:30 am' },
      { value: '11:45:00', text: '11:45 am' },
      { value: '12:00:00', text: '12:00 pm' },
      { value: '12:15:00', text: '12:15 pm' },
      { value: '12:30:00', text: '12:30 pm' },
      { value: '12:45:00', text: '12:45 pm' },
      { value: '13:00:00', text: '01:00 pm' },
      { value: '13:15:00', text: '01:15 pm' },
      { value: '13:30:00', text: '01:30 pm' },
      { value: '13:45:00', text: '01:45 pm' },
      { value: '14:00:00', text: '02:00 pm' },
      { value: '14:15:00', text: '02:15 pm' },
      { value: '14:30:00', text: '02:30 pm' },
      { value: '14:45:00', text: '02:45 pm' },
      { value: '15:00:00', text: '03:00 pm' },
      { value: '15:15:00', text: '03:15 pm' },
      { value: '15:30:00', text: '03:30 pm' },
      { value: '15:45:00', text: '03:45 pm' },
      { value: '16:00:00', text: '04:00 pm' },
      { value: '16:15:00', text: '04:15 pm' },
      { value: '16:30:00', text: '04:30 pm' },
      { value: '16:45:00', text: '04:45 pm' },
      { value: '17:00:00', text: '05:00 pm' },
      { value: '17:15:00', text: '05:15 pm' },
      { value: '17:30:00', text: '05:30 pm' },
      { value: '17:45:00', text: '05:45 pm' },
      { value: '18:00:00', text: '06:00 pm' },
      { value: '18:15:00', text: '06:15 pm' },
      { value: '18:30:00', text: '06:30 pm' },
      { value: '18:45:00', text: '06:45 pm' },
      { value: '19:00:00', text: '07:00 pm' },
      { value: '19:15:00', text: '07:15 pm' },
      { value: '19:30:00', text: '07:30 pm' },
      { value: '19:45:00', text: '07:45 pm' },
      { value: '20:00:00', text: '08:00 pm' },
      { value: '20:15:00', text: '08:15 pm' },
      { value: '20:30:00', text: '08:30 pm' },
      { value: '20:45:00', text: '08:45 pm' },
      { value: '21:00:00', text: '09:00 pm' },
      { value: '21:15:00', text: '09:15 pm' },
      { value: '21:30:00', text: '09:30 pm' },
      { value: '21:45:00', text: '09:45 pm' },
      { value: '22:00:00', text: '10:00 pm' },
      { value: '22:15:00', text: '10:15 pm' },
      { value: '22:30:00', text: '10:30 pm' },
      { value: '22:45:00', text: '10:45 pm' },
      { value: '23:00:00', text: '11:00 pm' },
      { value: '23:15:00', text: '11:15 pm' },
      { value: '23:30:00', text: '11:30 pm' },
      { value: '23:45:00', text: '11:45 pm' }
    ];
  }

  static SegmentAudienceItems() {
    return [
      'General Market',
      'Hispanic',
      '55+',
      'Military/Veterans/First Responders',
      'DE&I: Pride',
      'DE&I: Other',
      'TFB',
      'Charitable',
      'Emerging',
      'HQ Sponsorship',
      'Other'
    ];
  }

  static legacy_Organizations() {
    return [
      { value: 'metroStores', text: 'Metro Stores' },
      { value: 'tmobileStores', text: 'T-Mobile Stores' },
      { value: 'sponsorships', text: 'Sponsorships' },
      { value: 'communityEngagement', text: 'Community Engagement (Trucks)' },
      { value: 'tfbIl', text: 'TFB IL' },
      { value: 'signatureStores', text: 'Signature Stores' },
      { value: 'experienceStores', text: 'Experience Stores' },
      { value: 'other', text: 'other' }
    ];
  }

  static Organizations() {
    return [
      { value: 'Event_Metro_Stores', text: 'Metro Stores' },
      { value: 'Event_T_Mobile_Stores', text: 'T-Mobile Stores' },
      { value: 'Event_Sponsorships', text: 'Sponsorships' },
      {
        value: 'Event_Community_Engagements_Trucks',
        text: 'Community Engagement (Trucks)'
      },
      { value: 'Event_Local_Marketing', text: 'Local Marketing' },
      { value: 'Event_TFB_IL', text: 'TFB - IL' },
      { value: 'Event_Signature_Stores', text: 'Signature Stores' },
      { value: 'Event_Experience_Stores', text: 'Experience Stores' },
      { value: 'Event_Other', text: 'Other' }
    ];
  }

  static employeeOrgOptions() {
    return [
      { value: 'Local Marketing', text: 'Local Marketing' },
      { value: 'Community Engagement', text: 'Community Engagement' },
      { value: 'Sponsorship', text: 'Sponsorship' },
      { value: 'Retail – T-Mobile', text: 'Retail – T-Mobile' },
      { value: 'Retail - Metro', text: 'Retail - Metro' },
      { value: 'Signature', text: 'Signature' },
      { value: 'Experience', text: 'Experience' },
      { value: 'TFB – IL', text: 'TFB – IL' },
      { value: 'Local Legal', text: 'Local Legal' },
      { value: 'Other', text: 'Other' }
    ];
  }
  static employeeDepartmentOptions() {
    return [
      { value: 'T-Mobile for Business', text: 'T-Mobile for Business' },
      { value: 'Consumer Markets', text: 'Consumer Markets' },
      { value: 'Other', text: 'Other' }
    ];
  }

  static ContestTypes() {
    return [
      { value: 'Enter for a Chance to Win', text: 'Enter for a Chance to Win' },
      { value: 'Instant Win', text: 'Instant Win' },
      { value: 'Sign Up/Check In', text: 'Sign Up/Check In' }
    ];
  }

  static eventStatusOptions() {
    return [
      { value: 'Published', text: 'Published' },
      { value: 'Draft', text: 'Draft' },
      { value: 'Legal Pending', text: 'Legal Pending' },
      { value: 'Denied', text: 'Denied' }
    ];
  }

  static eventLocationOptions() {
    return [
      { value: 'Physical/In Person Event', text: 'Physical/In Person Event' },
      { value: 'Online/Virtual Event', text: 'Online/Virtual Event' },
      {
        value: 'Combination Online/In Person',
        text: 'Combination Online/In Person'
      }
    ];
  }

  static Experiences() {
    return [
      { value: 'Gametime', text: 'Gametime' },
      { value: 'Bobblehead', text: 'Bobblehead' },
      { value: 'Other', text: 'Other' }
    ];
  }

  static hqSponsorshipSegmentItems() {
    return [
      'Little League Baseball',
      'MLB',
      'Concert Tours',
      'Amphitheaters',
      'eSports',
      'SEC'
    ];
  }

  static marketTypeItems() {
    return [
      { value: 'Top 100', text: 'Top 100' },
      { value: 'SMRA', text: 'SMRA' },
      { value: '#N/A', text: 'Not Applicable' }
    ];
  }
}
