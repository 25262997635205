let intervalErrorList = [];
let switchHumanName = keyName => {
  switch (keyName) {
    case 'startDate':
      return 'Start Date';
    case 'startTime':
      return 'Start Time';
    case 'endDate':
      return 'End Date';
    case 'endTime':
      return 'End Time';
    case 'winnerSelectionDate':
      return 'Winner Selection Date';
    case 'winnerSelectionTime':
      return 'Winner Selection Time';
    default:
      return keyName;
  }
};
const checkIntervalPropertiesForNull = (ep, index) => {
  for (let key in ep) {
    if (key != 'selectionDate' && ep[key] == null) {
      intervalErrorList.push({
        index: index,
        key: key,
        message: `${switchHumanName(key)} cannot be empty.`
      });
    }
  }
};
const chkForEquality = (expectedValue, actualValue, index, key) => {
  if (expectedValue === actualValue) return;
  intervalErrorList.push({
    index: index,
    key: key,
    message: `${switchHumanName(key)} have to match.`
  });
};

const chkIntervalLogicOuter = (objectArray, targetIntervalCombo) => {
  let t =
    targetIntervalCombo === 'start'
      ? {
          a: 'startDate',
          b: 'startTime',
          c: 'endDate',
          d: 'endTime'
        }
      : {
          a: 'endDate',
          b: 'endTime',
          c: 'startDate',
          d: 'endTime'
        };

  return objectArray.reduce(function(
    previousEp,
    currentEp,
    currentIndex,
    objectArray
  ) {
    let evaluatingIndex = currentIndex;
    let topicIntervalDt = currentEp[t.a];
    let predicateIntervalDt = previousEp[t.c];
    let topicIntervalTm = currentEp[t.b];
    let predicateIntervalTm = previousEp[t.d];

    if (
      topicIntervalDt === predicateIntervalDt &&
      topicIntervalTm < predicateIntervalTm
    ) {
      intervalErrorList.push({
        index: evaluatingIndex,
        key: t.b,
        message: `${switchHumanName(
          t.b
        )} cannot begin before the previous entry period ${t.d}.`
      });
    } else if (topicIntervalDt < predicateIntervalDt) {
      intervalErrorList.push({
        index: evaluatingIndex,
        key: t.a,
        message: `${switchHumanName(
          t.a
        )} cannot begin before the previous entry period ${t.c}.`
      });
    }
    return previousEp;
  });
};

const dtAsUTC = date => {
  let result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

const cvtToDt = (day, time) => {
  const dtString = `${day}T${time}`;
  let d = new Date(dtString);
  d.toLocaleString('en-US', {
    timeZone: 'America/New_York'
  });
  return d;
};
const daysBetween = (intvl, index) => {
  let sFormated = cvtToDt(intvl.startDate, intvl.startTime);
  let eFormated = cvtToDt(intvl.endDate, intvl.endTime);
  let millisecondsPerDay = 24 * 60 * 60 * 1000;
  let difInDays =
    (dtAsUTC(eFormated) - dtAsUTC(sFormated)) / millisecondsPerDay;
  let isNeg = Math.sign(difInDays);
  if (isNaN(isNeg) || isNeg == -1 || difInDays > 45) {
    intervalErrorList.push({
      index: index,
      key: 'endDate',
      message:
        'Each entry period can only be a maximum of 45 days long and the start date must occur before the end date.'
    });
  }
  return true;
};

const daysBetweenSelectionFromEnd = (intvl, index) => {
  if (
    intvl.endDate === intvl.winnerSelectionDate &&
    intvl.endTime === intvl.winnerSelectionTime
  )
    return true;
  let eFormated = cvtToDt(intvl.endDate, intvl.endTime);
  let wFormated = cvtToDt(intvl.winnerSelectionDate, intvl.winnerSelectionTime);
  let millisecondsPerDay = 24 * 60 * 60 * 1000;
  let difInDays =
    (dtAsUTC(eFormated) - dtAsUTC(wFormated)) / millisecondsPerDay;
  let roundDifinDays = Math.round(difInDays);
  let isNeg = Math.sign(roundDifinDays);

  if (isNaN(isNeg) || isNeg == 1) {
    intervalErrorList.push({
      index: index,
      key: 'winnerSelectionDate',
      message:
        'Winner Selection time must occur after the entry period end date and time.'
    });
  }
  return true;
};
const daysBetweenSelectionFromStart = (intvl, index) => {
  let sFormated = cvtToDt(intvl.startDate, intvl.startTime);
  let wFormated = cvtToDt(intvl.winnerSelectionDate, intvl.winnerSelectionTime);
  let millisecondsPerDay = 24 * 60 * 60 * 1000;
  let difInDays =
    (dtAsUTC(wFormated) - dtAsUTC(sFormated)) / millisecondsPerDay;
  let isNeg = Math.sign(difInDays);
  if (isNaN(isNeg) || isNeg == -1 || difInDays > 45) {
    intervalErrorList.push({
      index: index,
      key: 'winnerSelectionDate',
      message:
        'Winner Selection time must occur after the entry period start date, and within 45 days.'
    });
  }
  return true;
};

let validateIntervals = (
  evtStrtDt,
  evtStrtTm,
  evtEndDt,
  evtEndTm,
  intervals
) => {
  intervalErrorList = [];
  //First check if Any objects have any null or empty values;
  intervals.forEach((entryPeriod, index) => {
    checkIntervalPropertiesForNull(entryPeriod, index);
  });
  if (intervalErrorList.length) return intervalErrorList;
  //Now check if Event Start Date/Time = ep 1 start date/time;
  chkForEquality(evtStrtDt, intervals[0].startDate, 0, 'startDate');
  chkForEquality(evtStrtTm, intervals[0].startTime, 0, 'startTime');
  chkForEquality(
    evtEndDt,
    intervals[intervals.length - 1].endDate,
    intervals.length - 1,
    'endDate'
  );
  chkForEquality(
    evtEndTm,
    intervals[intervals.length - 1].endTime,
    intervals.length - 1,
    'endTime'
  );
  if (intervalErrorList.length) return intervalErrorList;
  //check to make sure no interval is longer than 45 days long and that no startDate comes before end date;
  intervals.every(daysBetween);
  if (intervalErrorList.length) return intervalErrorList;
  //Check to make sure all intervals are in order;
  chkIntervalLogicOuter(intervals, 'start');
  if (intervalErrorList.length) return intervalErrorList;
  //check to make sure no winner selection dates come before end periods;

  intervals.every(daysBetweenSelectionFromEnd);
  if (intervalErrorList.length) return intervalErrorList;
  //check to make sure interval selection dates occur within 45 days of entry period start;
  intervals.every(daysBetweenSelectionFromStart);
  if (intervalErrorList.length) return intervalErrorList;

  return [];
};

module.exports = validateIntervals;
